import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { RootState } from 'store';
import { PetState } from 'reducers/pet/PetState';
import { SelectPetForm } from 'components/Pet/SelectPetForm';
import { PetWithService } from 'model/Booking';
import { Customer, Marketplace, Pet } from 'model';

import { BookingLayout, Heading } from 'components';
import { EditBooking } from 'model/Booking';
import FormErrorList from 'components/UI/FormsPart/FormErrorList/FormErrorList';
import { BookingStep } from 'components/Booking/Booking';

interface BookingSelectPetProps {
    booking: EditBooking;
    marketplace: Marketplace;
    //
    onCreate: () => void;
    onChange: (booking: EditBooking) => void;
    onContinue: () => void;
}

export const BookingSelectPet: React.FunctionComponent<BookingSelectPetProps> = props => {
    const customer = useSelector<RootState, Customer | undefined>(
        state => state.login.auth.customer
    );
    const { pets: savedPets } = useSelector<RootState, PetState>(state => state.pets);

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const pets = useMemo(() => {
        const customerPets = customer?.pets?.filter(pet => pet.active) || [];

        return props.booking.pets
            .concat(savedPets)
            .concat(customerPets)
            .reduce((arr, pet) => {
                if (!arr.find(p => p.name === pet.name)) {
                    arr.push(pet);
                }

                return arr;
            }, [] as Array<Pet>);
    }, [savedPets, customer, props]);

    const isInvalid = useMemo(() => {
        return props.booking.petsToBooking.length === 0;
    }, [props.booking]);

    const onChangePet = (petsToBooking: Array<PetWithService>) => {
        const updatedPets = props.booking.pets.map(pet => {
            if (petsToBooking.some(item => item.pet.uuid === pet.uuid)) {
                return petsToBooking.find(item => item.pet.uuid === pet.uuid)?.pet as Pet;
            }

            return pet;
        });

        const booking = { ...props.booking, petsToBooking, pets: updatedPets };
        props.onChange(booking);
    };

    const continueHandler = () => {
        if (isEditing) {
            setIsEditing(false);
        } else {
            props.onContinue();
        }
    };

    return (
        <>
            <BookingLayout
                showFooter={
                    isEditing
                        ? false
                        : {
                              disabled: isInvalid,
                              label: isEditing ? 'Update pet' : 'Continue',
                              onClick: continueHandler
                          }
                }
                showResume={props.booking}
                leftColumn={
                    <Grid item xs={12}>
                        <Heading
                            boxProps={{ mb: 3 }}
                            title={
                                pets.length > 0 ? (
                                    <small>
                                        {customer
                                            ? `Welcome back ${customer.name}`
                                            : 'Select your pet'}
                                    </small>
                                ) : (
                                    <small>Add pet</small>
                                )
                            }
                        />

                        <FormErrorList step={BookingStep.PetSelect} />

                        <SelectPetForm
                            pets={pets}
                            booking={props.booking}
                            //
                            onCreate={props.onCreate}
                            onEdit={() => setIsEditing(true)}
                            onUpdated={() => setIsEditing(false)}
                            onChange={onChangePet}
                        />
                    </Grid>
                }
            />
        </>
    );
};

export default BookingSelectPet;
