import { Availability } from 'model';

export interface AvailabilityState {
    availableHours: Array<Availability>;
    status: AvailabilityStatus;
    loading: boolean;
}

export enum AvailabilityStatus {
    Initial,
    FetchStart,
    FetchSuccess,
    GetNextDateStart,
    GetNextDateSuccess,
    Error
}
