import { Action } from "redux";

//Types
export const SIGN_UP_RESET_ACTION_TYPE = "SIGN_UP_RESET";
export const SIGN_UP_START_ACTION_TYPE = "SIGN_UP_START";
export const SIGN_UP_SUCCESS_ACTION_TYPE = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILED_ACTION_TYPE = "SIGN_UP_FAILED";
export const SIGN_UP_ERROR_ACTION_TYPE = "SIGN_UP_ERROR_REGISTERED";

//SingUp
interface SignUpResetAction extends Action<typeof SIGN_UP_RESET_ACTION_TYPE> {}
interface SignUpStartAction extends Action<typeof SIGN_UP_START_ACTION_TYPE> {}
interface SignUpSuccessAction
  extends Action<typeof SIGN_UP_SUCCESS_ACTION_TYPE> {}
interface SignUpFailedAction
  extends Action<typeof SIGN_UP_FAILED_ACTION_TYPE> {}

interface SignUpErrorAction extends Action<typeof SIGN_UP_ERROR_ACTION_TYPE> {}

//SignUpAction
export type SignUpAction =
  | SignUpResetAction
  | SignUpStartAction
  | SignUpSuccessAction
  | SignUpFailedAction
  | SignUpErrorAction;
