import {
    AvailabilityAction,
    AVAILABILITY_ERROR,
    AVAILABILITY_FETCH_START,
    AVAILABILITY_FETCH_SUCCESS,
    AVAILABILITY_RESET,
    AVAILABILITY_GET_NEXT_DATE_START,
    AVAILABILITY_GET_NEXT_DATE_SUCCESS
} from './AvailabilityActionsTypes';
import { ThunkAction } from 'redux-thunk';
import store from 'store';
import { createTokenConfig } from 'api/ApiClient';
import { Availability } from 'model';
import { AvailabilityDtos } from './AvailabilityDtos';
import { AxiosResponse } from 'axios';
import { AvailabilityState } from 'reducers/availability/AvailabilityState';
import { RootAction } from 'actions/ActionTypes';
import { convertAvailabilityData } from './AvailabilityDataConverter';
import ApiPublicClient from '../../api/ApiPublicClient';

const availabilityUrl = 'availability_by_hour/';
const nextAvailableDateUrl = 'next_available_date';

export const resetThunk = (): ThunkAction<void, AvailabilityState, null, AvailabilityAction> => {
    return async dispatch => {
        dispatch(reset());
    };
};

export const getAvailabilityThunk = (
    startDate: string,
    serviceId: string,
    petSize: string,
    hours: string
): ThunkAction<void, AvailabilityState, null, RootAction> => {
    return async dispatch => {
        dispatch(fetchStart());

        const marketplaceId = store.getState().marketplace.marketplace.id;
        const url =
            availabilityUrl +
            `?marketplace_id=${marketplaceId}&location_id=nil&start_date=${startDate}&pet_size=${petSize}&service_id=${serviceId}&hours=${hours}`;

        try {
            const response: AxiosResponse<Array<AvailabilityDtos>> = await ApiPublicClient.get(
                url,
                createTokenConfig()
            );
            const availability = convertAvailabilityData(response.data);
            dispatch(fetchSuccess(availability));
        } catch (apiError: any) {
            console.error('apiError get pet ', apiError);
            dispatch(error());
        }
    };
};

export const getNextAvailableDateThunk = (
    startDate: string,
    serviceId: string,
    petSize: string,
    staffId?: number
): ThunkAction<void, AvailabilityState, null, RootAction> => {
    return async dispatch => {
        dispatch(getNextDateStart());

        const marketplaceId = store.getState().marketplace.marketplace.id;
        let url =
            nextAvailableDateUrl +
            `?marketplace_id=${marketplaceId}&location_id=nil&start_date=${startDate}&pet_size=${petSize}&service_id=${serviceId}`;

        if (staffId) {
            url += `&staff_id=${staffId}`;
        }

        try {
            const response: AxiosResponse<Array<AvailabilityDtos>> = await ApiPublicClient.get(
                url,
                createTokenConfig()
            );
            const availability = convertAvailabilityData(response.data);
            dispatch(getNextDateSuccess(availability));
        } catch (apiError: any) {
            console.error('apiError get pet ', apiError);
            dispatch(error());
        }
    };
};

export const reset = (): AvailabilityAction => {
    return {
        type: AVAILABILITY_RESET
    };
};

const fetchStart = (): AvailabilityAction => {
    return {
        type: AVAILABILITY_FETCH_START
    };
};

const fetchSuccess = (availableHours: Availability[]): AvailabilityAction => {
    return {
        type: AVAILABILITY_FETCH_SUCCESS,
        payload: {
            availableHours
        }
    };
};

const getNextDateStart = (): AvailabilityAction => {
    return {
        type: AVAILABILITY_GET_NEXT_DATE_START
    };
};

const getNextDateSuccess = (availableHours: Availability[]): AvailabilityAction => {
    return {
        type: AVAILABILITY_GET_NEXT_DATE_SUCCESS,
        payload: {
            availableHours
        }
    };
};

const error = (): AvailabilityAction => {
    return {
        type: AVAILABILITY_ERROR
    };
};
