import { PetState, PetStatus } from './PetState';
import {
    PET_ERROR,
    PET_FETCH_FAILED,
    PET_RESET,
    PET_FETCH_START,
    PET_FETCH_SUCCESS,
    PetAction,
    PET_SAVE_SUCCESS,
    PET_SET_AGES,
    PET_SET_SIZES,
    PET_SET_HAIR_LENGTHS
} from 'actions/pet/PetActionsTypes';
import { Reducer } from 'redux';

const initialState: PetState = {
    pet: undefined,
    pets: [],
    status: PetStatus.Initial,
    loading: false,

    sizes: {
        cats: [],
        dogs: [],
        exotics: []
    },
    ages: {
        cats: [],
        dogs: [],
        exotics: []
    },
    hairLengths: {
        cats: [],
        dogs: [],
        exotics: []
    }
};

export const petReducer: Reducer<PetState, PetAction> = (state = initialState, action) => {
    switch (action.type) {
        case PET_RESET:
            return initialState;
        case PET_FETCH_START:
            return {
                ...state,
                status: PetStatus.FetchStart,
                loading: true
            };
        case PET_FETCH_SUCCESS:
            return {
                ...state,
                pets: action.pets,
                status: PetStatus.FetchSuccess,
                loading: false
            };
        case PET_SAVE_SUCCESS:
            return {
                ...state,
                pet: action.pet,
                status: PetStatus.SaveSuccess,
                loading: false
            };
        case PET_FETCH_FAILED:
            return {
                ...state,
                status: PetStatus.FetchFailed,
                loading: false
            };
        case PET_ERROR:
            return {
                ...state,
                status: PetStatus.Error,
                loading: false
            };
        case PET_SET_AGES:
            return {
                ...state,
                ages: action.ages
            };
        case PET_SET_SIZES:
            return {
                ...state,
                sizes: action.sizes
            };
        case PET_SET_HAIR_LENGTHS:
            return {
                ...state,
                hairLengths: action.hairLengths
            };
        default:
            return state;
    }
};

export default petReducer;
