import React from "react";
import { Box, Button } from "@mui/material";
import "./BlackButton.scss";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";

const BlackButton = (props: any) => {
  let { label, boxProps, loading, disabled, ...otherProps } = props;

  const spinner = <PulseLoader color="#fff" loading={loading} size={6} />;

  return (
    <Box {...boxProps}>
      {props.to && (
        <Link to={props.to} className="BlackButton" {...otherProps}>
          {props.label}
        </Link>
      )}

      {!props.to && (
        <Button
          className="BlackButton"
          disabled={disabled || loading}
          {...otherProps}
        >
          {!loading && props.label}
          {loading && spinner}
        </Button>
      )}
    </Box>
  );
};

export default BlackButton;
