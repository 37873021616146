import { BookingStep } from 'components/Booking/Booking';
import { Action } from 'redux';

//Types
export const CUSTOMER_RESET = 'BOOKING_RESET';
export const CUSTOMER_ERROR = 'BOOKING_ERROR';
export const CUSTOMER_LOADING = 'BOOKING_LOADING';

interface CustomerResetAction extends Action<typeof CUSTOMER_RESET> {}
interface CustomerErrorAction extends Action<typeof CUSTOMER_ERROR> {
    step?: BookingStep;
}
interface CustomerLoadingAction extends Action<typeof CUSTOMER_LOADING> {
    loading: boolean;
}

//SignUpAction
export type CustomerAction = CustomerResetAction | CustomerErrorAction | CustomerLoadingAction;
