import { BookingStep } from 'components/Booking/Booking';
import { NewBooking } from 'model/Booking';
import { Action } from 'redux';

//Types
export const BOOKING_RESET = 'BOOKING_RESET';
export const BOOKING_CREATE_START = 'BOOKING_CREATE_START';
export const BOOKING_CREATE_SUCCESS = 'BOOKING_CREATE_SUCCESS';
export const BOOKING_DEPOSIT_CREATE_START = 'BOOKING_DEPOSIT_CREATE_START';
export const BOOKING_DEPOSIT_CREATE_SUCCESS = 'BOOKING_DEPOSIT_CREATE_SUCCESS';
export const BOOKING_GET_SIBLINGS_START = 'BOOKING_GET_SIBLINGS_START';
export const BOOKING_GET_SIBLINGS_SUCCESS = 'BOOKING_GET_SIBLINGS_SUCCESS';
export const BOOKING_ERROR = 'BOOKING_ERROR';

interface BookingResetAction extends Action<typeof BOOKING_RESET> {}

interface BookingCreateStartAction extends Action<typeof BOOKING_CREATE_START> {}
interface BookingCreateSuccessAction extends Action<typeof BOOKING_CREATE_SUCCESS> {
    booking: NewBooking;
}
//
interface BookingDepositCreateStartAction extends Action<typeof BOOKING_DEPOSIT_CREATE_START> {}
interface BookingDepositCreateSuccessAction extends Action<typeof BOOKING_DEPOSIT_CREATE_SUCCESS> {
    booking: NewBooking;
}
//
interface BookingGetSiblingsStartAction extends Action<typeof BOOKING_GET_SIBLINGS_START> {}
interface BookingGetSiblingsSuccessAction extends Action<typeof BOOKING_GET_SIBLINGS_SUCCESS> {
    pets: Array<string>;
}
//
interface BookingErrorAction extends Action<typeof BOOKING_ERROR> {
    step?: BookingStep;
}

//SignUpAction
export type BookingAction =
    | BookingResetAction
    | BookingCreateStartAction
    | BookingCreateSuccessAction
    | BookingDepositCreateStartAction
    | BookingDepositCreateSuccessAction
    | BookingGetSiblingsStartAction
    | BookingGetSiblingsSuccessAction
    | BookingErrorAction;
