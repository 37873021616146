import { BookingLayout } from 'components/UI/Layout';
import { hero1 } from 'assets/images';
import React from 'react';
import MarketplaceInfo from 'components/Booking/MarketplaceInfo/MarketplaceInfo';
import { FullWidthImage, Heading, WideAnchor } from 'components/Commons';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { MarketplaceState } from 'reducers/marketplace/MarketplaceState';
import PoweredByCuddles from 'components/UI/Common/PoweredByCuddles/PoweredByCuddles';

interface BookingHomeProps {
    onContinue: (IsReturningClient: boolean) => void;
}

const BookingHome: React.FC<BookingHomeProps> = props => {
    const marketplaceState = useSelector<RootState, MarketplaceState>(state => state.marketplace);

    const marketplaceName = marketplaceState.marketplace.name;

    return (
        <BookingLayout
            sx={{ '.BookingLayout-left-column': { display: 'flex' } }}
            leftColumn={
                <Box className="BookingLayout-left-column-content">
                    <Grid item xs={12}>
                        <MarketplaceInfo
                            marketplace={marketplaceState.marketplace}
                            sx={{ mb: { xs: 6, lg: 4.5 } }}
                        />

                        <Heading title="Let's make an appointment" />

                        {marketplaceState.marketplace.allowNewClients && (
                            <WideAnchor
                                id="new-client"
                                title="New client"
                                description={`This is my first time booking with ${marketplaceName}`}
                                onClick={() => props.onContinue(false)}
                            />
                        )}
                        <WideAnchor
                            id="returning-client"
                            title="Returning client"
                            description={`I’ve booked with ${marketplaceName} before 🐶`}
                            onClick={() => props.onContinue(true)}
                        />
                        <PoweredByCuddles showOnDesktop={false} showOnMobile={true} />
                    </Grid>
                </Box>
            }
            rightColumn={<FullWidthImage src={hero1} alt="Cuddles Booking flow" />}
        />
    );
};

export default BookingHome;
