import FormError from 'components/UI/FormsPart/FormError/FormError';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AlertsState } from 'reducers/alerts/AlertsState';
import { RootState } from 'store';

import './FormErrorList.scss';
import { BookingStep } from 'components/Booking/Booking';

interface FormErrorListProps {
    step: BookingStep | 'any';
}

const FormErrorList: React.FC<FormErrorListProps> = props => {
    const alerts = useSelector<RootState, AlertsState>(state => state.alert);

    const noDupesAlerts = useMemo(() => {
        return alerts.reduce((arr, alert) => {
            const alertExists = arr.find(
                item => item.text === alert.text && item.uuid !== alert.uuid
            );

            // If alert isn't duplicated and is from current step
            if (!alertExists && (alert.step === props.step || props.step === 'any')) {
                arr.push(alert);
            }

            return arr;
        }, [] as AlertsState);
    }, [alerts, props.step]);

    return (
        <div className="FormErrorList">
            {noDupesAlerts.length > 0 &&
                noDupesAlerts.map(alert => (
                    <FormError
                        key={alert.text}
                        title={alert.text}
                        boxProps={{
                            sx: {
                                'flex': '0 0 100%',
                                'mt': '24px !important',
                                'mb': '16px !important',
                                ' > div': {
                                    color: alert.textColor,
                                    backgroundColor: alert.color
                                }
                            }
                        }}
                    />
                ))}
        </div>
    );
};

export default FormErrorList;
