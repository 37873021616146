import { fetchMarketplaceBySlugThunk } from 'actions/marketplace/MarketplaceActions';
import { IntakeForm as IntakeFormComponent } from 'components/IntakeForm/IntakeForm';
import { IntakeFormDisabled } from 'components/IntakeForm/IntakeFormDisabled';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { MarketplaceState, MarketplaceStatus } from 'reducers/marketplace/MarketplaceState';
import { RootState } from 'store';

interface MarketplaceSlug {
    slug: string;
}

const IntakeForm: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams<MarketplaceSlug>();

    const stateMarketplace = useSelector<RootState, MarketplaceState>(state => state.marketplace);

    const loading =
        stateMarketplace.status == MarketplaceStatus.Fetching ||
        stateMarketplace.status == MarketplaceStatus.Initial ||
        stateMarketplace.status == MarketplaceStatus.Cleared ||
        stateMarketplace.status == MarketplaceStatus.Error;

    const show = React.useMemo(() => {
        return (
            stateMarketplace.marketplace.id !== undefined &&
            stateMarketplace.marketplace.intakeFormEnabled
        );
    }, [stateMarketplace.marketplace]);

    React.useEffect(() => {
        document.title = 'Cuddles - Intake Form';

        const fetchData = async () => {
            await dispatch(fetchMarketplaceBySlugThunk(params.slug));
        };

        if (
            !stateMarketplace?.marketplace.id ||
            stateMarketplace?.marketplace.slug !== params.slug
        ) {
            fetchData();
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (stateMarketplace.status === MarketplaceStatus.FetchSuccess) {
            document.title = `${stateMarketplace.marketplace.name} - Intake Form`;
        }

        if (stateMarketplace.status === MarketplaceStatus.Error) {
            history.push('/');
        }
    }, [stateMarketplace]);

    return (
        <>
            {show ? (
                <IntakeFormComponent />
            ) : (
                !loading && (
                    <>
                        <IntakeFormDisabled />
                    </>
                )
            )}
        </>
    );
};

export default IntakeForm;
