import { Agreement, AgreementSignature } from 'model';

export interface AgreementsState {
    loading: boolean;
    status: AgreementsStatus;
    agreements: Array<Agreement>;
    signature?: AgreementSignature;
}

export enum AgreementsStatus {
    Error,
    Initial,

    FetchStart,
    FetchSuccess,

    GetSignatureStart,
    GetSignatureSuccess
}
