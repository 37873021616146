import { StaffDto } from 'actions/staffs/StaffsDtos';
import { Staff } from 'model';

export const convertToStaff = (dto: StaffDto): Staff => {
  return {
    id: dto.id,
    name: [dto.first_name, dto.last_name].join(' '),
    email: dto.email,
    avatar: dto.image,
  };
};
