import React from 'react';
import './PoweredByCuddles.scss';
import { cuddlesLogo } from 'assets/images';
import { useMarketplace } from 'hooks';

interface PoweredByCuddlesProps {
    showOnDesktop?: boolean;
    showOnMobile?: boolean;
    customClass?: string;
}

const PoweredByCuddles: React.FC<PoweredByCuddlesProps> = ({
    showOnDesktop,
    showOnMobile,
    customClass
}) => {
    const marketplace = useMarketplace();
    const marketplaceName = marketplace.name;

    // Determine the classes to apply based on the props
    const className = [
        'powered-by-cuddles',
        showOnDesktop === true ? 'show-on-desktop' : '',
        showOnDesktop === false ? 'hide-on-desktop' : '',
        showOnMobile === true ? 'show-on-mobile' : '',
        showOnMobile === false ? 'hide-on-mobile' : '',
        customClass || '' // Include the customClass if provided
    ]
        .filter(Boolean) // Remove any empty strings from the array
        .join(' ');

    return (
        <div className={className}>
            <a
                href={`https://www.cuddlesapp.com?utm_source=online-booking&utm_medium=${marketplaceName}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <p>
                    Powered by <img src={cuddlesLogo} alt="Cuddles Logo" className="cuddles-logo" />
                </p>
            </a>
        </div>
    );
};

export default PoweredByCuddles;
