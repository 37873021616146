import React, { useMemo } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import { Heading } from 'components/Commons';
import StaffChip from 'components/Staff/StaffChip/StaffChip';
import StaffChipNoPreference from 'components/Staff/StaffChip/StaffChipNoPreference';
import { BookingLayout } from 'components/UI/Layout';
import { EditBooking, Marketplace } from 'model';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { StaffsState } from 'reducers/staffs/StaffsState';
import FormErrorList from 'components/UI/FormsPart/FormErrorList/FormErrorList';
import { BookingStep } from 'components/Booking/Booking';

interface BookingSelectStaffProps {
    booking: EditBooking;
    marketplace: Marketplace;
    //
    onChange: (booking: EditBooking) => void;
    onContinue: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            display: 'grid',
            gap: '16px 30px',
            gridTemplateColumns: '1fr',

            [theme.breakpoints.up('md')]: {
                rowGap: 18,
                gridTemplateColumns: 'repeat(3, 1fr)'
            }
        }
    })
);

const BookingSelectStaff: React.FC<BookingSelectStaffProps> = props => {
    const classes = useStyles();

    const { staffs } = useSelector<RootState, StaffsState>(state => state.staffs);

    const staffChangeHandler = (id?: number) => {
        props.onChange({
            ...props.booking,
            staffId: id
        });
    };

    const staffsList = useMemo(() => {
        return staffs.filter(staff => {
            return props.booking.petsToBooking[0].service?.staffIds.includes(staff.id);
        });
    }, [staffs, props.booking]);

    return (
        <BookingLayout
            showFooter={{
                disabled: false,
                onClick: props.onContinue
            }}
            showResume={props.booking}
            leftColumn={
                <Grid item xs={12}>
                    <Heading boxProps={{ mb: 3 }} title={<small>Select staff</small>} />

                    <FormErrorList step={BookingStep.StaffSelect} />

                    <Box className={classes.grid}>
                        <StaffChipNoPreference
                            checked={props.booking.staffId === undefined}
                            onChange={staffChangeHandler}
                        />

                        {staffsList.map((staff, index) => (
                            <StaffChip
                                index={index}
                                staff={staff}
                                key={staff.id}
                                checked={staff.id === props.booking.staffId}
                                onChange={staffChangeHandler}
                            />
                        ))}
                    </Box>
                </Grid>
            }
        />
    );
};

export default BookingSelectStaff;
