/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { BlackButton, CustomField, CustomSelect, PetImage, SearchAutocomplete } from 'components';
import { Option, Pet, PetSize, PetType, PetVaccine } from 'model';
import { useMarketplace, useMasterData, usePetData } from 'hooks';
import { isString } from 'lodash';
import { SearchAutocompleteOption } from 'components/UI/FormsPart/SearchAutocomplete/SearchAutocomplete';
import RadioGroup from 'components/UI/FormsPart/RadioGroup/RadioGroup';
import PetBirthdayField from 'components/Pet/PetBirthdayField/PetBirthdayField';
import VaccinesForm from 'components/Pet/VaccinesForm';
import { getSizeByWeight } from 'utils/WeightUtil';

interface PetFormError {
    [key: string]: boolean;
}

interface PetFormProps {
    pet?: Pet;
    update?: boolean;
    showRemove?: boolean;
    loading?: boolean;
    //
    onSubmit?: (pet: Pet) => void;
    onUpdate?: (pet: Pet) => void;
    onRemove?: (values: Pet) => void;
    onChange?: (pet: Pet, invalid: boolean) => void;
}

export const PetForm: React.FunctionComponent<PetFormProps> = props => {
    const marketplace = useMarketplace();
    const { petTypes, petGenders: genders, breeds } = useMasterData();

    const { sizes: petSizes, hairLengths: petHairLengths, hairColors } = usePetData();

    const initialFormValidation = {
        name: false,
        type: false,
        age: false,
        gender: false,
        breed: false,
        size: false
    };

    const showRemove = props.showRemove ? props.showRemove : false;

    const [invalid, setInvalid] = useState<boolean>(true);
    const [touched, setTouched] = useState<PetFormError>(initialFormValidation);
    const [errors, setErrors] = useState<PetFormError>(initialFormValidation);
    const [values, setValues] = useState<Pet>(
        props.pet
            ? {...props.pet,
                weight: props.pet.weight ? Math.trunc(props.pet.weight) : undefined
            }
            : {
                  name: '',
                  type: { id: '', singular: '', plural: '' },
                  age: '',
                  gender: '',
                  breed: NaN,
                  hairColor: { id: '', description: '', name: '' },
                  hairLength: { id: '', description: '', name: '' },
                  size: '',
                  image: '',
                  birthdate: '',
                  uuid: uuid(),
                  active: true,
                  vaccines: []
              }
    );

    useEffect(() => {
        // eslint-disable-next-line
        formValidate((({ image, ...o }) => o)(values));
    }, [values]);

    useEffect(() => {
        props.onChange && props.onChange(values, invalid);
    }, [values, invalid]);

    const onFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
        setTouched({ ...touched, [name]: true });
    };

    const onFieldChangeWeight = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        const cleanValue = value.replace(/\D/g, '').substring(0,3);
        const numericValue = Number(cleanValue);

        const size = values['type']?.id === 'dogs' ? getSizeByWeight(numericValue, marketplace.petTypes?.dogs?.sizes ?? [])?.id ?? '' : (values['type']?.id === 'cats' ? 'small' : '');

        setValues({ ...values, [name]: cleanValue === '0' ? '' : cleanValue, ['size']: size});
        setTouched({ ...touched, [name]: true });
    };

    const onFieldChangeType = (selectedTypeId: string) => {
        const type = petTypes.find(type => type.id === selectedTypeId);

        if (!type) {
            return;
        }

        setValues({ ...values, type: type, breed: NaN, size: type.id === 'dogs' ? (values['weight'] !== undefined ? getSizeByWeight(values['weight'], marketplace.petTypes?.dogs?.sizes ?? [])?.id ??'' : ''): 'small' });
        setTouched({ ...touched, type: true, breed: true, size: true });
    };

    const onFieldChangeHairLength = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const hairLength = hairLengthOptions.find(opt => opt.id === value);

        setValues({ ...values, [name]: hairLength });
        setTouched({ ...touched, [name]: true });
    };

    const onFieldChangeHairColor = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        const hairLength = colorOptions.find(opt => opt.id === value);

        setValues({ ...values, [name]: hairLength });
        setTouched({ ...touched, [name]: true });
    };

    const onAutocompleteChange = (value: SearchAutocompleteOption | null) => {
        if (!value || isString(value)) return;

        setValues({ ...values, breed: Number(value.id) });
        setTouched({ ...touched, [value.name]: true });
    };

    const onImageChange = (file: File | undefined, src: Pet['image']) => {
        if (file) {
            setValues(old => ({ ...old, image: src, imageFileToUpload: file }));
        } else {
            setValues(old => ({ ...old, image: '' }));
        }
    };

    const onbirthdayChange = (value: string) => {
        setValues(old => ({ ...old, birthdate: value }));
    };

    // eslint-disable-next-line
    const formValidate = (fieldValues: any) => {
        const keys = Object.keys(fieldValues);

        const mapErrors = (value: string) => {
            if (['image', 'age', 'hairColor', 'birthdate', 'size'].includes(value)) {
                return [[value], false];
            }

            if (value.includes('vaccines')) {
                const petVaccines: Array<PetVaccine> = fieldValues['vaccines'];

                return [
                    [value],
                    petVaccines.length > 0
                        ? petVaccines.some(vaccine => vaccine.expiration === undefined)
                        : false
                ];
            }

            if (value.includes('hairLength')) {
                return [[value], fieldValues['hairLength'] && !fieldValues['hairLength'].id];
            }

            return [
                [value],
                !fieldValues[value] || (fieldValues[value] && fieldValues[value].length === 0)
            ];
        };

        const newErrors = Object.fromEntries(keys.map(mapErrors));
        const filteredNewErrors = Object.fromEntries(
            keys
                .filter((value: string) => {
                    return touched[value];
                })
                .map(mapErrors)
        );

        setInvalid(!Object.values(newErrors).every(x => x === false));
        setErrors({ ...filteredNewErrors, image: false });
    };

    const selectedBreed = useMemo(() => {
        let breedsArray: Option<number>[] = [];

        if (values.type.id) {
            breedsArray = [...breeds[`${values.type.id}` as keyof typeof breeds]];
        }

        return breedsArray.find(breed => breed.id === values.breed);
    }, [values.breed]);

    const types: Option<string>[] = useMemo(() => {
        return petTypes.reduce((arr: Option<string>[], type: PetType) => {
            if (petSizes[type.id as keyof typeof breeds]?.length > 0 || type.id === props.pet?.type.id) {
                arr.push({
                    id: type.id,
                    name: type.singular,
                    description: type.singular
                });
            }

            return arr;
        }, []);
    }, [petTypes, petSizes]);

    const sizes: Array<Option<string>> = useMemo(() => {
        return (petSizes[`${values.type.id}` as keyof typeof breeds] ?? []).map(
            (size: PetSize) => ({
                id: size.element.id,
                description: size.element.description,
                name: `${size.element.name} ${size.element.description}`,
                element: size.element
            })
        );
    }, [values.type.id, petSizes]);

    const onFormRemove = () => {
        props.onRemove && props.onRemove(values);
    };

    const onFormUpdate = () => {
        props.onUpdate && props.onUpdate(values);
    };

    const onVaccineChange = (vaccines: Array<PetVaccine>) => {
        setValues({
            ...values,
            vaccines
        });
    };

    const breedOptions = useMemo(() => {
        const breedsByType = breeds[`${values.type.id}` as keyof typeof breeds]
            ? breeds[`${values.type.id}` as keyof typeof breeds]
            : [];
        return breedsByType.length > 0 ? breedsByType.filter(b => b.id !== 0) : [];
    }, [values.type, breeds]);

    const hairLengthOptions = useMemo(() => {
        return petHairLengths[`${values.type.id}` as keyof typeof petHairLengths] ?? [];
    }, [values.type, petHairLengths]);

    const colorOptions = useMemo(() => {
        return hairColors[`${values.type.id}` as keyof typeof petHairLengths] ?? [];
    }, [values.type, hairColors]);

    const showBreedSelected = () => {
        return selectedBreed ? selectedBreed : ({} as SearchAutocompleteOption);
    };

    //

    return (
        <Box sx={{ mb: 2 }}>
            <Box
                sx={{
                    position: 'relative',
                    display: { xs: 'block', md: 'flex' },
                    textAlign: 'right',
                    alignItems: 'center'
                }}
            >
                {!props.pet && showRemove && (
                    <Box
                        sx={{
                            backgroundColor: 'var(--secondary-color)',
                            color: 'var(--white)',
                            borderRadius: 6,
                            fontSize: '0.8rem',
                            fontWeight: 500,
                            paddingY: 1,
                            paddingX: 1.8,
                            position: { xs: 'static', md: 'absolute' },
                            display: 'inline-block',
                            right: 0,
                            cursor: 'pointer'
                        }}
                        onClick={onFormRemove}
                    >
                        Remove
                    </Box>
                )}

                <PetImage
                    name="photo"
                    label="Upload a photo of your pet"
                    {...(values.image && { value: values.image })}
                    onChangeFn={onImageChange}
                />
            </Box>

            <Box sx={{ mt: 3, mb: 1 }}>
                <RadioGroup
                    name="pet_type"
                    label="Pet type"
                    defaultValue={values.type.id}
                    options={types}
                    onChange={onFieldChangeType}
                />
            </Box>

            <Grid container columnSpacing={3}>
                <Grid item xs={12}>
                    <CustomField
                        id="petform_input_petname"
                        type="text"
                        name="name"
                        label="Pet name"
                        required
                        value={values.name}
                        onChange={onFieldChange}
                        {...(errors.name && { error: true })}
                    />
                </Grid>

                <Grid item lg={6} xs={12}>
                    <SearchAutocomplete
                        id="petform_autocomplete_input"
                        required
                        label="Breed"
                        key={values.type.id}
                        value={showBreedSelected()}
                        onChange={onAutocompleteChange}
                        options={breedOptions}
                        error={errors.breed}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <CustomField
                        id="petform_input_weight"
                        name="weight"
                        label="Weight"
                        required
                        value={values.weight}
                        onChange={onFieldChangeWeight}
                        error={errors.weight}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <CustomSelect
                        id="petform_list_petgender"
                        name="gender"
                        label="Gender"
                        required
                        value={values.gender}
                        options={genders}
                        onChange={onFieldChange}
                        error={errors.gender}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <CustomSelect
                        id="petform_list_hair_length"
                        name="hairLength"
                        label="Hair Length"
                        required
                        options={hairLengthOptions}
                        value={values.hairLength?.id}
                        onChange={onFieldChangeHairLength}
                        error={errors.hairLength}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <PetBirthdayField defaultValue={values.birthdate} onChange={onbirthdayChange} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <CustomSelect
                        id="petform_list_hair_color"
                        name="hairColor"
                        label="Color"
                        options={colorOptions}
                        value={values.hairColor?.id}
                        onChange={onFieldChangeHairColor}
                        error={errors.hairColor}
                    />
                </Grid>

                {marketplace.vaccines.length > 0 && (
                    <Grid item xs={12}>
                        <VaccinesForm
                            vaccines={props.pet?.vaccines || []}
                            onChange={onVaccineChange}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    {props.update && (
                        <BlackButton
                            label="Update"
                            boxProps={{
                                mt: 4,
                                sx: { button: { width: 'auto !important' } }
                            }}
                            onClick={onFormUpdate}
                            disabled={invalid}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default PetForm;
