import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { PetAges, PetHairLengths, PetSizes } from 'model';
import useMasterData from 'hooks/useMasterData';

export const usePetSizes = () => {
    const sizes = useSelector<RootState, PetSizes>(state => state.pets.sizes);

    return sizes;
};

export const usePetAges = () => {
    const ages = useSelector<RootState, PetAges>(state => state.pets.ages);

    return ages;
};

export const usePetHairLengths = () => {
    const hairLengths = useSelector<RootState, PetHairLengths>(state => state.pets.hairLengths);

    return hairLengths;
};

export const usePetData = () => {
    const masterData = useMasterData();

    return {
        hairLengths: usePetHairLengths(),
        hairColors: masterData.petHairColors,
        sizes: usePetSizes(),
        ages: usePetAges()
    };
};
