import { Action } from "redux";
import { Marketplace } from "model/Marketplace";
//Types
export const MARKETPLACE_RESET_ACTION_TYPE = "marketplace/reset";
export const MARKETPLACE_CLEAR_ACTION_TYPE = "marketplace/clear";
export const MARKETPLACE_FETCH_ACTION_TYPE = "marketplace/fetch_start";
export const MARKETPLACE_FETCH_SUCCESS_ACTION_TYPE = "marketplace/fetch_success";
export const MARKETPLACE_SET_ACTION_TYPE = "marketplace/set";
export const MARKETPLACE_SAVE_ACTION_TYPE = "marketplace/save_start";
export const MARKETPLACE_SAVE_SUCCESS_ACTION_TYPE = "marketplace/save_success";
export const MARKETPLACE_ERROR_ACTION_TYPE = "marketplace/error";

//Marketplace
interface MarketplaceResetAction extends Action<typeof MARKETPLACE_RESET_ACTION_TYPE> {}

interface MarketplaceClearAction extends Action<typeof MARKETPLACE_CLEAR_ACTION_TYPE> {}

interface MarketplaceFetchAction extends Action<typeof MARKETPLACE_FETCH_ACTION_TYPE> {}
interface MarketplaceFetchSuccessAction
  extends Action<typeof MARKETPLACE_FETCH_SUCCESS_ACTION_TYPE> {
    payload: {
      marketplace: Marketplace;
    }
  }

interface MarketplaceSetAction
  extends Action<typeof MARKETPLACE_SET_ACTION_TYPE> {
    payload: {
      marketplace: Marketplace;
    }
  }

interface MarketplaceSaveAction extends Action<typeof MARKETPLACE_SAVE_ACTION_TYPE> {}
interface MarketplaceSaveSuccessAction
  extends Action<typeof MARKETPLACE_SAVE_SUCCESS_ACTION_TYPE> {
    payload: {
      marketplace: Marketplace;
    }
  }

interface MarketplaceErrorAction extends Action<typeof MARKETPLACE_ERROR_ACTION_TYPE> {}

//AccountSetupAction
export type MarketplaceAction =
  MarketplaceResetAction
  | MarketplaceClearAction
  | MarketplaceFetchAction
  | MarketplaceFetchSuccessAction
  | MarketplaceSetAction
  | MarketplaceSaveAction
  | MarketplaceSaveSuccessAction
  | MarketplaceErrorAction;
