export const BookingPath: string = '/:slug';
export const IntakeFormPath: string = '/:slug/intake-form';
export const HomePath: string = '/:slug';
export const LoginPath: string = '/login';
export const SignUpPath: string = '/signup';
export const CreateAccountPath: string = '/signup/form';
export const ResetPath: string = '/auth/reset';
export const ResetSentPath: string = '/auth/reset/sent';

export type Path =
    | typeof BookingPath
    | typeof HomePath
    | typeof LoginPath
    | typeof SignUpPath
    | typeof CreateAccountPath
    | typeof ResetPath
    | typeof ResetSentPath;
