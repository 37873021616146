import { BookingStep } from 'components/Booking/Booking';
import {
    ADD_ALERT,
    AlertsAction,
    CLEAR_ALERT,
    REMOVE_ALERT,
    REMOVE_ALERTS_BY_STEP
} from './AlertsActionsTypes';
import { v4 as uuid } from 'uuid';
import { IntakeFormStep } from 'components/IntakeForm/IntakeForm';

const addAlert = (
    text: string,
    step: BookingStep | IntakeFormStep,
    color: string = 'var(--error-color)',
    textColor: string = 'var(--white)'
): AlertsAction => ({
    type: ADD_ALERT,
    payload: {
        uuid: uuid(),
        text,
        color,
        textColor,
        step
    }
});

const removeAlert = (alertUuid: string): AlertsAction => ({
    type: REMOVE_ALERT,
    payload: alertUuid
});

const clearAlerts = (): AlertsAction => ({
    type: CLEAR_ALERT
});

const removeAlertsByStep = (step: BookingStep) => ({
    type: REMOVE_ALERTS_BY_STEP,
    payload: {
        step
    }
});

const showSuccessAlert = (message: string, step: BookingStep | IntakeFormStep): AlertsAction => {
    return addAlert(message, step, 'var(--green-summer)');
};
const showErrorAlert = (message: string, step: BookingStep | IntakeFormStep): AlertsAction => {
    return addAlert(message, step, 'var(--error-color)');
};

export { addAlert, clearAlerts, removeAlert, showSuccessAlert, showErrorAlert, removeAlertsByStep };
