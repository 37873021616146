import { Pet, PetAges, PetHairLengths, PetSizes } from 'model';
import { Action } from 'redux';

//Types
export const PET_RESET = 'PET_RESET';
export const PET_FETCH_START = 'PET_FETCH_START';
export const PET_FETCH_SUCCESS = 'PET_FETCH_SUCCESS';
export const PET_FETCH_FAILED = 'PET_FETCH_FAILED';
export const PET_SAVE_START = 'PET_SAVE_START';
export const PET_SAVE_SUCCESS = 'PET_SAVE_SUCCESS';

export const PET_SET_AGES = 'PET_SET_AGES';
export const PET_SET_SIZES = 'PET_SET_SIZES';
export const PET_SET_HAIR_LENGTHS = 'PET_SET_HAIR_LENGTHS';

export const PET_ERROR = 'PET_ERROR';

interface PetResetAction extends Action<typeof PET_RESET> {}
interface PetStartAction extends Action<typeof PET_FETCH_START> {}
interface PetSaveStartAction extends Action<typeof PET_SAVE_START> {}
interface PetSuccessAction extends Action<typeof PET_FETCH_SUCCESS> {
    pets: Pet[];
}
interface PetFailedAction extends Action<typeof PET_FETCH_FAILED> {}

interface PetErrorAction extends Action<typeof PET_ERROR> {}
interface PetSaveSuccessAction extends Action<typeof PET_SAVE_SUCCESS> {
    pet: Pet;
}

interface PetSetAgesAction extends Action<typeof PET_SET_AGES> {
    ages: PetAges;
}
interface PetSetSizesAction extends Action<typeof PET_SET_SIZES> {
    sizes: PetSizes;
}
interface PetSetHairLengthsAction extends Action<typeof PET_SET_HAIR_LENGTHS> {
    hairLengths: PetHairLengths;
}

//SignUpAction
export type PetAction =
    | PetResetAction
    | PetStartAction
    | PetSuccessAction
    | PetFailedAction
    | PetSaveStartAction
    | PetSaveSuccessAction
    | PetErrorAction
    | PetSetAgesAction
    | PetSetSizesAction
    | PetSetHairLengthsAction;
