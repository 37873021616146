import { Box, Grid } from '@material-ui/core';
import { Heading } from 'components/Commons';
import { IntakeFormLayout } from 'components/IntakeForm/Layout/Layout';
import PetForm from 'components/Pet/PetForm';
import { PrimaryButton } from 'components/UI/Buttons';
import { Pet, ProgressBar } from 'model';
import { IntakeForm } from 'model/IntakeForm';
import React from 'react';

interface IntakeFormAddPetProps {
    progress: ProgressBar;
    intakeForm: IntakeForm;
    //
    onBack: () => void;
    onChange: (booking: IntakeForm) => void;
    onContinue: () => void;
}

export const IntakeFormAddPet: React.FC<IntakeFormAddPetProps> = props => {
    const [pet, setPet] = React.useState<Pet>({} as Pet);
    const [isInvalid, setIsInvalid] = React.useState(false);

    const petFormChangeHandler = (formPet: Pet, isFormInvalid: boolean) => {
        setPet(formPet);
        setIsInvalid(isFormInvalid);
    };

    const continueHandler = () => {
        props.onChange({
            ...props.intakeForm,
            pets: props.intakeForm.pets.filter(p => p.name !== pet.name).concat(pet)
        });

        props.onContinue();
    };

    return (
        <IntakeFormLayout
            showBack
            progress={props.progress}
            footer={
                <>
                    <PrimaryButton
                        label="Continue"
                        disabled={isInvalid}
                        onClick={continueHandler}
                    />
                </>
            }
            onBack={props.onBack}
        >
            <Grid item xs={12}>
                <>
                    <Heading title={<small>Add pet</small>} />

                    <Box sx={{ mt: 2 }}>
                        <PetForm
                            loading={false}
                            showRemove={false}
                            //
                            onRemove={() => {}}
                            onChange={petFormChangeHandler}
                        />
                    </Box>
                </>
            </Grid>
        </IntakeFormLayout>
    );
};
