import { PET_TYPE } from '../constants';
import { Pet, Service } from 'model';
import store from 'store';

export const filterServicesForPet = (services: Array<Service>, pet: Pet) => {
    return services.filter(service => isServiceAvailableForPet(service, pet));
};

export const isServiceAvailableForPet = (service: Service, pet: Pet) => {
    const marketplace = store.getState().marketplace.marketplace;

    // Marketplace has pet type active
    if (pet.type.id === PET_TYPE.CAT && !marketplace.catsEnabled) return false;
    if (pet.type.id === PET_TYPE.DOG && !marketplace.dogsEnabled) return false;
    if (pet.type.id === PET_TYPE.EXOTIC && !marketplace.exoticsEnabled) return false;

    //   Has fixed price
    if (service.fixedPrice.length > 0) {
        const hasValidFixedPrice = service.fixedPrice.some(fixedPrice => {
            return (
                fixedPrice.petType.id === pet.type.id && fixedPrice.price && fixedPrice.price > 0
            );
        });

        // Has variable duration
        if (service.variableDuration.length > 0) {
            return hasValidFixedPrice && isVariableDurationServiceAvailableForPet(service, pet);
        }

        return hasValidFixedPrice;
    }

    // Has variable price
    if (service.variablePrice) {
        const hasValidVariablePrice = service.variablePrice.prices.some(variablePrice => {
            return (
                variablePrice.petType?.id === pet.type.id &&
                variablePrice.petSize?.id === pet.size &&
                variablePrice.price &&
                variablePrice.price > 0
            );
        });

        // Has variable duration
        if (service.variableDuration.length > 0) {
            return hasValidVariablePrice && isVariableDurationServiceAvailableForPet(service, pet);
        }

        return hasValidVariablePrice;
    }

    // Edge cases
    return false;
};

export const searchForServicePetPrice = (service: Service, pet: Pet) => {
    let priceResult = 0;

    if (service.fixedPrice.length > 0) {
        priceResult = service.fixedPrice
            .filter(price => price.petType?.id === pet.type?.id)
            .map(p => p.price)
            .reduce((min, current) => Math.min(min || 0, current || 0), Infinity) as number;
    }

    if (service.variablePrice) {
        if (
            (service.variablePrice.variables.petSizeVariable &&
                service.variablePrice.variables.hairLengthVariable) ||
            service.variablePrice.variables.hairLengthVariable
        ) {
            priceResult = service.variablePrice.prices
                .filter(
                    price =>
                        price.petType?.id === pet.type?.id &&
                        price.petSize?.id === pet.size &&
                        (price.hairLength?.id === pet.hairLength?.id ||
                            pet.hairLength === undefined)
                )
                .map(p => p.price)
                .reduce((min, current) => Math.min(min || 0, current || 0), Infinity) as number;
        } else if (
            service.variablePrice.variables.petSizeVariable &&
            !service.variablePrice.variables.hairLengthVariable
        ) {
            priceResult = service.variablePrice.prices
                .filter(
                    price => price.petSize?.id === pet.size && price.petType?.id === pet.type?.id
                )
                .map(p => p.price)
                .reduce((min, current) => Math.min(min || 0, current || 0), Infinity) as number;
        }
    }

    if (!pet.type.id) {
        priceResult = 0;
    }

    return priceResult;
};

const isVariableDurationServiceAvailableForPet = (service: Service, pet: Pet) => {
    return service.variableDuration.some(
        variableDuration => variableDuration.petSize.id === pet.size
    );
};
