import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

import './SignPad.styles.scss';

interface SignPadProps {
    id?: string;
    height?: number;
    onRef?: React.LegacyRef<SignatureCanvas> | undefined;
    onChange?: () => void;
}

const SignPad: React.FC<SignPadProps> = props => {
    return (
        <div
            className="SignPad"
            style={
                {
                    '--sign-pad-height': `${props.height}px`
                } as React.CSSProperties
            }
        >
            <SignatureCanvas
                penColor="black"
                ref={props.onRef}
                canvasProps={{ height: props.height, id: props.id }}
                onEnd={props.onChange}
            />
        </div>
    );
};

export default SignPad;
