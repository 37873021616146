import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme, Typography } from '@mui/material';
import { notFound, poweredBy } from 'assets/images';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: "auto",
            marginBottom: "auto"
        },
        title: {
            fontWeight: 600,
            fontSize: "46px",
            lineHeight: "46px",
            textAlign: "center",
            [theme.breakpoints.down('sm')]: {
                fontSize: "28px",
                lineHeight: "28px",
            },
            marginBottom: "30px"

        },
        subTitle: {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "25.2px",
            [theme.breakpoints.down('sm')]: {
                fontSize: "16px",
                lineHeight: "22.4px",
            },
            textAlign: "center",
            marginBottom: "25px"

        },
        heroContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: "100px"
        },
        footerLogoContainer: {
            position: "fixed",
            left: 0,
            [theme.breakpoints.down('sm')]: {
                bottom: "5vh",
            },
            [theme.breakpoints.only('md')]: {
                bottom: "2vh",
            },
            [theme.breakpoints.only('lg')]: {
                bottom: "15vh",
            },
            [theme.breakpoints.only('xl')]: {
                bottom: "20vh",
            },
            width: "100%",
            textAlign: "center"
        },
        footerLogo: {
            [theme.breakpoints.down('sm')]: {
                bottom: "483px",
            },
            
            width: '127px',
        }
    })
);



export default function NotFound() {
    const classes = useStyles();


    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>Oops! Page Not Found</Typography>
            <Typography className={classes.subTitle}>Oh no! We cannot find the page <br /> you were looking for.</Typography>

            <Box className={classes.heroContainer}>    <img src={notFound}></img></Box>

            <Box className={classes.footerLogoContainer}>
                <img className={classes.footerLogo} src={poweredBy}></img>
            </Box>


        </Box>
    );
}
