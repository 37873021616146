import { Pet, PetSizes, PetAges, PetHairLengths } from 'model';

export interface PetState {
    pet: Pet | undefined;
    pets: Array<Pet>;
    status: PetStatus;
    loading: boolean;

    sizes: PetSizes;
    ages: PetAges;
    hairLengths: PetHairLengths;
}

export enum PetStatus {
    Initial,
    SetData,
    FetchStart,
    FetchSuccess,
    FetchFailed,
    SaveStart,
    SaveSuccess,
    Error
}
