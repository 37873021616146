import React from 'react';
import { StaffChipProps } from 'components/Staff/StaffChip/StaffChip';
import { Radio } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faShuffle } from '@fortawesome/pro-regular-svg-icons';
import './StaffChip.styles.scss';

type StaffChipNoPreferenceProps = Omit<StaffChipProps, 'staff' | 'index'>;

const StaffChipNoPreference: React.FC<StaffChipNoPreferenceProps> = props => {
    const changeHandler = () => {
        props.onChange(undefined);
    };

    return (
        <div
            className="StaffChip"
            data-selected={props.checked}
            data-variant="no-preference"
            onClick={changeHandler}
        >
            <Radio
                disableRipple
                checked={props.checked}
                className="StaffChip-radio"
                id="staff-no-preference"
                checkedIcon={<FontAwesomeIcon icon={faCircleCheck} onChange={changeHandler} />}
            />

            <div>
                <p className="StaffChip-name">
                    <FontAwesomeIcon icon={faShuffle} className="StaffChip-shuffle" />
                    No preference
                </p>
                <p className="StaffChip-description">Any available staff member</p>
            </div>
        </div>
    );
};

export default StaffChipNoPreference;
