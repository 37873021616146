import { Container } from '@material-ui/core';
import { Header } from 'components/UI/Layout';
import { ProgressBar } from 'model';
import React from 'react';
import store from 'store';

import './Layout.styles.scss';
import { hero5 } from 'assets/images';
import PoweredByCuddles from 'components/UI/Common/PoweredByCuddles/PoweredByCuddles';

export interface IntakeFormLayout
    extends React.PropsWithChildren<{
        showBack?: boolean;
        progress?: ProgressBar;
        footer?: React.ReactNode;
        onBack?: () => void;
    }> {}

export const IntakeFormLayout: React.FC<IntakeFormLayout> = props => {
    const marketplace = store.getState().marketplace.marketplace;

    return (
        <div className="IntakeForm-layout">
            <Header
                progress={props.progress}
                backButton={props.showBack || false}
                logo={marketplace.logo}
                onBack={props.onBack}
            />

            <div className="IntakeForm-layout-wrapper">
                <Container className="IntakeForm-layout-container">
                    <div className="IntakeForm-layout-leftColumn">
                        <div className="IntakeForm-layout-leftColumn-body">
                            <div className="IntakeForm-layout-leftColumn-body-content">
                                {props.children}
                            </div>
                            <div className="IntakeForm-layout-leftColumn-poweredByCuddles">
                                <PoweredByCuddles showOnDesktop={true} showOnMobile={true} />
                            </div>
                        </div>

                        {props.footer && (
                            <div className="IntakeForm-layout-footer">
                                {props.footer}

                                <div className="IntakeForm-layout-footer-divider"></div>
                            </div>
                        )}
                    </div>
                </Container>

                {props.footer && <div className="IntakeForm-layout-footer-background"></div>}

                <div
                    className="IntakeForm-layout-rightColumn"
                    style={{
                        backgroundImage: `url(${hero5})`
                    }}
                />
            </div>
        </div>
    );
};
