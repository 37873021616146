import { MarketplaceState, MarketplaceStatus } from "./MarketplaceState";
import {
  MARKETPLACE_RESET_ACTION_TYPE,
  MARKETPLACE_CLEAR_ACTION_TYPE,
  MARKETPLACE_FETCH_ACTION_TYPE,
  MARKETPLACE_FETCH_SUCCESS_ACTION_TYPE,
  MARKETPLACE_ERROR_ACTION_TYPE,
  MarketplaceAction,
} from "actions/marketplace/MarketplaceActionsTypes";
import { Reducer } from "redux";
import { emptyMarketplace } from "model/Marketplace";
import { initMarketplace } from "auth/Marketplace";

const marketplace = initMarketplace;

const initialState: MarketplaceState = {
  marketplace,
  status: MarketplaceStatus.Initial,
  loading: true,
};

export const marketplaceReducer: Reducer<
  MarketplaceState,
  MarketplaceAction
> = (state = initialState, action) => {
  switch (action.type) {
    case MARKETPLACE_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case MARKETPLACE_CLEAR_ACTION_TYPE:
      return {
        ...state,
        status: MarketplaceStatus.Cleared,
        marketplace: emptyMarketplace,
        loading: true,
      };
    case MARKETPLACE_FETCH_ACTION_TYPE:
      return {
        ...state,
        status: MarketplaceStatus.Fetching,
      };
    case MARKETPLACE_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: MarketplaceStatus.FetchSuccess,
        loading: false,
        marketplace: action.payload.marketplace,
      };
    case MARKETPLACE_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: MarketplaceStatus.Error,
        loading: true,
      };
    default:
      return state;
  }
};

export default marketplaceReducer;
