import { Grid } from '@mui/material';
import useNonInitialEffect from '@versiondos/hooks';
import { BookingStep } from 'components/Booking/Booking';
import { Heading } from 'components/Commons';
import EmergencyContactForm from 'components/Customer/EmergencyContactForm';
import { CustomField } from 'components/UI/FormsPart';
import { CustomMaskField } from 'components/UI/FormsPart/CustomMaskField/CustomMaskField';
import { CustomPhoneField } from 'components/UI/FormsPart/CustomPhoneField/CustomPhoneField';
import FormErrorList from 'components/UI/FormsPart/FormErrorList/FormErrorList';
import { BookingLayout } from 'components/UI/Layout';
import { useMarketplace } from 'hooks';
import { EditBooking, EmergencyContact, MarketplaceType } from 'model';
import React, { useMemo, useState } from 'react';
import { validateEmail, validatePhone } from 'utils';

interface BookingCustomerProps {
    booking: EditBooking;
    //
    onChange: (booking: EditBooking) => void;
    onContinue: () => void;
}

const BookingCustomer: React.FC<BookingCustomerProps> = props => {
    /**
     * Hooks
     */
    const marketplace = useMarketplace();

    /**
     * Local state
     */
    const [touched, setTouched] = useState({
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        address: false,
        city: false,
        state: false,
        zipCode: false
    });

    const [customer, setCustomer] = useState<EditBooking['customer']>({
        first_name: props.booking.customer?.first_name ?? '',
        last_name: props.booking.customer?.last_name ?? '',
        email: props.booking.customer?.email ?? '',
        phone: props.booking.customer?.phone ?? '',
        blocked: props.booking.customer?.blocked ?? false,
        address: {
            address: props.booking.customer?.address?.address,
            city: props.booking.customer?.address?.city,
            state: props.booking.customer?.address?.state,
            zipCode: props.booking.customer?.address?.zipCode
        },
        emergencyContact: undefined
    });

    const [isEmergencyContactRequired, setIsEmergencyContactRequired] = useState<boolean>(
        !!props.booking.customer.emergencyContact?.first_name ?? false
    );

    const marketplaceIsCanadian = marketplace.address?.some(address => address.country === 'CA');

    /**
     * Computed values
     */

    const isAddressRequired = useMemo(() => {
        return [MarketplaceType.INHOME, MarketplaceType.MOBILE].includes(marketplace.businessType);
    }, [marketplace]);

    const errors = useMemo(() => {
        return Object.entries(customer).reduce(
            // eslint-disable-next-line
            (obj, item: [string, any], i) => {
                const name = item[0];
                const value = item[1];

                if (name === 'first_name') obj.first_name = value.length === 0;

                if (name === 'last_name') obj.last_name = value.length === 0;

                if (name === 'email') obj.email = value.length === 0 || validateEmail(value);

                if (name === 'phone') obj.phone = value.length === 0 || validatePhone(value);

                if (name === 'address' && isAddressRequired) {
                    obj.address = !value?.address || value?.address?.length === 0;
                    obj.city = !value?.city || value?.city?.length === 0;
                    obj.state = !value?.state || value?.state?.length === 0;
                    obj.zipCode = !value?.zipCode || value?.zipCode?.length === 0;
                }

                if (name === 'emergencyContact' && isEmergencyContactRequired) {
                    obj.emergencyContact =
                        value === undefined ||
                        (!value?.first_name &&
                            !value?.last_name &&
                            !value?.phone &&
                            !value?.relationship?.id);
                }

                return obj;
            },
            {
                first_name: false,
                last_name: false,
                email: false,
                phone: false,
                address: false,
                city: false,
                state: false,
                zipCode: false,
                emergencyContact: false
            }
        );
    }, [customer, isAddressRequired, isEmergencyContactRequired]);

    const invalid = useMemo(() => {
        return Object.values(errors).filter(v => v);
    }, [errors]);

    /**
     * Event handlers
     */
    const fieldChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setTouched({ ...touched, [name]: true });

        if (['address', 'city', 'state', 'zipCode'].includes(name)) {
            setCustomer({
                ...customer,
                address: {
                    ...customer.address,
                    [name]: value
                }
            });
        } else {
            setCustomer({ ...customer, [name]: value });
        }
    };

    const emergencyContactChangeHandler = (contact?: EmergencyContact) => {
        setCustomer(prev => ({ ...prev, emergencyContact: contact }));
    };

    const continueHandler = () => {
        props.onContinue();
    };

    /**
     * Effects
     */
    useNonInitialEffect(() => {
        props.onChange({ ...props.booking, customer });
    }, [customer]);

    return (
        <BookingLayout
            showFooter={{
                label: 'Continue',
                disabled: invalid.length > 0,
                onClick: continueHandler
            }}
            rightColumnMobile={true}
            showResume={props.booking}
            leftColumn={
                <Grid item xs={12}>
                    <Heading title="Personal info" />

                    <FormErrorList step={BookingStep.CustomerForm} />

                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={6}>
                            <CustomField
                                id="personalInfo_input_firstname"
                                required
                                type="text"
                                name="first_name"
                                label="First Name"
                                value={customer.first_name}
                                onChange={fieldChangeHandler}
                                error={touched.first_name && errors.first_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomField
                                id="personalInfo_input_lastname"
                                required
                                type="text"
                                name="last_name"
                                label="Last Name"
                                value={customer.last_name}
                                onChange={fieldChangeHandler}
                                error={touched.last_name && errors.last_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomField
                                id="personalInfo_input_email"
                                required
                                type="email"
                                name="email"
                                label="Email"
                                value={customer.email}
                                onChange={fieldChangeHandler}
                                error={touched.email && errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomPhoneField
                                id="personalInfo_input_phone"
                                required
                                type="text"
                                name="phone"
                                label="Phone Number"
                                value={customer.phone}
                                onChange={fieldChangeHandler}
                                error={touched.phone && errors.phone}
                            />
                        </Grid>

                        {isAddressRequired && (
                            <>
                                <Grid item xs={12}>
                                    <CustomField
                                        required
                                        type="text"
                                        name="address"
                                        label="Home Address"
                                        id="personalInfo_input_address"
                                        onChange={fieldChangeHandler}
                                        value={customer.address?.address}
                                        error={touched.address && errors.address}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CustomField
                                        required
                                        type="text"
                                        name="city"
                                        label="City"
                                        id="personalInfo_input_city"
                                        onChange={fieldChangeHandler}
                                        value={customer.address?.city}
                                        error={touched.city && errors.city}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CustomField
                                        required
                                        type="text"
                                        name="state"
                                        label="State"
                                        id="personalInfo_input_state"
                                        onChange={fieldChangeHandler}
                                        value={customer.address?.state}
                                        error={touched.state && errors.state}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {marketplaceIsCanadian ? (
                                        <CustomMaskField
                                            required
                                            type="text"
                                            mask="a9a9a9"
                                            name="zipCode"
                                            label="Zip Code"
                                            id="personalInfo_input_zipcode"
                                            onChange={fieldChangeHandler}
                                            value={customer.address?.zipCode ?? ''}
                                            error={touched.zipCode && errors.zipCode}
                                        />
                                    ) : (
                                        <CustomMaskField
                                            required
                                            type="text"
                                            mask="99999"
                                            name="zipCode"
                                            label="Zip Code"
                                            id="personalInfo_input_zipcode"
                                            onChange={fieldChangeHandler}
                                            value={customer.address?.zipCode ?? ''}
                                            error={touched.zipCode && errors.zipCode}
                                        />
                                    )}
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <EmergencyContactForm
                                values={{
                                    first_name: props.booking.customer.emergencyContact?.first_name,
                                    last_name: props.booking.customer.emergencyContact?.last_name,
                                    phone: props.booking.customer.emergencyContact?.phone,
                                    relationship:
                                        props.booking.customer.emergencyContact?.relationship
                                }}
                                showForm={isEmergencyContactRequired}
                                onChange={emergencyContactChangeHandler}
                                onToggle={() => setIsEmergencyContactRequired(prev => !prev)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};

export default BookingCustomer;
