import React from 'react';
import './UnderlinedButton.styles.scss';

export interface UnderlinedButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    label: string;
}

const UnderlinedButton: React.FC<UnderlinedButtonProps> = ({ className, ...props }) => {
    return (
        <button {...props} className={`UnderlinedButton ${className}`}>
            {props.label}
        </button>
    );
};

export default UnderlinedButton;
