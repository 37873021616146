import store, { history } from 'store';
import Routes from 'routes/Routes';
import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { useMarketplace } from 'hooks';
import { Provider, useDispatch } from 'react-redux';
import { fetchMasterDataThunk } from 'actions/masterData/MasterDataActions';
import { Container } from '@mui/material';
import { fetchServicesThunk } from 'actions/services/ServicesActions';
import { fetchStaffsThunk } from 'actions/staffs/StaffsActions';

const App: React.FC = () => {
    const dispatch = useDispatch();
    const marketplace = useMarketplace();

    useEffect(() => {
        const fetchData = async (id: number) => {
            await dispatch(fetchMasterDataThunk(id));
            await dispatch(fetchServicesThunk(id));
            await dispatch(fetchStaffsThunk(id));
        };

        if (marketplace.id) {
            fetchData(marketplace.id);
        }
    }, [marketplace]);

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Container maxWidth={false} disableGutters={true} className="app-container">
                    <Routes />
                </Container>
            </ConnectedRouter>
        </Provider>
    );
};

export default App;
