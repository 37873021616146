import { Reducer } from 'redux';
import { StaffsState, StaffStatus } from './StaffsState';
import {
  StaffsAction,
  STAFFS_ERROR_ACTION_TYPE,
  STAFFS_FETCH_ACTION_TYPE,
  STAFFS_FETCH_SUCCESS_ACTION_TYPE,
  STAFFS_RESET_ACTION_TYPE,
} from 'actions/staffs/StaffsActionsTypes';

const initialState: StaffsState = {
  staffs: [],
  status: StaffStatus.Initial,
  loading: true,
};

export const staffsReducer: Reducer<StaffsState, StaffsAction> = (state = initialState, action) => {
  switch (action.type) {
    case STAFFS_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case STAFFS_FETCH_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.Fetching,
      };
    case STAFFS_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.FetchSuccess,
        loading: false,
        staffs: action.payload.staffs,
      };
    case STAFFS_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.Error,
        loading: true,
      };
    default:
      return state;
  }
};

export default staffsReducer;
