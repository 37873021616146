import { BookingStep } from 'components/Booking/Booking';
import { NewBooking } from 'model/Booking';

export interface BookingState {
  booking: NewBooking | undefined;
  siblings: Array<string>;
  status: BookingStatus;
  loading: boolean;
  errorStep?: BookingStep;
}

export enum BookingStatus {
  Initial,

  CreateStart,
  CreateSuccess,

  CreateDepositStart,
  CreateDepositSuccess,

  UpdateStart,
  UpdateSuccess,

  FetchingSiblings,
  FetchSiblingsSuccess,

  Error,
}
