import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';
import { newPetThunk } from 'actions/pet/PetActions';
import { PetForm } from 'components/Pet/PetForm';
import { PetSelect } from 'components/Pet/PetSelect/PetSelect';
import { Hour, Pet, PetWithService, Service } from 'model';
import { EditBooking } from 'model/Booking';
import { PetState, PetStatus } from 'reducers/pet/PetState';
import { ServicesState } from 'reducers/services/ServicesState';
import store, { RootState } from 'store';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
interface SelectPetFormProps {
    pets: Array<Pet>;
    booking: EditBooking;
    //
    onEdit: () => void;
    onCreate: () => void;
    onUpdated: (pets: Array<PetWithService>) => void;
    onChange: (pets: Array<PetWithService>) => void;
}

export const SelectPetForm: React.FunctionComponent<SelectPetFormProps> = props => {
    const dispatch = useDispatch();

    const pets = useSelector<RootState, PetState | undefined>(state => state.pets);
    const services = useSelector<RootState, ServicesState | undefined>(state => state.services);

    const [newPet, setNewPet] = useState<boolean>(false);
    const [petList, setPetList] = useState<Array<Pet>>(props.pets);
    const [petsWithService, setPetsWithService] = useState<
        { pet: Pet; service?: Service; hour?: Hour }[]
    >(props.booking.petsToBooking.length ? [props.booking.petsToBooking[0]] : []);

    useEffect(() => {
        if (pets?.status === PetStatus.SaveSuccess) {
            let newPets: Array<Pet> = [];
            if (petList) {
                newPets = petList.filter(item => item.id !== pets!.pet!.id);
            }
            newPets.push(pets!.pet!);
            setPetList(newPets);
        }

        if (pets?.status === PetStatus.FetchSuccess) {
            setPetList(pets.pets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pets]);

    const onSubmitPet = (pet: Pet) => {
        if (pet.id) {
            dispatch(newPetThunk(pet));
        }

        const newPets: Array<PetWithService> = [...petsWithService].map(item => {
            if (item.pet.uuid !== pet.uuid) {
                return item;
            }

            return {
                ...item,
                pet: pet,
                service: undefined
            };
        });

        setPetsWithService(newPets);
        props.onChange(newPets);
        props.onUpdated(newPets);
    };

    const onPetChange = (value: { pet: Pet; service?: Service }) => {
        if (!value.pet.uuid && !value.pet.id) return;

        const newPets: Array<PetWithService> = [value];

        setPetsWithService(newPets);
        setNewPet(false);
        props.onChange(newPets);
    };

    const isPetSelected = (petId: string | number | undefined) => {
        if (!petId) return false;

        return (
            petsWithService.filter(item => item.pet.id === petId || item.pet.uuid === petId)
                .length > 0
        );
    };

    const findServiceByPetId = (petId?: number) => {
        const index = petsWithService.findIndex(item => {
            if (item.pet.id === petId) return true;
            return false;
        });

        return petsWithService[index] ? petsWithService[index].service : undefined;
    };

    const onPetFormSubmit = (pet: Pet) => {
        amplitude.track(AMPLITUDE.CTA_SAVE_PET_INFO, {
            marketplace: store.getState().marketplace.marketplace.name
        });
        setNewPet(false);
        setPetList([...petList, pet]);
        onPetChange({ pet, service: undefined });
        amplitude.track(AMPLITUDE.LOAD_ADD_SERVICE, {
            marketplace: store.getState().marketplace.marketplace.name
        });
    };

    const unCheckPet = (petId: string | undefined) => {
        const newPets = [...petsWithService];

        newPets.splice(
            newPets.findIndex(item => item.pet.uuid === petId),
            1
        );
        setPetsWithService(newPets);
        props.onChange(newPets);
    };

    const petFormRemove = () => {
        setNewPet(false);
    };

    const addNewPet = () => {
        props.onCreate();
        // setNewPet(true);
        // setPetId(undefined)
    };

    //

    return (
        <Box>
            {!newPet && (
                <Box sx={{ mb: 3 }}>
                    {services && (
                        <>
                            {petList
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((pet, i) => (
                                    <PetSelect
                                        key={i}
                                        pet={pet}
                                        petIndex={i}
                                        services={services.services}
                                        booking={props.booking}
                                        //
                                        onEdit={props.onEdit}
                                        onSubmitPet={onSubmitPet}
                                        selected={isPetSelected(pet.id || pet.uuid)}
                                        onChangeFn={onPetChange}
                                        onUncheck={unCheckPet}
                                        service={findServiceByPetId(pet.id)}
                                    />
                                ))}
                        </>
                    )}
                </Box>
            )}

            {(newPet || (petList && petList.length === 0)) && (
                <>
                    <PetForm
                        showRemove={newPet}
                        loading={pets?.loading}
                        //
                        onRemove={petFormRemove}
                        onSubmit={onPetFormSubmit}
                    />
                </>
            )}

            {!newPet && petList && petList!.length > 0 && (
                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                'fontSize': 15,
                                'fontWeight': 500,
                                'cursor': 'pointer',
                                'display': 'inline-block',
                                'span': { textDecoration: 'underline' },
                                'svg': { marginRight: 0.7 },
                                '&:hover span': { textDecoration: 'none' },
                                'paddingBottom': '20px'
                            }}
                            id="add-new-pet"
                            onClick={addNewPet}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            <span>Add new pet</span>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
