import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup as MuiRadioGroup
} from '@mui/material';
import React from 'react';
import './RadioGroup.scss';
import { Option } from 'model';

export interface RadioGroupProps<T> {
    name: string;
    label: string;
    defaultValue?: T;
    options: Array<Option<T>>;
    onChange: (value: string) => void;
}

const RadioGroup = <T,>(props: RadioGroupProps<T>) => {
    return (
        <FormControl component="fieldset" className="RadioGroup">
            <FormLabel component="legend" className="RadioGroup-label">
                {props.label}
            </FormLabel>

            <MuiRadioGroup
                name="customized-radios"
                className="RadioGroup-wrapper"
                defaultValue={props.defaultValue}
                onChange={(e, value) => props.onChange(value)}
            >
                {props.options.map((opt, i) => (
                    <FormControlLabel
                        key={i}
                        value={opt.id}
                        control={
                            <Radio
                                disableRipple
                                className="RadioGroup-radio"
                                id={`${props.name}-${i}`}
                            />
                        }
                        label={opt.description}
                        className="RadioGroup-radio-label"
                    />
                ))}
            </MuiRadioGroup>
        </FormControl>
    );
};

export default RadioGroup;
