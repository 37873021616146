import { Marketplace } from "model";

export interface MarketplaceState {
  status: MarketplaceStatus;
  loading: boolean;
  marketplace: Marketplace;
}


export enum MarketplaceStatus {
  Initial,
  Fetching,
  FetchSuccess,
  Set,
  Saving,
  SaveSuccess,
  Cleared,
  Error,
}
