import { ForgotPasswordState } from "./ForgotPasswordState";
import {
  FORGOT_PASSWORD_RESET_ACTION_TYPE,
  FORGOT_PASSWORD_START_ACTION_TYPE,
  FORGOT_PASSWORD_END_ACTION_TYPE,
  FORGOT_PASSWORD_FAIL_ACTION_TYPE,
  FORGOT_PASSWORD_SENT_ACTION_TYPE,
  ForgotPasswordAction,
} from "actions/forgotPassword/ForgotPasswordActionsTypes";
import { Reducer } from "redux";

const initialState: ForgotPasswordState = {
  failed: false,
  sent: false,
  loading: false
};

export const forgotPasswordReducer: Reducer<
  ForgotPasswordState,
  ForgotPasswordAction
> = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_RESET_ACTION_TYPE:
      return {
        ...initialState
      };
    case FORGOT_PASSWORD_START_ACTION_TYPE:
      return {
        ...state,
        loading: true,
        email: action.payload.email,
        failed: false,
      };
    case FORGOT_PASSWORD_END_ACTION_TYPE:
      return {
        ...state,
        loading: false,
      };
    case FORGOT_PASSWORD_SENT_ACTION_TYPE:
      return {
        ...state,
        sent: action.payload,
      };
    case FORGOT_PASSWORD_FAIL_ACTION_TYPE:
      return {
        ...state,
        failed: action.payload,
      };

    default:
      return state;
  }
};

export default forgotPasswordReducer;
