import { Box } from "@mui/material";
import React from "react";
import "./BlackHeading.scss";

interface BlackHeadingProps {
  title: string;
  subtitle?: string;
  divider?: boolean;
  boxProps?: object;
}

const BlackHeading: React.FunctionComponent<BlackHeadingProps> = (props) => {
  const { boxProps } = props;

  return (
    <Box className="BlackHeading" {...boxProps}>
      <h2>{props.title}</h2>
      {props.subtitle && (
        <Box className="BlackHeading-subtitle">
          <span dangerouslySetInnerHTML={{ __html: props.subtitle }}></span>
        </Box>
      )}
      {props.divider && <hr />}
    </Box>
  );
};

BlackHeading.defaultProps = {
  divider: true,
};

export default BlackHeading;
