import React from 'react';
import SignPad from 'components/SignPad/SignPad';
import Button from 'components/UI/Buttons/Button/Button';
import Modal, { ModalProps } from 'components/UI/Modal/Modal';

import './AgreementModal.styles.scss';
import ReactSignatureCanvas from 'react-signature-canvas';
import { Agreement, AgreementSignature } from 'model';
import { useDispatch, useSelector } from 'react-redux';
import { getAgreementSignatureThunk } from 'actions/agreements/AgreementsActions';
import { RootState } from 'store';
import { AgreementsState, AgreementsStatus } from 'reducers/agreements/AgreementsState';
import Spinner from 'components/UI/Spinner';
import { convertToFile } from 'utils/filesUtils';

export type AgreementModalProps = Omit<ModalProps, 'footer' | 'open' | 'title' | 'children'> & {
    agreement: Agreement;
    onSign: (signature: AgreementSignature) => void;
};

const AgreementModal: React.FC<AgreementModalProps> = props => {
    const dispatch = useDispatch();

    const agreementsState = useSelector<RootState, AgreementsState>(state => state.agreements);

    const [isInvalid, setIsInvalid] = React.useState<boolean>(true);
    const [signRef, setSignRef] = React.useState<ReactSignatureCanvas | null>();
    const [signature, setSignature] = React.useState<AgreementSignature | null>();

    const isLoading = agreementsState.loading;

    const clearHandler = () => {
        if (signRef) {
            signRef.clear();
            signPadChangeHandler();
        }
    };

    const signPadChangeHandler = () => {
        setIsInvalid(signRef?.isEmpty() as boolean);
    };

    const confirmHandler = async () => {
        if (!agreementsState.signature) {
            return;
        }

        const file = await convertToFile(
            signRef?.getTrimmedCanvas().toDataURL('image/png') as string,
            `signature_${signature?.id}.png`
        );

        props.onSign({
            ...agreementsState.signature,
            customerSignature: file
        });
    };

    React.useEffect(() => {
        if (agreementsState.status === AgreementsStatus.GetSignatureSuccess) {
            setSignature(agreementsState.signature);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreementsState.status]);

    React.useEffect(() => {
        dispatch(getAgreementSignatureThunk(props.agreement.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal
                open
                title={props.agreement.title}
                footer={
                    <div className="AgreementModal-footer">
                        <div className="AgreementModal-clearSign">
                            <p>Sign Here</p>

                            <button onClick={clearHandler}>Clear</button>
                        </div>

                        <SignPad
                            id="agreement_sign_pad"
                            onRef={ref => setSignRef(ref)}
                            onChange={signPadChangeHandler}
                        />

                        <Button
                            variant="secondary"
                            disabled={isInvalid}
                            id="agreement_confirm"
                            onClick={confirmHandler}
                        >
                            Confirm
                        </Button>
                    </div>
                }
                onClose={props.onClose}
            >
                {isLoading ? (
                    <Spinner />
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: props.agreement.content }}></div>
                )}
            </Modal>
        </>
    );
};

export default AgreementModal;
