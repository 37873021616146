import React from 'react';
import { Option } from 'model';
import { AutocompleteRenderInputParams, Box } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import '../CustomAutocomplete/CustomAutocomplete.scss';
import { isObject, isString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import './SearchAutocomplete.scss';

export type SearchAutocompleteOption = Option<string> | Option<number>;

interface SearchAutocompleteProps {
    id?: string;
    label?: string;
    error?: boolean;
    required?: boolean;
    hideIcon?: boolean;
    children?: React.ReactNode;
    options: readonly SearchAutocompleteOption[];
    value: SearchAutocompleteOption | null | undefined;
    onChange?: (value: SearchAutocompleteOption | null) => void;
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

export const SearchAutocomplete: React.FunctionComponent<SearchAutocompleteProps> = props => {
    const inOptionSelected = (
        option: SearchAutocompleteOption,
        value: SearchAutocompleteOption
    ) => {
        // if (isObject(option) && isObject(value)) {
        // 	//SU-54 issue
        // 	//return option.id === value.id;
        // 	return value ? props.options.some((o) => o === value.id) : false;
        // }

        return option === value;
    };

    const onChange = (
        e: React.ChangeEvent<EventTarget>,
        value: SearchAutocompleteOption | null
    ) => {
        props.onChange && props.onChange(value);
    };

    return (
        <Box className="SearchAutocomplete" data-hide-icon={props.hideIcon}>
            <FontAwesomeIcon icon={faSearch} />

            <Autocomplete
                id={props.id}
                className="CustomAutocomplete"
                onChange={onChange}
                options={props.options}
                value={props.value}
                isOptionEqualToValue={inOptionSelected}
                ListboxProps={{
                    style: {
                        maxHeight: '150px'
                    }
                }}
                getOptionLabel={(option: string | Option<number> | Option<string>) =>
                    isString(option) ? option : option.name ?? ''
                }
                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option) => {
                    return (
                        <Box
                            id={`option_${option}`}
                            component="li"
                            {...props}
                            key={`${isObject(option) ? option.id + option.name : option}`}
                        >
                            {option.name}
                        </Box>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        error={props.error}
                        label={props.label}
                        {...(props.required && { required: true })}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'
                        }}
                    />
                )}
            />
        </Box>
    );
};
