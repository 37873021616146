import { RootAction } from 'actions/ActionTypes';
import {
    AGREEMENTS_ERROR,
    AGREEMENTS_FETCH_START,
    AGREEMENTS_FETCH_SUCCESS,
    AGREEMENTS_GET_SIGNATURE_START,
    AGREEMENTS_GET_SIGNATURE_SUCCESS,
    AGREEMENTS_RESET,
    AGREEMENTS_SIGN_START,
    AGREEMENTS_SIGN_SUCCESS,
    AgreementsAction
} from 'actions/agreements/AgreementsActionsTypes';
import {
    convertAgreements,
    convertToAgreementSignature,
    convertToAgreementSignatureDto
} from 'actions/agreements/AgreementsDataConverter';
import { AgreementDto, AgreementSignatureDto } from 'actions/agreements/AgreementsDtos';
import ApiClient, { createTokenConfig } from 'api';
import ApiPublicClient from 'api/ApiPublicClient';
import { AxiosResponse } from 'axios';
import { Agreement, AgreementSignature } from 'model';
import moment from 'moment-timezone';
import { serialize } from 'object-to-formdata';
import { AgreementsState } from 'reducers/agreements/AgreementsState';
import { ThunkAction } from 'redux-thunk';
import store from 'store';

const agreementsUrl = 'agreements';
const signaturesUrl = 'signatures';
const findOrCreateSignatureUrl = 'signature/find_or_create';

export const fetchAgreementsThunk = (): ThunkAction<void, AgreementsState, null, RootAction> => {
    return async dispatch => {
        dispatch(fetchStart());

        const customerId = store.getState().login.auth.customer?.id;
        const marketplaceId = store.getState().marketplace.marketplace.id;
        const url = agreementsUrl + `?marketplace_id=${marketplaceId}&customer_id=${customerId}`;

        try {
            const response: AxiosResponse<Array<AgreementDto>> = await ApiPublicClient.get(
                url,
                createTokenConfig()
            );

            dispatch(fetchSuccess(convertAgreements(response.data)));
        } catch (apiError: unknown) {
            console.error(apiError);

            dispatch(error());
        }
    };
};

const fetchStart = (): AgreementsAction => {
    return {
        type: AGREEMENTS_FETCH_START
    };
};

const fetchSuccess = (agreements: Array<Agreement>): AgreementsAction => {
    return {
        type: AGREEMENTS_FETCH_SUCCESS,
        payload: {
            agreements
        }
    };
};

export const getAgreementSignatureThunk = (
    agreementId: number
): ThunkAction<void, AgreementsState, null, RootAction> => {
    return async dispatch => {
        dispatch(getSignatureStart());

        const customerId = store.getState().login.auth.customer?.id;
        const marketplaceId = store.getState().marketplace.marketplace.id;
        const url = findOrCreateSignatureUrl + `?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<AgreementSignatureDto> = await ApiClient.put(
                url,
                {
                    customer_id: customerId,
                    agreement_id: agreementId
                },
                createTokenConfig()
            );

            dispatch(getSignatureSuccess(convertToAgreementSignature(response.data)));
        } catch (apiError: unknown) {
            console.error(apiError);

            dispatch(error());
        }
    };
};

const getSignatureStart = (): AgreementsAction => {
    return {
        type: AGREEMENTS_GET_SIGNATURE_START
    };
};

const getSignatureSuccess = (signature: AgreementSignature): AgreementsAction => {
    return {
        type: AGREEMENTS_GET_SIGNATURE_SUCCESS,
        payload: {
            signature
        }
    };
};

export const signAgreementThunk = (
    signature: AgreementSignature
): ThunkAction<void, AgreementsState, null, RootAction> => {
    return async dispatch => {
        dispatch(signAgreementStart());

        const url = signaturesUrl + `/${signature.id}`;

        const formDataOptions = {
            indices: false,
            nullsAsUndefineds: false,
            booleansAsIntegers: false,
            allowEmptyArrays: false
        };

        const formData = serialize(
            {
                signature: convertToAgreementSignatureDto({
                    ...signature,
                    sentAt: moment(),
                    signedAt: moment()
                })
            },
            formDataOptions
        );

        try {
            const response: AxiosResponse<AgreementSignatureDto> = await ApiClient.patch(
                url,
                formData,
                createTokenConfig()
            );

            dispatch(signAgreementSuccess(convertToAgreementSignature(response.data)));
        } catch (apiError: unknown) {
            console.error(apiError);

            dispatch(error());
        }
    };
};

const signAgreementStart = (): AgreementsAction => {
    return {
        type: AGREEMENTS_SIGN_START
    };
};

const signAgreementSuccess = (signature: AgreementSignature): AgreementsAction => {
    return {
        type: AGREEMENTS_SIGN_SUCCESS,
        payload: {
            signature
        }
    };
};

export const reset = (): AgreementsAction => {
    return {
        type: AGREEMENTS_RESET
    };
};

const error = (): AgreementsAction => {
    return {
        type: AGREEMENTS_ERROR
    };
};
