import React, { useMemo } from 'react'

import { Box, Grid } from '@mui/material'

import './HoursList.scss'
import { Hour } from 'model'
import { SelectButton } from 'components'

interface HoursListProps {
    petName: string,
    selected?: Hour,
    hours: Hour[],
    index: number
    //
    onChange(hour: Hour, key: number): void,
}

const hourToOption = (hour: Hour): { id: string, title: string } => {
    return { id: hour.hour, title: hour.hour }
};

const HoursList: React.FunctionComponent<HoursListProps> = ({ index, petName, hours, selected, onChange }) => {
    const onHourChange = (hour: Hour["hour"]) => {
        const selected = hours.find((item, i) => item.hour === hour)

        if (!selected) return

        onChange(selected, index)
    };

    const List = ({ hours: hourList, title }: { hours: Hour[], title: string }) => (
        <>
            <p className="HoursList-label">{title}</p>

            <Grid container columnSpacing={2}>
                {
                    hourList.length > 0 ?
                        hourList.map((hour, i) =>
                            <Grid key={i} item xs={4} md={3}>
                                <SelectButton onChange={onHourChange}
                                    {...selected && { selected: selected.hour }} option={hourToOption(hour)} />
                            </Grid>)
                        :
                        <Grid item xs={12}>
                            <p id="hourList_label_not_available" className="HoursList-not-available">Not available.</p>
                        </Grid>
                }
            </Grid>
        </>
    );

    const morningHours = useMemo(() => {
        return hours.filter(hour => hour.cycle === 'am') ?? [];
    }, [hours]);

    const afternoonHours = useMemo(() => {
        return hours.filter(hour => hour.cycle === 'pm') ?? [];
    }, [hours]);

    return (
        <Box className="HoursList">
            {/* <p className="HoursList-label">{petName}</p> */}
            <Grid container columnSpacing={2}>

                {hours.length > 0 ? <>
                    <Grid item xs={12}>
                        <List title="Morning" hours={morningHours} />
                    </Grid>

                    <Grid item xs={12}>
                        <List title="Afternoon" hours={afternoonHours} />
                    </Grid>
                </> :
                    <Grid item xs={12} sx={{ mt: 4 }}>
                        <p className="HoursList-not-available">Not available.</p>
                    </Grid>
                }

            </Grid>
        </Box>
    )
}

export default HoursList
