import { DateTime } from 'luxon';
import { Moment } from 'moment-timezone';
import { EmergencyContact, NewCustomer } from './Customer';
import { Pet } from './Pet';
import { AgreementSignature } from 'model/Agreement';

export interface EditBooking {
    id?: number;
    hour?: Hour;
    date?: Day;
    notes?: string;
    invoiceId?: number;
    staffId?: number;
    customer: {
        first_name: string;
        last_name: string;
        email: string;
        phone: string;
        blocked: boolean;

        address?: {
            address?: string;
            city?: string;
            state?: string;
            zipCode?: string;
        };

        emergencyContact?: EmergencyContact;
    };
    pets: Array<Pet>;
    petsToBooking: {
        pet: Pet;
        hour?: Hour;
        service?: Service;
        staffId?: number;
    }[];
    agreementsSignatures: Array<AgreementSignature>;
}

export interface NewBooking {
    id?: number;
    uuid: string;
    customerId: number;
    marketplaceId: number;
    customer?: NewCustomer;
    staffId?: number;
    appointmentsAttributes: Array<NewBookingAppointment>;
}

export interface NewBookingAppointment {
    id?: number;
    addressId: number;
    bookedAt: Moment;
    customerId: number;
    petId: number;
    serviceId: number;
    staffId: number;
    uuid: string;
    createdBy: number;
    channel?: string;
    notes?: string;
    status?: number;
}

export interface PetWithService {
    pet: Pet;
    service?: Service;
    hour?: Hour;
}

export interface Option<T> {
    id: T;
    name: string;
    description?: string;
}

export interface Day {
    ISODate: string;
    dayLabel: string;
    dayNumber: string;
    object: DateTime;
}

export interface Service {
    id: number;
    name: string;
    description: string;
    fixedPrice: Array<FixedPriceOption>;
    variablePrice?: VariablePrice;
    price?: string;
    petList: Array<string>;
    variableDuration: Array<VariableDuration>;
    durationHours?: number;
    durationMinutes?: number;
    staffIds: Array<number>;
}

export interface Duration {
    hours: number;
    minutes: number;
}

export interface VariableDuration {
    petSize: Option<string>;
    duration: Duration;
}

export interface VariablePrice {
    variables: VariablePriceVariables;
    prices: Array<VariablePriceOption>;
}

export interface FixedPriceOption {
    petType: PetType;
    price?: number;
}

export interface PetType {
    id: string;
    singular: string;
    plural: string;
}

export interface VariablePriceOption {
    petType?: PetType;
    petSize?: Option<string>;
    hairLength?: Option<string>;
    price?: number;
}

export interface VariablePriceVariables {
    petTypeVariable: boolean;
    petSizeVariable: boolean;
    hairTypeVariable: boolean;
    hairLengthVariable: boolean;
    exoticTypeVariable: boolean;
}

export interface Hour {
    hour: string;
    cycle: string;
    staff: Array<number>;
}

export enum BookingStatusEnum {
    PENDING = 11,
    FINISH = 1
}
