import { AgreementDto, AgreementSignatureDto } from 'actions/agreements/AgreementsDtos';
import { Agreement, AgreementSignature } from 'model';
import moment from 'moment-timezone';

export const convertAgreements = (agreements: Array<AgreementDto>): Array<Agreement> => {
    return agreements.map(convertAgreement);
};

export const convertAgreement = (dto: AgreementDto): Agreement => ({
    id: dto.id,
    uuid: dto.uuid,
    title: dto.title,
    active: dto.active,
    content: dto.content,
    email: dto.email,
    mandatory: dto.mandatory,
    marketplaceId: dto.marketplace_id,
    sendTo: dto.send_to,
    sms: dto.sms,
    createdAt: moment(dto.created_at),
    updatedAt: moment(dto.updated_at)
});

export const convertToAgreementDto = (agreement: Agreement): AgreementDto => ({
    id: agreement.id,
    uuid: agreement.uuid,
    title: agreement.title,
    active: agreement.active,
    content: agreement.content,
    email: agreement.email,
    mandatory: agreement.mandatory,
    marketplace_id: agreement.marketplaceId,
    send_to: agreement.sendTo,
    sms: agreement.sms,
    created_at: agreement.createdAt.toISOString(),
    updated_at: agreement.updatedAt.toISOString()
});

export const convertToAgreementSignature = (dto: AgreementSignatureDto): AgreementSignature => ({
    id: dto.id,
    uuid: dto.uuid,
    agreementId: dto.agreement_id,
    customerId: dto.customer_id,
    marketplaceId: dto.marketplace_id,
    sentAt: dto.sent_at ? moment(dto.sent_at) : undefined,
    signedAt: dto.signed_at ? moment(dto.signed_at) : undefined,
    updatedAt: moment(dto.updated_at),
    createdAt: moment(dto.created_at)
});

export const convertToAgreementSignatureDto = (
    signature: AgreementSignature
): AgreementSignatureDto => ({
    id: signature.id,
    uuid: signature.uuid,
    agreement_id: signature.agreementId,
    customer_id: signature.customerId,
    marketplace_id: signature.marketplaceId,
    customer_signature: signature.customerSignature,
    sent_at: signature.sentAt ? signature.sentAt.toISOString() : null,
    signed_at: signature.signedAt ? signature.signedAt.toISOString() : null,
    updated_at: signature.updatedAt.toISOString(),
    created_at: signature.createdAt.toISOString()
});
