import { RootAction } from 'actions/ActionTypes';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { notificationsMiddleware } from 'middleware/NotificationsMiddleware';
import alertsReducer from 'reducers/alerts/AlertsReducer';
import { AlertsState } from 'reducers/alerts/AlertsState';
import availabilityReducer from 'reducers/availability/AvailabilityReducer';
import { AvailabilityState } from 'reducers/availability/AvailabilityState';
import bookingReducer from 'reducers/booking/BookingReducer';
import headerReducer from 'reducers/header/HeaderReducer';
import { HeaderState } from 'reducers/header/HeaderState';
import loginReducer from 'reducers/login/LoginReducer';
import { LoginState } from 'reducers/login/LoginState';
import marketplaceReducer from 'reducers/marketplace/MarketplaceReducer';
import { MarketplaceState } from 'reducers/marketplace/MarketplaceState';
import masterDataReducer from 'reducers/masterData/MasterDataReducer';
import { MasterDataState } from 'reducers/masterData/MasterDataState';
import petReducer from 'reducers/pet/PetReducer';
import { PetState } from 'reducers/pet/PetState';
import servicesReducer from 'reducers/services/ServicesReducer';
import { ServicesState } from 'reducers/services/ServicesState';
import signUpReducer from 'reducers/signUp/SignUpReducer';
import { SignUpState } from 'reducers/signUp/SignUpState';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { ForgotPasswordState } from 'reducers/forgotPassword/ForgotPasswordState';
import forgotPasswordReducer from 'reducers/forgotPassword/ForgotPasswordReducer';

import { BookingState } from 'reducers/booking/BookingState';
import customerReducer from 'reducers/customer/CustomerReducer';
import { CustomerState } from 'reducers/customer/CustomerState';
import { InvoicesState, invoicesReducer } from '@spike/invoices-action';
import { PaymentsState, paymentsReducer } from '@spike/payments-action';
import staffsReducer from 'reducers/staffs/StaffsReducer';
import { StaffsState } from 'reducers/staffs/StaffsState';
import { AgreementsState } from 'reducers/agreements/AgreementsState';
import agreementsReducer from 'reducers/agreements/AgrementsReducer';

export const history = createBrowserHistory();

export interface RootState {
    readonly customer: CustomerState;
    readonly availability: AvailabilityState;
    readonly booking: BookingState;
    readonly marketplace: MarketplaceState;
    readonly masterdata: MasterDataState;
    readonly header: HeaderState;
    readonly signUp: SignUpState;
    readonly login: LoginState;
    readonly alert: AlertsState;
    readonly pets: PetState;
    readonly services: ServicesState;
    readonly staffs: StaffsState;
    readonly forgotPassword: ForgotPasswordState;
    readonly invoices: InvoicesState;
    readonly payments: PaymentsState;
    readonly agreements: AgreementsState;
}

const createRootReducer = (history: any) =>
    combineReducers({
        customer: customerReducer,
        availability: availabilityReducer,
        booking: bookingReducer,
        router: connectRouter(history),
        marketplace: marketplaceReducer,
        masterdata: masterDataReducer,
        header: headerReducer,
        signUp: signUpReducer,
        login: loginReducer,
        alert: alertsReducer,
        pets: petReducer,
        staffs: staffsReducer,
        services: servicesReducer,
        forgotPassword: forgotPasswordReducer,
        invoices: invoicesReducer,
        payments: paymentsReducer,
        agreements: agreementsReducer
    });

const middleware = applyMiddleware(thunk, routerMiddleware(history), notificationsMiddleware);

const composeEnhancers = composeWithDevTools({});

export const store: Store<RootState, RootAction> = createStore(
    createRootReducer(history),
    composeEnhancers(middleware)
);

export const dispatch = (action: any) => store.dispatch(action);

export default store;
