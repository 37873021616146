import { NewBooking, NewBookingAppointment } from "model/Booking";
import { NewBookingDto, NewBookingAppointmentDto } from "./BookingDtos";

export const convertBooking = (bookingDataDto: NewBookingDto): NewBooking => {
  return {
    id: bookingDataDto.id ? bookingDataDto.id : 0,
    customerId: bookingDataDto.customer_id,
    uuid: bookingDataDto.uuid,
    marketplaceId: bookingDataDto.marketplace_id,
    staffId: bookingDataDto.staff_id,
    appointmentsAttributes: bookingDataDto.appointments?.map(appointment => convertAppointmentDto(appointment)) ?? [],
  };
};

export const convertBookingDto = (newBooking: NewBooking): NewBookingDto => {
  return {
    customer_id: newBooking.customerId,
    uuid: newBooking.uuid,
    marketplace_id: newBooking.marketplaceId,
    staff_id: newBooking.staffId,
    appointments_attributes: newBooking.appointmentsAttributes.map((appointment) => convertAppointment(appointment)),
  };
};

export const convertAppointmentDto = (appointmentDto: NewBookingAppointmentDto): NewBookingAppointment => {
  return {
    id: appointmentDto.id,
    addressId: appointmentDto.address_id,
    bookedAt: appointmentDto.booked_at,
    customerId: appointmentDto.customer_id,
    petId: appointmentDto.pet_id,
    serviceId: appointmentDto.service_id,
    staffId: appointmentDto.staff_id,
    uuid: appointmentDto.uuid,
    createdBy: appointmentDto.created_by,
    notes: appointmentDto.notes,
    status: appointmentDto.status,
  }
};

export const convertAppointment = (appointment: NewBookingAppointment): NewBookingAppointmentDto => {
  return {
    id: appointment.id!,
    channel: 'online-booking',
    address_id: appointment.addressId,
    booked_at: appointment.bookedAt,
    customer_id: appointment.customerId,
    pet_id: appointment.petId,
    service_id: appointment.serviceId,
    staff_id: appointment.staffId,
    uuid: appointment.uuid,
    created_by: appointment.createdBy,
    notes: appointment.notes,
    status: appointment.status,
  }
};
