import React from 'react';
import './StaffChip.styles.scss';
import { Radio } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { Staff } from 'model';

export interface StaffChipProps {
    staff: Staff;
    index: number;
    checked: boolean;
    onChange: (staffId?: number) => void;
}

const StaffChip: React.FC<StaffChipProps> = props => {
    const changeHandler = () => {
        props.onChange(props.staff.id);
    };

    return (
        <div className="StaffChip" data-selected={props.checked} onClick={changeHandler}>
            <Radio
                disableRipple
                checked={props.checked}
                className="StaffChip-radio"
                id={`staff-${props.index}`}
                checkedIcon={<FontAwesomeIcon icon={faCircleCheck} onChange={changeHandler} />}
            />
            <img src={props.staff.avatar} alt="" className="StaffChip-avatar" />
            <p className="StaffChip-name">{props.staff.name}</p>
        </div>
    );
};

export default StaffChip;
