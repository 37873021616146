import store from 'store';
import { faDog, faCat, faTurtle } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PET_TYPE } from 'constants/index';
import moment, { Moment } from 'moment-timezone';

const { DOG, CAT, EXOTIC } = PET_TYPE;

// const timeZone = store.getState().marketplace.marketplace.timeZone || "America/New_York";

export const getBreedName = (petType: string, breedId: string | number): string => {
  const masterData = store.getState().masterdata;
  const breedFound = masterData.data.breeds[petType as keyof typeof masterData.data.breeds].find(
    (breed: { id: any }) => breed.id === breedId,
  );
  if (breedFound) return breedFound.name;
  return '';
};

export const getPetIcon = (petType: string): IconProp => {
  if (petType === DOG) return faDog;
  if (petType === CAT) return faCat;
  if (petType === EXOTIC) return faTurtle;
  return faTurtle;
};

export const formatPhoneNumber = (text: string) => {
  return text && text.length === 10
    ? `(${text.substr(0, 3)}) ${text.substr(3, 3)}-${text.substr(6, text.length)}`
    : text;
};

export const formatDateTimezone = (dateHour: string, timezone: string): Moment => {
  return moment(dateHour, 'YYYY-MM-DD HH:mm A').tz(timezone, true);
};

export const validatePhone = (phone: string): boolean => {
  // eslint-disable-next-line
  return !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone);
};

export const validateEmail = (email: string): boolean => {
  return !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email);
};

export const parseJSON = (json: string, defaultValue: any = null) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return defaultValue;
  }
};
