import React, { useEffect, useState } from 'react';
import { RootState } from 'store';
import { DropIn } from 'model/Payments';
import { Box, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { EditBooking, Marketplace } from 'model';
import { BookingLayout } from 'components/UI/Layout';
import useNonInitialEffect from '@versiondos/hooks';
import AdyenPayment, { CompletedInfo } from './BookingResume/AdyenPayment';
import { Heading } from 'components/Commons';
import { FormError } from 'components/UI/FormsPart';
import { MarketplaceState } from 'reducers/marketplace/MarketplaceState';
import { formatPhoneNumber } from 'utils';
import {
    PaymentsState,
    PaymentsStatus,
    confirmDepositThunk,
    getDropInDepositSessionThunk
} from '@spike/payments-action';
import useApiClientWrapper from 'hooks/useApiClientWrapper';
import { showErrorAlert } from 'actions/alerts/AlertsActions';
import Spinner from 'components/UI/Spinner';
import { createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { BookingStep } from 'components/Booking/Booking';

interface BookingPaymentProps {
    booking: EditBooking;
    marketplace: Marketplace;

    onError: () => void;
    onCompletePayment: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        spinner: {
            'marginTop': '30px !important',

            '& .MuiCircularProgress-colorPrimary': {
                color: '#eab464 !important'
            }
        }
    })
);

export const BookingPayment: React.FunctionComponent<BookingPaymentProps> = props => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const apiClientWrapper = useApiClientWrapper();

    const paymentState = useSelector<RootState, PaymentsState>(state => state.payments);

    const [dropIn, setDropIn] = useState<DropIn>();

    const [x, setX] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const [errorPayment, setErrorPayment] = useState<boolean>(false);

    const { marketplace } = useSelector<RootState, MarketplaceState>(state => state.marketplace);

    const checkAmount = () => {
        setLoading(false);

        console.log(
            Number(paymentState.dropIn?.amount.value) / 100 >
                Number(props.booking?.petsToBooking[0]?.service?.price)
        );

        if (
            Number(paymentState.dropIn?.amount.value) / 100 >
            Number(props.booking?.petsToBooking[0]?.service?.price)
        ) {
            dispatch(
                showErrorAlert(
                    'Your appointment requires a deposit before confirmation. The deposit exceeds the service price, to proceed with the booking please contact the marketplace.',
                    BookingStep.Notes
                )
            );
            setErrorPayment(true);
            props.onError();
            return;
        }

        paymentState.dropIn?.id && setDropIn(paymentState.dropIn);
    };

    const getDropinSession = () => {
        dispatch(getDropInDepositSessionThunk(apiClientWrapper, Number(props.booking.invoiceId)));
    };

    const completePaymentHandler = (paymentInfo: CompletedInfo) => {
        dispatch(
            confirmDepositThunk(
                apiClientWrapper,
                Number(props.booking.invoiceId),
                'credit_card',
                paymentInfo.sessionId
            )
        );
    };

    const errorPaymentHandler = () => {
        setX(x => x + 1);
        setErrorPayment(true);
    };

    useEffect(() => {
        getDropinSession();
        // eslint-disable-next-line
    }, []);

    useNonInitialEffect(() => {
        if (paymentState?.status === PaymentsStatus.GetDropInDepositSessionSuccess) {
            checkAmount();
        }

        if (paymentState?.status === PaymentsStatus.ConfirmDepositSuccess) {
            props.onCompletePayment && props.onCompletePayment();
        }

        if (paymentState?.status === PaymentsStatus.Error) {
            setLoading(false);
            setErrorPayment(true);
        }
    }, [paymentState]);

    const paymentError = (
        <>
            {errorPayment && (
                <FormError
                    key={`<b>Payment cannot be processed.</b> Please try a different card or contact <b>${
                        marketplace.name
                    }</b> directly to request an appointment using a different payment method. If you have any questions, contact <b>${
                        marketplace.name
                    }</b> at <b>${
                        marketplace.phone ? formatPhoneNumber(marketplace.phone) : '-'
                    }</b>.`}
                    title={`<b>Payment cannot be processed.</b> Please try a different card or contact <b>${
                        marketplace.name
                    }</b> directly to request an appointment using a different payment method. If you have any questions, contact <b>${
                        marketplace.name
                    }</b> at <b>${
                        marketplace.phone ? formatPhoneNumber(marketplace.phone) : '-'
                    }</b>.`}
                    boxProps={{
                        sx: {
                            'flex': '0 0 100%',
                            'mt': '40px !important',
                            ' > div': {
                                backgroundColor: '#ffeff0',
                                color: '#000000',
                                border: '2px solid #EF4F57',
                                borderRadius: '16px',
                                padding: '20px',
                                fontFamily: 'Poppins',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '140%',
                                whiteSpace: 'pre-wrap',
                                textAlign: 'justify',
                                display: 'flex',
                                alignItems: 'flex-start',
                                marginBottom: '20px'
                            }
                        }
                    }}
                    iconX={true}
                />
            )}
        </>
    );

    const dropinPayment = (
        <>
            {dropIn && (
                <Box className="BookingResume-billing-adyenPayment">
                    <AdyenPayment
                        dropIn={dropIn}
                        //
                        key={x}
                        onComplete={completePaymentHandler}
                        onError={errorPaymentHandler}
                    />
                </Box>
            )}
        </>
    );

    return (
        <BookingLayout
            sx={{
                '.BookingLayout-left-column': {
                    display: {
                        xs: 'none',
                        md: 'block'
                    },
                    pb: '16px !important'
                }
                // TODO: Remove
                // '.BookingLayout-right-column': { display: 'flex', marginTop: '0px !important' }
            }}
            rightColumnMobile
            showResume={props.booking}
            leftColumn={
                <>
                    <Heading title="Payment info" />

                    {loading ? (
                        <>
                            <Spinner className={classes.spinner} />
                        </>
                    ) : (
                        <>
                            {paymentError}

                            {dropIn && !isMobile ? dropinPayment : null}
                        </>
                    )}
                </>
            }
            rightColumn={
                <>
                    {isMobile && (
                        <Box sx={{ position: 'relative' }}>
                            {paymentError}

                            {dropIn && dropinPayment}
                        </Box>
                    )}
                </>
            }
        />
    );
};
