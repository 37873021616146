import { convertPetsData } from 'actions/pet/PetDataConverter';
import { Customer } from 'model';
import { CustomerDto } from './CustomerDtos';

export const convertCustomer = (dto: CustomerDto): Customer => ({
    id: dto.id,
    email: dto.email!,
    name: dto.first_name,
    avatar: dto.image_url!,
    phoneNumber: dto.phone!,
    blocked: !!dto.blocked,
    pets: convertPetsData(dto.pets ?? [])
});
