import { Option, OrderedOption, PetType } from './index';

import { MedicalHistoryOptions } from './MedicalHistory';

export const emptyMasterData: MasterData = {
    accessLevel: [],
    businessAreas: [],
    paymentTypes: [],
    petTypes: [],
    ageOptions: [],
    petAges: [],
    petSizes: [],
    petHairColors: {
        cats: [],
        dogs: [],
        exotics: []
    },
    petHairTypes: [],
    petHairLengths: {
        dog: [],
        cat: [],
        exotic: []
    },
    petExoticTypes: [],
    petGenders: [],
    grooming: {
        visibility: [],
        holdingAreas: [],
        sizeSeparation: [],
        security: [],
        locationTypes: []
    },
    daycare: {
        visibility: [],
        sizeSeparation: [],
        recreationalPerks: [],
        security: [],
        extras: []
    },
    boarding: {
        visibility: [],
        sizeSeparation: [],
        facilityTours: [],
        overnightCare: [],
        recreationalPerks: [],
        security: [],
        extras: []
    },
    staff: {
        training: []
    },
    status: [],
    serviceTags: [],
    roles: [],
    relationships: [],
    bookingStatus: [],
    dentalReportOptions: {
        dentalProcedures: [],
        homecare: [],
        periodontalFindings: [],
        recommendations: [],
        toothAbnormalities: []
    },
    groomingReportOptions: {
        skin: [],
        teeth: [],
        nails: [],
        coat: [],
        ears: [],
        eyes: [],
        behavior: [],
        findings: [],
        recommendations: [],
        findingPlaces: []
    },
    breeds: {
        dogs: [],
        cats: [],
        exotics: []
    },
    medicalHistory: {
        dentals: [],
        medicalConditionCategories: [],
        behaviorConditionCategories: []
    },
    petRequirements: [],
    imagesMaxSizes: {
        groomingReportMaxKb: 2048,
        dentalReportKb: 2048,
        squareLogoKb: 2048,
        squareLogo: {
            maxSizeKb: 2048,
            maxWidthPx: 600,
            maxHeightPx: 600,
            imageTypes: ['png', 'jpg']
        },
        horizontalLogoKb: 2048,
        horizontalLogo: {
            maxSizeKb: 2048,
            maxWidthPx: 500,
            maxHeightPx: 200,
            imageTypes: ['png', 'jpg']
        },
        avatarKb: 2048
    },
    vaccines: [],
    emailContent: [],
    productUnits: [],
    taxOptions: [],
    payments: {
        phoneTypes: [],
        documentTypes: {
            shareholder: [],
            businessProfile: [],
            bankAccount: []
        },
        genders: [],
        shareholderTypes: [],
        verificationStatuses: [],
        bankAccountTypes: [],
        currencies: [],
        files: {
            pdf: { maxSizeBytes: 0, minSizeBytes: 0 },
            image: { maxSizeBytes: 0, minSizeBytes: 0 }
        }
    },
    usStates: [],
    countryCodes: [],
    olbFromLimits: [],
    olbToLimits: []
};

export interface MasterData {
    businessAreas: Array<Option<string>>;
    accessLevel: Array<Option<number>>;
    roles: Array<Option<number>>;
    breeds: Breeds;
    petTypes: Array<PetType>;
    petAges: Array<Option<string>>;
    petSizes: Array<OrderedOption<string>>;
    petHairColors: HairColors;
    petHairTypes: Array<Option<string>>;
    petHairLengths: {
        dog: Array<Option<string>>;
        cat: Array<Option<string>>;
        exotic: Array<Option<string>>;
    };
    petExoticTypes: Array<Option<string>>;
    petGenders: Array<Option<string>>;
    paymentTypes: Array<Option<string>>;
    ageOptions: Array<Option<string>>;
    petRequirements: Array<Option<string>>;
    vaccines: Array<Option<string>>;
    grooming: Grooming;
    daycare: Daycare;
    boarding: Boarding;
    staff: Staff;
    status: Array<Option<number>>;
    serviceTags: Array<string>;
    relationships: Array<Option<number>>;
    bookingStatus: Array<Option<number>>;
    medicalHistory: MedicalHistoryOptions;
    dentalReportOptions: DentalReportOptions;
    groomingReportOptions: GroomingReportOptions;
    imagesMaxSizes: ImagesMaxSizes;
    emailContent: Array<string>;
    productUnits: Array<ProductUnit>;
    taxOptions: Array<Option<number>>;
    payments: Payments;
    usStates: Array<Option<string>>;
    countryCodes: Array<CountryCodes>;
    olbFromLimits: Array<TimeLimit>;
    olbToLimits: Array<TimeLimit>;
}

export interface Grooming {
    visibility: Array<Option<string>>;
    holdingAreas: Array<Option<string>>;
    sizeSeparation: Array<Option<string>>;
    security: Array<Option<string>>;
    locationTypes: Array<Option<string>>;
}

export interface Boarding {
    visibility: Array<Option<string>>;
    sizeSeparation: Array<Option<string>>;
    facilityTours: Array<Option<string>>;
    overnightCare: Array<Option<string>>;
    recreationalPerks: Array<Option<string>>;
    security: Array<Option<string>>;
    extras: Array<Option<string>>;
}

export interface Daycare {
    visibility: Array<Option<string>>;
    sizeSeparation: Array<Option<string>>;
    recreationalPerks: Array<Option<string>>;
    security: Array<Option<string>>;
    extras: Array<Option<string>>;
}

export interface Staff {
    training: Array<Option<string>>;
}

export interface Breeds {
    dogs: Array<Option<number>>;
    cats: Array<Option<number>>;
    exotics: Array<Option<number>>;
}

export interface HairColors {
    dogs: Array<Option<string>>;
    cats: Array<Option<string>>;
    exotics: Array<Option<string>>;
}

export interface DentalReportOptions {
    dentalProcedures: Array<Option<number>>;
    homecare: Array<Option<number>>;
    periodontalFindings: Array<Option<number>>;
    recommendations: Array<Option<number>>;
    toothAbnormalities: Array<Option<number>>;
}

export interface GroomingReportOptions {
    skin: Array<Option<number>>;
    teeth: Array<Option<number>>;
    nails: Array<Option<number>>;
    coat: Array<Option<number>>;
    ears: Array<Option<number>>;
    eyes: Array<Option<number>>;
    behavior: Array<Option<number>>;
    findings: Array<Option<number>>;
    findingPlaces: Array<Option<number>>;
    recommendations: Array<Option<number>>;
}

export interface ImagesMaxSizes {
    groomingReportMaxKb: number;
    dentalReportKb: number;
    /**
     * @deprecated Should not be used. Use squareLogo.maxSizeKb.
     */
    squareLogoKb: number;
    squareLogo: ImageDefinition;
    /**
     * @deprecated Should not be used. Use horizontalLogo.maxSizeKb.
     */
    horizontalLogoKb: number;
    horizontalLogo: ImageDefinition;
    avatarKb: number;
}

export interface ImageDefinition {
    maxSizeKb: number;
    maxWidthPx: number;
    maxHeightPx: number;
    imageTypes: Array<string>;
}

export interface ProductUnit {
    id: number;
    name: string;
    unit: string;
}

export interface Payments {
    phoneTypes: Array<Option<string>>;
    documentTypes?: PaymentsDocumentTypes;
    genders: Array<Option<string>>;
    verificationStatuses: Array<Option<string>>;
    shareholderTypes: Array<Option<string>>;
    currencies: Array<Option<string>>;
    bankAccountTypes: Array<Option<string>>;
    files: PaymentsFiles;
}

export interface CountryCodes {
    id: number;
    alpha2: string;
    alpha3: string;
    name: string;
    numeric: number;
}

export interface PaymentsFiles {
    pdf: PaymentsFileSizes;
    image: PaymentsFileSizes;
}

export interface PaymentsFileSizes {
    maxSizeBytes: number;
    minSizeBytes: number;
}
export interface PaymentsDocumentTypes {
    shareholder: Array<Option<string>>;
    businessProfile: Array<Option<string>>;
    bankAccount: Array<Option<string>>;
}

export interface TimeLimit {
    id: string;
    name: string;
    offsetSeconds: number | null;
}

export default MasterData;
