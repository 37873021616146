import { BookingState, BookingStatus } from './BookingState';
import {
    BOOKING_ERROR,
    BOOKING_RESET,
    BOOKING_CREATE_START,
    BOOKING_CREATE_SUCCESS,
    BOOKING_DEPOSIT_CREATE_START,
    BOOKING_DEPOSIT_CREATE_SUCCESS,
    BookingAction,
    BOOKING_GET_SIBLINGS_START,
    BOOKING_GET_SIBLINGS_SUCCESS
} from 'actions/booking/BookingActionsTypes';
import { Reducer } from 'redux';

const initialState: BookingState = {
    booking: undefined,
    status: BookingStatus.Initial,
    loading: false,
    siblings: []
};

export const bookingReducer: Reducer<BookingState, BookingAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case BOOKING_RESET:
            return initialState;
        case BOOKING_CREATE_START:
            return {
                ...state,
                status: BookingStatus.CreateStart,
                loading: true
            };
        case BOOKING_CREATE_SUCCESS:
            return {
                ...state,
                booking: action.booking,
                status: BookingStatus.CreateSuccess,
                loading: false
            };
        case BOOKING_DEPOSIT_CREATE_START:
            return {
                ...state,
                status: BookingStatus.CreateDepositStart,
                loading: true
            };
        case BOOKING_DEPOSIT_CREATE_SUCCESS:
            return {
                ...state,
                booking: action.booking,
                status: BookingStatus.CreateDepositSuccess,
                loading: false
            };
        case BOOKING_GET_SIBLINGS_START:
            return {
                ...state,
                loading: true,
                status: BookingStatus.FetchingSiblings
            };
        case BOOKING_GET_SIBLINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                siblings: action.pets,
                status: BookingStatus.FetchSiblingsSuccess
            };
        case BOOKING_ERROR:
            return {
                ...state,
                status: BookingStatus.Error,
                errorStep: action.step,
                loading: false
            };
        default:
            return state;
    }
};

export default bookingReducer;
