import { AvailabilityState, AvailabilityStatus } from './AvailabilityState';
import {
    AVAILABILITY_ERROR,
    AVAILABILITY_FETCH_START,
    AVAILABILITY_FETCH_SUCCESS,
    AVAILABILITY_GET_NEXT_DATE_START,
    AVAILABILITY_GET_NEXT_DATE_SUCCESS,
    AvailabilityAction
} from 'actions/availability/AvailabilityActionsTypes';
import { Reducer } from 'redux';

const initialState: AvailabilityState = {
    availableHours: [],
    status: AvailabilityStatus.Initial,
    loading: false
};

export const availabilityReducer: Reducer<AvailabilityState, AvailabilityAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case AVAILABILITY_FETCH_START:
            return {
                ...state,
                status: AvailabilityStatus.FetchStart,
                loading: true
            };
        case AVAILABILITY_FETCH_SUCCESS:
            return {
                availableHours: action.payload.availableHours,
                status: AvailabilityStatus.FetchSuccess,
                loading: false
            };
        case AVAILABILITY_GET_NEXT_DATE_START:
            return {
                ...state,
                status: AvailabilityStatus.GetNextDateStart,
                loading: true
            };
        case AVAILABILITY_GET_NEXT_DATE_SUCCESS:
            return {
                availableHours: action.payload.availableHours,
                status: AvailabilityStatus.GetNextDateSuccess,
                loading: false
            };
        case AVAILABILITY_ERROR:
            return {
                ...state,
                status: AvailabilityStatus.Error,
                loading: false
            };
        default:
            return state;
    }
};

export default availabilityReducer;
