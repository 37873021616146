import React from "react";
import { Link } from "react-router-dom";
import "./PrimaryLink.scss";

interface PrimaryLinkProps {
  title: string;
  to: object | string;
}

const PrimaryLink: React.FunctionComponent<PrimaryLinkProps> = (props) => {
  const { title, ...otherProps } = props;

  return (
    <Link className="PrimaryLink" {...otherProps}>
      {props.title}
    </Link>
  );
};

PrimaryLink.defaultProps = {
  title: "",
};

export default PrimaryLink;
