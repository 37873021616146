export interface AdyenResultCode {
  code: string,
  description: string,
  actionToTake: string,
  success: boolean,
}

const authenticationFinished = {
  code: "AuthenticationFinished",
  description: "The payment has been successfully authenticated with 3D Secure.",
  actionToTake: "Collect the 3D Secure 2 authentication data that you will need to authorise the payment.",
  success: true,
};


const authenticationNotRequired = {
  code: "AuthenticationNotRequired",
  description: "The transaction does not require 3D Secure authentication, for example, the issuing bank does not require authentication or the transaction is out of scope. Check the authenticationNotRequiredReason parameter to know why authentication was skipped.",
  actionToTake: "Since authentication is not required for the transaction, you can already proceed to authorise the payment.",
  success: true,
};

const authorised = {
  code: "Authorised",
  description: "The payment was successfully authorised.",
  actionToTake: "Inform the customer that the payment was successful.",
  success: true,
};

const cancelled = {
  code: "Cancelled",
  description: "The payment was canceled (by either the shopper or your own system) before processing was completed.",
  actionToTake: "Inform the shopper that their payment was canceled. Contact the shopper to check whether they want to continue with their order.",
  success: false,
};

const challengeShopper = {
  code: "ChallengeShopper",
  description: "The issuer requires further shopper interaction before the payment can be authenticated. Returned for 3D Secure 2 transactions.",
  actionToTake: "Present the challenge flow to the shopper and submit the result to Adyen.",
  success: false,
};

const error = {
  code: "Error",
  description: "There was an error when the payment was being processed.",
  actionToTake: "Inform the shopper that there was an error processing their payment.",
  success: false,
};

const identifyShopper = {
  code: "IdentifyShopper",
  description: "The issuer requires the shopper's device fingerprint before the payment can be authenticated. Returned for 3D Secure 2 transactions.",
  actionToTake: "Initiate the 3D Secure 2 device fingerprinting process and submit the result to Adyen.",
  success: false,
};

const pending = {
  code: "Pending",
  description: "It's not possible to obtain the final status of the payment at this time.",
  actionToTake: "Inform the shopper that you've received their order, and are waiting for the payment to be completed. When the shopper has completed the payment you will receive a successful AUTHORISATION notification. It can take minutes, hours, or even days for some payments to be completed.",
  success: true,
};

const presentToShopper = {
  code: "PresentToShopper",
  description: "Present the voucher or the QR code to the shopper.",
  actionToTake: "Inform the shopper that you've received their order, and are waiting for the payment to be completed. When the shopper has completed the payment you will receive a successful AUTHORISATION notification.",
  success: false,
};

const received = {
  code: "PresentToShopper",
  description: "This is part of the standard payment flow for methods such as SEPA Direct Debit, where it can take some time before the final status of the payment is known.",
  actionToTake: "Inform the shopper that you've received their order, and are waiting for the payment to clear. You will receive the final result of the payment in an AUTHORISATION notification. It can take minutes, hours, or even days for some payments to clear.",
  success: false,
};

const redirectShopper = {
  code: "RedirectShopper",
  description: "The customer needs to be redirected to an external web page or app to complete the payment.",
  actionToTake: "Redirect the shopper to complete the payment.",
  success: false,
};

const refused = {
  code: "Refused",
  description: "The payment was refused. The payment was refused. You'll receive a refusalReason in the same response that indicates why it was refused. We do not recommend disclosing this refusal reason to the shopper. For more information, see Refusal reasons.",
  actionToTake: "Inform the shopper that their payment was refused and ask them to try the payment again, for example, using a different payment method or card.",
  success: false,
};

export const adyenResultCodes = new Map<string,AdyenResultCode>([
  [authenticationFinished.code, authenticationFinished],
  [authenticationNotRequired.code, authenticationNotRequired],
  [authorised.code, authorised],
  [cancelled.code, cancelled],
  [challengeShopper.code, challengeShopper],
  [error.code, error],
  [identifyShopper.code, identifyShopper],
  [pending.code, pending],
  [presentToShopper.code, presentToShopper],
  [received.code, received],
  [redirectShopper.code, redirectShopper],
  [refused.code, refused],
]);
