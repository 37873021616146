import {
    BookingAction,
    BOOKING_RESET,
    BOOKING_CREATE_START,
    BOOKING_CREATE_SUCCESS,
    BOOKING_DEPOSIT_CREATE_START,
    BOOKING_DEPOSIT_CREATE_SUCCESS,
    BOOKING_ERROR,
    BOOKING_GET_SIBLINGS_START,
    BOOKING_GET_SIBLINGS_SUCCESS
} from './BookingActionsTypes';
import { ThunkAction } from 'redux-thunk';
import store from 'store';
import ApiClient, { createTokenConfig } from 'api/ApiClient';
import { AlertsAction } from 'actions/alerts/AlertsActionsTypes';
import { showErrorAlert } from 'actions/alerts/AlertsActions';
import { CallHistoryMethodAction } from 'connected-react-router';
import { NewBooking, NewBookingAppointment } from 'model/Booking';
import { NewBookingDto, SiblingDto } from './BookingDtos';
import axios, { AxiosResponse } from 'axios';
import { MasterDataAction } from 'actions/masterData/MasterDataActionsTypes';
import { BookingState } from 'reducers/booking/BookingState';
import { convertBooking, convertBookingDto } from './BookingDataConverter';
import { Customer } from 'model';
import { BookingStep } from 'components/Booking/Booking';

const bookingUrl = 'booking';
const bookingsUrl = 'bookings';
const siblingsUrl = 'siblings_booking';

const appointmentsUrl = 'appointments';

export const resetThunk = (): ThunkAction<void, BookingState, null, BookingAction> => {
    return async dispatch => {
        dispatch(reset());
    };
};

export const createBookingThunk =
    (
        newBooking: NewBooking
    ): ThunkAction<
        void,
        BookingState,
        null,
        BookingAction | AlertsAction | CallHistoryMethodAction | MasterDataAction
    > =>
    async dispatch => {
        dispatch(bookingCreateStart());

        const marketplaceId = store.getState().marketplace.marketplace.id;
        const apiMethod = ApiClient.post;
        const saveUrl = `${bookingsUrl}/?marketplace_id=${marketplaceId}`;

        const bookingRequest = convertBookingDto(newBooking);

        try {
            const response: AxiosResponse<NewBookingDto> = await apiMethod(
                saveUrl,
                bookingRequest,
                createTokenConfig()
            );

            const booking = convertBooking(response.data);

            dispatch(bookingCreateSuccess(booking));
        } catch (apiError: unknown) {
            let errorMessage = 'Error creating booking.';

            if (axios.isAxiosError(apiError)) {
                const data = apiError.response?.data;
                const error = Object.values(data as object)[0];

                if ('appointments.booked_at' in data) {
                    errorMessage =
                        'You already booked an appointment for this pet today. Please cancel previous appointment to continue booking.';
                } else {
                    errorMessage = error[0] || 'Oops, something went wrong.';
                }
            }

            dispatch(showErrorAlert(errorMessage, BookingStep.DateSelect));
            dispatch(error(BookingStep.DateSelect));
        }
    };

export const createBookingDepositThunk =
    (
        newBooking: NewBooking
    ): ThunkAction<
        void,
        BookingState,
        null,
        BookingAction | AlertsAction | CallHistoryMethodAction | MasterDataAction
    > =>
    async dispatch => {
        dispatch(bookingDepositCreateStart());

        const marketplaceId = store.getState().marketplace.marketplace.id;
        const saveUrl = `${bookingUrl}/create_for_deposits/?marketplace_id=${marketplaceId}`;

        const bookingRequest = convertBookingDto(newBooking);

        try {
            const response: AxiosResponse<NewBookingDto> = await ApiClient.post(
                saveUrl,
                bookingRequest,
                createTokenConfig()
            );
            dispatch(bookingDepositCreateSuccess(convertBooking(response.data)));
        } catch (apiError: unknown) {
            let errorMessage = 'Error creating booking.';

            if (axios.isAxiosError(apiError)) {
                const data = apiError.response?.data;

                const error = Object.values(data as object)[0];

                errorMessage = error[0];
            }

            dispatch(error(BookingStep.Notes));
            dispatch(showErrorAlert(errorMessage, BookingStep.Notes));
        }
    };

export const updateAppointmentStatusThunk = (
    appointmentId: NewBookingAppointment['id'],
    status: NewBookingAppointment['status']
): ThunkAction<
    void,
    BookingState,
    null,
    BookingAction | AlertsAction | CallHistoryMethodAction | MasterDataAction
> => {
    return async () => {
        const marketplaceId = store.getState().marketplace.marketplace.id;
        const url = `${appointmentsUrl}/${appointmentId}/?marketplace_id=${marketplaceId}`;

        try {
            await ApiClient.patch(url, { status }, createTokenConfig());
        } catch (apiError: unknown) {
            if (axios.isAxiosError(apiError)) {
                console.error('apiError ', apiError.response);
            }
        }
    };
};

export const fetchBookingSiblingsThunk =
    (
        date: string,
        customer: Customer
    ): ThunkAction<
        void,
        BookingState,
        null,
        BookingAction | AlertsAction | CallHistoryMethodAction | MasterDataAction
    > =>
    async (dispatch): Promise<boolean> => {
        dispatch(bookingGetSiblingsStart());

        const marketplaceId = store.getState().marketplace.marketplace.id;
        const url = `${siblingsUrl}?marketplace_id=${marketplaceId}&date=${date}&customer_id=${customer.id}`;

        try {
            const { data }: AxiosResponse<SiblingDto> = await ApiClient.get(url);

            const pets = data.pets || [];

            await dispatch(bookingGetSiblingsSuccess(pets));

            return pets.length > 0;
        } catch (apiError: unknown) {
            dispatch(error(BookingStep.Notes));
            dispatch(showErrorAlert('Error getting booking siblings.', BookingStep.Notes));

            if (axios.isAxiosError(apiError)) {
                console.error('apiError ', apiError.response);
            }

            return false;
        }
    };

export const reset = (): BookingAction => {
    return {
        type: BOOKING_RESET
    };
};

const bookingCreateStart = (): BookingAction => {
    return {
        type: BOOKING_CREATE_START
    };
};

const bookingCreateSuccess = (booking: NewBooking): BookingAction => {
    return {
        type: BOOKING_CREATE_SUCCESS,
        booking
    };
};

//

const bookingDepositCreateStart = (): BookingAction => {
    return {
        type: BOOKING_DEPOSIT_CREATE_START
    };
};

const bookingDepositCreateSuccess = (booking: NewBooking): BookingAction => {
    return {
        type: BOOKING_DEPOSIT_CREATE_SUCCESS,
        booking
    };
};
//

const bookingGetSiblingsStart = (): BookingAction => {
    return {
        type: BOOKING_GET_SIBLINGS_START
    };
};

const bookingGetSiblingsSuccess = (pets: Array<string>): BookingAction => {
    return {
        type: BOOKING_GET_SIBLINGS_SUCCESS,
        pets
    };
};
//
const error = (step?: BookingStep): BookingAction => {
    return {
        type: BOOKING_ERROR,
        step
    };
};
