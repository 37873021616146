import { Availability } from "model";
import { Action } from "redux";

//Types
export const AVAILABILITY_RESET = "AVAILABILITY_RESET";
export const AVAILABILITY_FETCH_START = "AVAILABILITY_FETCH_START";
export const AVAILABILITY_FETCH_SUCCESS = "AVAILABILITY_FETCH_SUCCESS";
export const AVAILABILITY_FETCH_FAILED = "AVAILABILITY_FETCH_FAILED";
export const AVAILABILITY_SAVE_START = "AVAILABILITY_SAVE_START";
export const AVAILABILITY_SAVE_SUCCESS = "AVAILABILITY_SAVE_SUCCESS";
export const AVAILABILITY_GET_NEXT_DATE_START = "AVAILABILITY_GET_NEXT_DATE_START";
export const AVAILABILITY_GET_NEXT_DATE_SUCCESS = "AVAILABILITY_GET_NEXT_DATE_SUCCESS";

export const AVAILABILITY_ERROR = "AVAILABILITY_ERROR";

interface AvailabilityResetAction extends Action<typeof AVAILABILITY_RESET> { }
interface AvailabilityFetchStartAction extends Action<typeof AVAILABILITY_FETCH_START> { }
interface AvailabilityFetchSuccessAction extends Action<typeof AVAILABILITY_FETCH_SUCCESS> {
  payload: {
    availableHours: Array<Availability>;
  }
}
interface AvailabilityGetNextDateStartAction extends Action<typeof AVAILABILITY_GET_NEXT_DATE_START> { }
interface AvailabilityGetNextDateSuccessAction extends Action<typeof AVAILABILITY_GET_NEXT_DATE_SUCCESS> {
  payload: {
    availableHours: Array<Availability>;
  }
}

interface AvailabilityErrorAction extends Action<typeof AVAILABILITY_ERROR> { }

//SignUpAction
export type AvailabilityAction =
  | AvailabilityResetAction
  | AvailabilityFetchStartAction
  | AvailabilityFetchSuccessAction
  | AvailabilityGetNextDateStartAction
  | AvailabilityGetNextDateSuccessAction
  | AvailabilityErrorAction
