import { Staff } from 'model';

export interface StaffsState {
  status: StaffStatus;
  loading: boolean;
  staffs: Array<Staff>;
}

export enum StaffStatus {
  Initial,

  Fetching,
  FetchSuccess,

  Set,

  Saving,
  SaveSuccess,
  Cleared,
  Error,
}
