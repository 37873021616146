import React, { FunctionComponent, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { BookingPath, IntakeFormPath } from './Paths';
import Booking from 'pages/Booking';
import NotFound from 'pages/404';
import IntakeForm from 'pages/IntakeForm';

const BookingRoute = BookingPath + '/*';
const IntakeFormRoute = IntakeFormPath + '*';

export const Routes: FunctionComponent = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path={BookingPath} component={Booking} />
                <Route exact path={IntakeFormPath} component={IntakeForm} />
                <Redirect from={IntakeFormRoute} to="/" />
                <Redirect from={BookingRoute} to="*" />
                <Route exact path="*" component={NotFound} />
                <Redirect to="*" />
            </Switch>
        </Fragment>
    );
};

export default Routes;
