import { removeAlert } from 'actions/alerts/AlertsActions';
import { AlertsAction, ADD_ALERT } from 'actions/alerts/AlertsActionsTypes';
import { Middleware, Dispatch, MiddlewareAPI } from 'redux';

export const notificationsMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AlertsAction>, AlertsAction>) =>
  (dispatch: Dispatch<AlertsAction>) =>
  (action: AlertsAction) => {
    if (action.type === ADD_ALERT) {
      setTimeout(() => {
        dispatch(removeAlert(action.payload.uuid));
      }, 10000);
    }
    return dispatch(action);
  };
