import {
  SERVICES_ERROR_ACTION_TYPE,
  SERVICES_FETCH_ACTION_TYPE,
  SERVICES_FETCH_SUCCESS_ACTION_TYPE,
  SERVICES_RESET_ACTION_TYPE,
  ServicesAction,
} from './ServicesActionsTypes';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';

import { ServicesState } from 'reducers/services/ServicesState';
import { ServiceDto } from './ServicesDtos';
import ApiPublicClient, { createTokenConfig } from 'api/ApiPublicClient';
import { convertToService } from './ServicesConverter';

const servicesUrl = '/olb_services_lookup';

export const fetchServicesThunk = (
  marketplaceId: number,
): ThunkAction<void, ServicesState, null, ServicesAction> => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const url = `${servicesUrl}?marketplace_id=${marketplaceId}`;

    try {
      const response: AxiosResponse<Array<ServiceDto>> = await ApiPublicClient.get(
        url,
        createTokenConfig(),
      );
      dispatch(fetchSucces(response.data));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
    }
  };
};

export const reset = (): ServicesAction => {
  return {
    type: SERVICES_RESET_ACTION_TYPE,
  };
};

const fetchStart = (): ServicesAction => {
  return {
    type: SERVICES_FETCH_ACTION_TYPE,
  };
};

export const fetchSucces = (servicesDto: Array<ServiceDto>): ServicesAction => {
  return {
    type: SERVICES_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      services: servicesDto.map((service) => convertToService(service)),
    },
  };
};

const error = (): ServicesAction => {
  return {
    type: SERVICES_ERROR_ACTION_TYPE,
  };
};
