import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyringe } from '@fortawesome/pro-regular-svg-icons';
import VaccineOption from 'components/Pet/Vaccine/VaccineOption';
import { useMarketplace } from 'hooks';
import { PetVaccine, createEmptyVaccine } from 'model';

export interface VaccinesFormProps {
    vaccines: Array<PetVaccine>;
    onChange: (vaccines: Array<PetVaccine>) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%',
            marginTop: 32
        },
        title: {
            'fontSize': 16,
            'lineHeight': 1,
            'fontWeight': 600,
            'padding': '16px 0px',
            'borderBottom': 'solid 2px #000',

            '& svg': {
                marginRight: 8
            }
        }
    })
);

const findVaccine = (vaccines: Array<PetVaccine>, type: string) => {
    return vaccines.find(vaccine => vaccine.type?.id === type);
};

const VaccinesForm: React.FC<VaccinesFormProps> = props => {
    const classes = useStyles();
    const marketplace = useMarketplace();

    const defaultVaccines = (): Array<PetVaccine & { checked: boolean }> => {
        return marketplace.vaccines.map(vaccine => {
            const petVaccine = findVaccine(props.vaccines, vaccine.id);

            if (petVaccine) {
                return { ...petVaccine, checked: petVaccine.id ? false : true };
            }

            return {
                ...createEmptyVaccine(),
                checked: false,
                type: vaccine
            };
        });
    };

    const [vaccines, setVaccines] = useState<Array<PetVaccine & { checked: boolean }>>(
        defaultVaccines()
    );

    const vaccineChangeHandler = (vaccine: PetVaccine, isChecked: boolean) => {
        const petVaccines = vaccines.map(v => {
            if (v.uuid === vaccine.uuid) {
                return { ...vaccine, checked: isChecked };
            }

            return v;
        });

        setVaccines(petVaccines);
        props.onChange(petVaccines.filter(v => v.checked));
    };

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>
                <FontAwesomeIcon icon={faSyringe} />
                Vaccines Records
            </Typography>

            {vaccines.map((vaccine, index) => (
                <VaccineOption
                    key={vaccine.id}
                    id={index}
                    vaccine={vaccine}
                    checked={vaccine.checked}
                    onChange={vaccineChangeHandler}
                />
            ))}
        </div>
    );
};

export default VaccinesForm;
