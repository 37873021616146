import React from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Spinner } from 'components/UI/Spinner';
import { DropIn } from 'model/Payments';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { adyenResultCodes } from './AdyenResultCodes';
import { PaymentsState, PaymentsStatus } from '@spike/payments-action';

interface AdyenPaymentProps {
  dropIn: DropIn;
  className?: string;
  //
  onComplete?: (info: CompletedInfo) => void;
  onError?: (message: string) => void;
}

export interface CompletedInfo {
  success: boolean;
  message: string;
  sessionId: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    dropIn: {
      width: '100%',
    },
    error: {
      width: '100%',
      textAlign: 'center',
      color: '#EF4F57',
      fontWeight: 500,
      letterSpacing: 2,
      textTransform: 'uppercase',
      fontSize: '16px',
    },
  }),
);

const NETWORK_ERROR = 'NETWORK_ERROR';

export const AdyenPayment: FunctionComponent<AdyenPaymentProps> = (props) => {
  const classes = useStyles();

  const dropInRef = useRef<HTMLInputElement>(null);

  const paymentsStatus = useSelector<RootState, PaymentsStatus>((state) => state.payments.status);

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (props.dropIn) {
      const createCheckout = async () => {
        const configuration = {
          environment: `${process.env.REACT_APP_ADYEN_ENVIRONMENT}`, // Change to 'live' for the live environment.
          clientKey: `${process.env.REACT_APP_ADYEN_CLIENT_KEY}`, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
          session: {
            id: props.dropIn!.id, // Unique identifier for the payment session.
            sessionData: props.dropIn!.sessionData, // The payment session data.
          },
          onPaymentCompleted: (result: any, component: any) => {
            const resultCode = adyenResultCodes.get(result.resultCode);

            if (resultCode?.success) {
              props.onComplete &&
                props.onComplete(
                  resultCode
                    ? {
                        success: resultCode.success,
                        message: resultCode.actionToTake,
                        sessionId: props.dropIn!.id,
                      }
                    : {
                        success: false,
                        message: 'An error as ocurred.',
                        sessionId: props.dropIn!.id,
                      },
                );
            } else {
              props.onError && props.onError('');
            }
          },
          onError: (error: any, component: any) => {
            const errorMessage = '';

            if (error.name === NETWORK_ERROR) {
              setError('Network error!');
            } else {
              setError('An unexpected error has occured!');
            }

            props.onError && props.onError(errorMessage);
          },
          // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
          // For example, this is 3D Secure configuration for cards:
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: false,
              billingAddressRequired: false,
            },
          },
        };

        const checkout = await AdyenCheckout(configuration);
        if (dropInRef.current) {
          checkout
            .create('dropin', {
              showStoredPaymentMethods: false,
            })
            .mount(dropInRef.current);
        }
      };

      setLoading(false);
      createCheckout();
    } else if (PaymentsStatus.Error === paymentsStatus) {
      setLoading(false);
      setError('Error getting session.');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box className={clsx(classes.container, props.className)}>
      {loading && <Spinner />}
      {error && <Typography className={classes.error}>{error}</Typography>}
      <div id='dropin-container' ref={dropInRef} className={classes.dropIn}></div>
    </Box>
  );
};

export default AdyenPayment;
