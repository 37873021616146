import { createStyles, makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import { findOrCreateCustomerWithPetsThunk } from 'actions/customer/CustomerActions';
import { IntakeFormAddPet } from 'components/IntakeForm/IntakeFormAddPet';
import { IntakeFormCustomer } from 'components/IntakeForm/IntakeFormCustomer';
import { IntakeFormPetList } from 'components/IntakeForm/IntakeFormPetList';
import { IntakeFormSuccess } from 'components/IntakeForm/IntakeFormSuccess';
import Spinner from 'components/UI/Spinner';
import { ProgressBar } from 'model';
import type { IntakeForm as IntakeFormType } from 'model/IntakeForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerState, CustomerStatus } from 'reducers/customer/CustomerState';
import { MasterDataState } from 'reducers/masterData/MasterDataState';
import { ServicesState } from 'reducers/services/ServicesState';
import { StaffsState } from 'reducers/staffs/StaffsState';
import { RootState } from 'store';

const useStyles = makeStyles(() =>
    createStyles({
        boxSpinner: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px'
        },
        spinner: {
            '& .MuiCircularProgress-colorPrimary': {
                color: '#eab464 !important'
            }
        }
    })
);

export enum IntakeFormStep {
    Loading,
    CustomerForm,
    AddPet,
    PetList,
    Success
}

const intakeFormStepsLength = Object.keys(IntakeFormStep).length / 2;

export const IntakeForm: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [intakeForm, setIntakeForm] = React.useState<IntakeFormType>({
        customer: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },

        pets: []
    });
    const [step, setStep] = React.useState<IntakeFormStep>(IntakeFormStep.CustomerForm);
    const [progress, setProgress] = React.useState<ProgressBar>({
        show: true,
        width: 1 / (intakeFormStepsLength - 1)
    });

    const staffsState = useSelector<RootState, StaffsState>(state => state.staffs);
    const servicesState = useSelector<RootState, ServicesState>(state => state.services);
    const masterDataState = useSelector<RootState, MasterDataState>(state => state.masterdata);
    const customerState = useSelector<RootState, CustomerState>(state => state.customer);

    React.useEffect(() => {
        if (step > 0) {
            setProgress(old => ({
                ...old,
                width: step / (intakeFormStepsLength - 1)
            }));
        }
    }, [step]);

    React.useEffect(() => {
        if (customerState.status === CustomerStatus.Error) {
            setStep(IntakeFormStep.CustomerForm);
        }
    }, [customerState.status]);

    const isLoading = React.useMemo(() => {
        const isLoadingStep = step === IntakeFormStep.Loading;
        const isMasterDataLoading = masterDataState.loading;
        const isServicesLoading = servicesState.loading;
        const isStaffsLoading = staffsState.loading;

        return isLoadingStep || isMasterDataLoading || isServicesLoading || isStaffsLoading;
    }, [step, masterDataState, servicesState, staffsState]);

    if (isLoading) {
        return (
            <Box className={classes.boxSpinner}>
                <Spinner className={classes.spinner} />
            </Box>
        );
    }

    const submitIntakeFormHandler = async () => {
        setStep(IntakeFormStep.Loading);

        const customer = await dispatch(
            findOrCreateCustomerWithPetsThunk(
                {
                    first_name: intakeForm.customer.firstName,
                    last_name: intakeForm.customer.lastName,
                    email: intakeForm.customer.email,
                    phone: intakeForm.customer.phone,
                    channel: 'intake-form',
                    ...(intakeForm.customer.emergencyContact && {
                        emergencyContacts: [
                            {
                                ...intakeForm.customer.emergencyContact
                            }
                        ]
                    }),
                    ...(intakeForm.customer.address?.address && {
                        addresses: [
                            {
                                city: `${intakeForm.customer.address?.city}`,
                                state: `${intakeForm.customer.address?.state}`,
                                zipCode: `${intakeForm.customer.address?.zipCode}`,
                                addressLineOne: `${intakeForm.customer.address?.address}`,
                                country: 'US',
                                active: true
                            }
                        ]
                    })
                },
                intakeForm.pets
            )
        );

        if ('id' in customer) {
            setStep(IntakeFormStep.Success);
        }
    };

    const changeHandler = (intakeForm: IntakeFormType) => {
        setIntakeForm(intakeForm);
    };

    const backHandler = () => {
        switch (step) {
            case IntakeFormStep.AddPet:
                setStep(IntakeFormStep.CustomerForm);
                break;
            case IntakeFormStep.PetList:
                setStep(
                    intakeForm.pets.length === 0
                        ? IntakeFormStep.AddPet
                        : IntakeFormStep.CustomerForm
                );
                break;
        }
    };

    const nextHandler = () => {
        switch (step) {
            case IntakeFormStep.CustomerForm:
                setStep(
                    intakeForm.pets.length > 0 ? IntakeFormStep.PetList : IntakeFormStep.AddPet
                );
                break;
            case IntakeFormStep.AddPet:
                setStep(IntakeFormStep.PetList);
                break;
            case IntakeFormStep.PetList:
                submitIntakeFormHandler();
                break;
        }
    };

    const customerFormView = (
        <IntakeFormCustomer
            progress={progress}
            intakeForm={intakeForm}
            onChange={changeHandler}
            onContinue={nextHandler}
        />
    );

    const addPetFormView = (
        <IntakeFormAddPet
            progress={progress}
            intakeForm={intakeForm}
            onBack={backHandler}
            onChange={changeHandler}
            onContinue={nextHandler}
        />
    );

    const petListView = (
        <IntakeFormPetList
            progress={progress}
            intakeForm={intakeForm}
            onBack={backHandler}
            onAddPet={() => setStep(IntakeFormStep.AddPet)}
            onChange={changeHandler}
            onContinue={nextHandler}
        />
    );

    const successView = <IntakeFormSuccess progress={progress} />;

    return (
        <>
            {step === IntakeFormStep.CustomerForm && customerFormView}
            {step === IntakeFormStep.AddPet && addPetFormView}
            {step === IntakeFormStep.PetList && petListView}
            {step === IntakeFormStep.Success && successView}
        </>
    );
};
