import { Box, Grid } from '@mui/material';
import { Heading } from 'components/Commons';
import PetForm from 'components/Pet/PetForm';
import { BookingLayout } from 'components/UI/Layout';
import { EditBooking, Marketplace, Pet } from 'model';
import React, { useState } from 'react';
import store from 'store';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import FormErrorList from 'components/UI/FormsPart/FormErrorList/FormErrorList';
import { BookingStep } from 'components/Booking/Booking';

interface BookingAddPetProps {
    booking: EditBooking;
    marketplace: Marketplace;
    //
    onChange: (booking: EditBooking) => void;
    onContinue: () => void;
}

const BookingAddPet: React.FC<BookingAddPetProps> = props => {
    const [pet, setPet] = useState<Pet>({} as Pet);
    const [isInvalid, setIsInvalid] = useState(false);

    const petFormChangeHandler = (formPet: Pet, isFormInvalid: boolean) => {
        setPet(formPet);
        setIsInvalid(isFormInvalid);
    };

    const continueHandler = () => {
        amplitude.track(AMPLITUDE.CTA_SAVE_PET_INFO, {
            marketplace: store.getState().marketplace.marketplace.name
        });

        props.onChange({
            ...props.booking,
            pets: props.booking.pets.filter(p => p.name !== pet.name).concat(pet),
            petsToBooking: [{ pet }]
        });

        props.onContinue();
    };

    return (
        <BookingLayout
            showResume={props.booking}
            showFooter={{
                label: 'Add Pet',
                disabled: isInvalid,
                onClick: continueHandler
            }}
            leftColumn={
                <Grid item xs={12}>
                    <>
                        <Heading title={<small>Add pet</small>} />

                        <FormErrorList step={BookingStep.AddPet} />

                        <Box sx={{ mt: 2 }}>
                            <PetForm
                                loading={false}
                                showRemove={false}
                                //
                                onRemove={() => {}}
                                onChange={petFormChangeHandler}
                            />
                        </Box>
                    </>
                </Grid>
            }
        />
    );
};

export default BookingAddPet;
