import React from 'react'
import { Box } from '@mui/material'
import './FormError.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
interface FormErrorProps {
    color?: string,
    title: string,
    boxProps?: object,
    icon?: boolean,
    iconX?: boolean,
}

const FormError: React.FunctionComponent<FormErrorProps> = (props) => {
    const { boxProps, ...otherProps } = props

    return (
        <Box {...boxProps}>
            <div className="FormError" {...otherProps}>
                { props.icon &&
                    <FontAwesomeIcon
                        icon={faCircleInfo}
                        color={"var(--titles-color)"}
                        size="xl"
                        className="InfoIcon"
                    />
                }
                { props.iconX &&
                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        color={"var(--error-color)"}
                        size="xl"
                        className="InfoIcon"
                    />
                }
                <div dangerouslySetInnerHTML={{ __html: props.title }}></div>
            </div>
        </Box>
    )
}

FormError.defaultProps = {
    title: ''
}

export default FormError
