import { Box } from '@mui/material';
import AgreementCard from 'components/Agreements/AgreementCard/AgreementCard';
import AgreementModal from 'components/Agreements/AgreementModal/AgreementModal';
import { Heading } from 'components/Commons';
import { Agreement, AgreementSignature } from 'model';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export interface AgreementsListProps {
    signatures: Array<AgreementSignature>;
    onChange: (signatures: Array<AgreementSignature>) => void;
}

const AgreementsList: React.FC<AgreementsListProps> = props => {
    const agreementsState = useSelector((state: RootState) => state.agreements);

    const [openAgreement, setOpenAgreement] = React.useState<Agreement>();

    const isAgreementSigned = (agreement: Agreement) => {
        return props.signatures.some(signature => signature.agreementId === agreement.id);
    };

    const agreementOpenHandler = (agreement: Agreement) => {
        setOpenAgreement(agreement);
    };

    const closeHandler = () => {
        setOpenAgreement(undefined);
    };

    const signHandler = (signature: AgreementSignature) => {
        props.onChange([...props.signatures, signature]);
        setOpenAgreement(undefined);
    };

    const activeAgreements = React.useMemo(() => {
        return agreementsState.agreements.filter(agreement => agreement.active);
    }, [agreementsState]);

    return (
        <>
            {activeAgreements.length > 0 && (
                <>
                    <Heading
                        title="Agreements"
                        boxProps={{ mt: 5, sx: { mb: { xs: 3, md: 5 } } }}
                    />

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                        {activeAgreements.map((agreement, index) => (
                            <AgreementCard
                                key={agreement.id}
                                agreement={agreement}
                                id={`agreement-${index}`}
                                checked={isAgreementSigned(agreement)}
                                onClick={agreementOpenHandler}
                            />
                        ))}
                    </Box>
                </>
            )}

            {openAgreement && (
                <AgreementModal
                    agreement={openAgreement}
                    onClose={closeHandler}
                    onSign={signHandler}
                />
            )}
        </>
    );
};

export default AgreementsList;
