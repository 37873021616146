import InputMask from "react-input-mask";
import React, { useMemo, useRef } from 'react';
import { isString } from "lodash";
import CustomField from "../CustomField/CustomField";

export const CustomPhoneField = (props: any) => {
    const input = useRef<HTMLInputElement>(null);

    const computedValue = useMemo(() => {
        return isString(props.value) ? props.value : '';
    }, [props.value]);

    return (
        <InputMask
            value={computedValue}
            mask="(999) 999-9999"
            onChange={props.onChange}
        >
            {() => <CustomField {...props} ref={input} />}
        </InputMask>
    )
};