import {
    MarketplaceAction,
    MARKETPLACE_RESET_ACTION_TYPE,
    MARKETPLACE_CLEAR_ACTION_TYPE,
    MARKETPLACE_FETCH_ACTION_TYPE,
    MARKETPLACE_FETCH_SUCCESS_ACTION_TYPE,
    MARKETPLACE_ERROR_ACTION_TYPE
} from './MarketplaceActionsTypes';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { MarketplaceState } from 'reducers/marketplace/MarketplaceState';
import { Marketplace } from 'model/Marketplace';
import ApiPublicClient from 'api/ApiPublicClient';
import { convertMarketplace } from './MarketplaceConverter';
import { cleanMarketplaceLocalStorage, setMarketplaceLocalStorage } from 'auth/Marketplace';
import { MarketplaceDto } from './MarketplaceDtos';
import {
    convertPetAges,
    convertPetHairLengths,
    convertPetSizes
} from 'actions/pet/PetDataConverter';
import { setPetAges, setPetHairLengths, setPetSizes } from 'actions/pet/PetActions';
import { PetAction } from 'actions/pet/PetActionsTypes';

const marketplacesUrl = '/marketplace_lookup/';

export const fetchMarketplaceThunk = (
    marketplaceId: string
): ThunkAction<void, MarketplaceState, null, MarketplaceAction> => {
    return async dispatch => {
        dispatch(clear());
        dispatch(fetchStart());
        cleanMarketplaceLocalStorage();

        const url = `${marketplacesUrl}${marketplaceId}?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<MarketplaceDto> = await ApiPublicClient.get(url);
            setMarketplaceLocalStorage(convertMarketplace(response.data));
            dispatch(fetchSucces(convertMarketplace(response.data)));
        } catch (apiError) {
            dispatch(error());
        }
    };
};

export const fetchMarketplaceBySlugThunk = (
    slug: string
): ThunkAction<void, MarketplaceState, null, MarketplaceAction | PetAction> => {
    return async dispatch => {
        dispatch(fetchStart());
        cleanMarketplaceLocalStorage();

        const url = `${marketplacesUrl}?url_slug=${slug}`;

        try {
            const response: AxiosResponse<MarketplaceDto> = await ApiPublicClient.get(url);

            setMarketplaceLocalStorage(convertMarketplace(response.data));

            const petAges = convertPetAges(
                response.data.cats_detail,
                response.data.dogs_detail,
                response.data.exotics_detail
            );

            const petSizes = convertPetSizes(
                response.data.cats_detail,
                response.data.dogs_detail,
                response.data.exotics_detail
            );

            const petHairLengths = convertPetHairLengths(
                response.data.cats_detail,
                response.data.dogs_detail,
                response.data.exotics_detail
            );

            dispatch(setPetAges(petAges));
            dispatch(setPetSizes(petSizes));
            dispatch(setPetHairLengths(petHairLengths));

            dispatch(fetchSucces(convertMarketplace(response.data)));
        } catch (apiError) {
            dispatch(error());

            console.log(apiError);
        }
    };
};

export const reset = (): MarketplaceAction => {
    return {
        type: MARKETPLACE_RESET_ACTION_TYPE
    };
};

const clear = (): MarketplaceAction => {
    return {
        type: MARKETPLACE_CLEAR_ACTION_TYPE
    };
};

const fetchStart = (): MarketplaceAction => {
    return {
        type: MARKETPLACE_FETCH_ACTION_TYPE
    };
};

export const fetchSucces = (marketplace: Marketplace): MarketplaceAction => {
    return {
        type: MARKETPLACE_FETCH_SUCCESS_ACTION_TYPE,
        payload: {
            marketplace
        }
    };
};

const error = (): MarketplaceAction => {
    return {
        type: MARKETPLACE_ERROR_ACTION_TYPE
    };
};
