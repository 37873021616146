import { Box } from "@mui/material"
import React from "react"
import './SelectButton.scss'

interface SelectButtonProps {
    onChange(option: string): void,
    selected?: number | string,
    option: {
        id: string,
        title: string
    }
}

const SelectButton: React.FunctionComponent<SelectButtonProps> = ({ option, selected, onChange }) => {
    return (
        <Box id={`hourList_button_${option.id}`}  onClick={() => onChange(option.id)} className={`SelectButton ${selected === option.id ? 'SelectButton-selected' : ''}`}>
            {option.id}
        </Box>
    )
}

export default SelectButton
