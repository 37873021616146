import {
    AGREEMENTS_ERROR,
    AGREEMENTS_FETCH_START,
    AGREEMENTS_FETCH_SUCCESS,
    AGREEMENTS_GET_SIGNATURE_START,
    AGREEMENTS_GET_SIGNATURE_SUCCESS,
    AGREEMENTS_RESET,
    AgreementsAction
} from 'actions/agreements/AgreementsActionsTypes';
import { AgreementsState, AgreementsStatus } from 'reducers/agreements/AgreementsState';
import { Reducer } from 'redux';

const initialState: AgreementsState = {
    status: AgreementsStatus.Initial,
    signature: undefined,
    agreements: [],
    loading: false
};

export const agreementsReducer: Reducer<AgreementsState, AgreementsAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case AGREEMENTS_RESET:
            return {
                ...initialState
            };

        case AGREEMENTS_ERROR:
            return {
                ...state,
                loading: false,
                status: AgreementsStatus.Error
            };

        // Fetch
        case AGREEMENTS_FETCH_START:
            return {
                ...state,
                loading: true,
                status: AgreementsStatus.FetchStart
            };
        case AGREEMENTS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                agreements: action.payload.agreements,
                status: AgreementsStatus.FetchSuccess
            };

        // Get signature
        case AGREEMENTS_GET_SIGNATURE_START:
            return {
                ...state,
                loading: true,
                status: AgreementsStatus.GetSignatureStart
            };
        case AGREEMENTS_GET_SIGNATURE_SUCCESS:
            return {
                ...state,
                loading: false,
                signature: action.payload.signature,
                status: AgreementsStatus.GetSignatureSuccess
            };

        default:
            return state;
    }
};

export default agreementsReducer;
