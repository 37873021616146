import { MarketplacePetSize } from 'model/Marketplace';
import { Ordered } from 'model';

export const getSizeByWeight = (
    weight: number,
    orderedSizes: Array<MarketplacePetSize>
) => {
    let size = orderedSizes.find(
        size =>
            (size.minWeight === null || weight >= size.minWeight) &&
            (size.maxWeight === null || weight <= size.maxWeight)
    );

    if (size === undefined && orderedSizes.length > 0) {
        const [firstSize] = orderedSizes;
        const [lastSize] = orderedSizes.reverse();

        size =
            firstSize.minWeight === null || weight < firstSize.minWeight
                ? firstSize
                : undefined;

        if (size === undefined) {
            size =
                lastSize.minWeight === null || weight > lastSize.minWeight
                    ? lastSize
                    : undefined;
        }
    }

    return size;
};
