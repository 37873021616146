import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import './Modal.styles.scss';

export type ModalProps = React.PropsWithChildren<React.ReactNode | undefined> & {
    title: string;
    open?: boolean;
    footer?: React.ReactNode;
    onClose?: () => void;
};

const Modal: React.FC<ModalProps> = props => {
    return (
        <>
            <div className="Modal-wrapper" data-open={!!props.open}>
                <div
                    className="Modal"
                    data-has-footer={!!props.footer}
                    data-has-body={!!props.children}
                >
                    <div className="Modal-header">
                        <p className="Modal-title">{props.title}</p>

                        <button type="button" className="Modal-close" onClick={props.onClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>

                    <div className="Modal-body">{props.children}</div>

                    {props.footer && <div className="Modal-footer">{props.footer}</div>}
                </div>
            </div>
        </>
    );
};

export default Modal;
