import React from 'react';
import { Pet } from 'model';
import './PetChip.styles.scss';
import PetIcon from 'components/Pet/PetIcon/PetIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

interface PetChipProps {
  pet: Pet;
  active?: boolean;
  checked?: boolean;
  onClick?: (pet: Pet) => void;
}

const PetChip: React.FC<PetChipProps> = (props) => {
  const clickHandler = () => {
    props.onClick && props.onClick(props.pet);
  };

  return (
    <div
      className='PetChip'
      data-active={props.active}
      data-checked={props.checked}
      data-clickable={props.onClick ? 'true' : 'false'}
      onClick={clickHandler}
    >
      <PetIcon pet={props.pet} />

      <span className='PetChip--name'>{props.pet.name}</span>

      {props.checked && <FontAwesomeIcon icon={faCircleCheck} className='PetChip-checkIcon' />}
    </div>
  );
};

export default PetChip;
