import {
    ADD_ALERT,
    AlertsAction,
    CLEAR_ALERT,
    REMOVE_ALERT,
    REMOVE_ALERTS_BY_STEP
} from 'actions/alerts/AlertsActionsTypes';
import { AlertsState } from './AlertsState';

const initialState: AlertsState = [];

function alertsReducer(state: AlertsState = initialState, action: AlertsAction) {
    switch (action.type) {
        case ADD_ALERT:
            return [...state, action.payload];
        case REMOVE_ALERT:
            return state.filter(alert => alert.uuid !== action.payload);
        case REMOVE_ALERTS_BY_STEP:
            return state.filter(alert => alert.step !== action.payload.step);
        case CLEAR_ALERT:
            return initialState;
        default:
            return state;
    }
}

export default alertsReducer;
