import { MasterDataState, MasterDataStatus } from "./MasterDataState";
import {
  MASTER_DATA_RESET_ACTION_TYPE,
  MASTER_DATA_FETCH_START_ACTION_TYPE,
  MASTER_DATA_FETCH_SUCCESS_ACTION_TYPE,
  MASTER_DATA_ERROR_ACTION_TYPE,
  MasterDataAction,
} from "../../actions/masterData/MasterDataActionsTypes";
import { Reducer } from "redux";
import { emptyMasterData } from "../../model/MasterData";

const initialState: MasterDataState = {
  status: MasterDataStatus.Initial,
  loading: false,
  data: emptyMasterData,
};

export const masterDataReducer: Reducer<MasterDataState, MasterDataAction> = (state = initialState, action) => {
  switch (action.type) {
    case MASTER_DATA_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case MASTER_DATA_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: MasterDataStatus.Fetching,
        loading: true,
      };
    case MASTER_DATA_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: MasterDataStatus.FetchSuccess,
        loading: false,
        data: action.payload.masterData,
      };
    case MASTER_DATA_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: MasterDataStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default masterDataReducer;
