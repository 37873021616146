import React from 'react';

import './AgreementCard.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { Agreement } from 'model';

export interface AgreementCardProps {
    id?: string;
    checked?: boolean;
    agreement: Agreement;
    onClick: (agreement: Agreement) => void;
}

const AgreementCard: React.FC<AgreementCardProps> = props => {
    const clickHandler = () => {
        props.onClick(props.agreement);
    };

    return (
        <div className="AgreementCard" data-checked={!!props.checked}>
            <FontAwesomeIcon icon={faCircleCheck} className="AgreementCard-checkIcon" />

            <span className="AgreementCard-name">
                <FontAwesomeIcon icon={faFileSignature} />
                {props.agreement.title}
            </span>

            <button className="AgreementCard-signButton" id={props.id} onClick={clickHandler}>
                Read & Sign
            </button>
        </div>
    );
};

export default AgreementCard;
