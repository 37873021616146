import { faCloudArrowUp, faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface VaccineFileButtonProps {
    id: string;
    file?: File;
    className?: string;
    onClick: () => void;
    onRemove: () => void;
}

const VaccineFileButton: React.FC<VaccineFileButtonProps> = props => {
    return (
        <>
            {props.file ? (
                <button
                    id={props.id}
                    type="button"
                    className={props.className}
                    onClick={props.onRemove}
                >
                    <FontAwesomeIcon icon={faFilePdf} />

                    <span>{props.file ? props.file.name : 'Upload'}</span>

                    <FontAwesomeIcon icon={faX} />
                </button>
            ) : (
                <button
                    id={props.id}
                    type="button"
                    className={props.className}
                    onClick={props.onClick}
                >
                    <FontAwesomeIcon icon={faCloudArrowUp} />

                    <span>Upload</span>
                </button>
            )}
        </>
    );
};

export default VaccineFileButton;
