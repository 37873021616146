import { BookingStep } from 'components/Booking/Booking';
import { IntakeFormStep } from 'components/IntakeForm/IntakeForm';
import { Alert } from 'model/Alerts';
import { Action } from 'redux';

//Types
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALERTS_BY_STEP = 'REMOVE_ALERTS_BY_STEP';
export const CLEAR_ALERT = 'CLEAR_ALERT';

//Alert
interface AlertAddAlertAction extends Action<typeof ADD_ALERT> {
    payload: Alert;
}
interface AlertRemoveAlertAction extends Action<typeof REMOVE_ALERT> {
    payload: string;
}
interface AlertRemoveAlertsByStepAction extends Action<typeof REMOVE_ALERTS_BY_STEP> {
    payload: {
        step: BookingStep | IntakeFormStep;
    };
}

interface AlertClearAlertAction extends Action<typeof CLEAR_ALERT> {}

//AlertsAction
export type AlertsAction =
    | AlertAddAlertAction
    | AlertRemoveAlertAction
    | AlertClearAlertAction
    | AlertRemoveAlertsByStepAction;
