import { BookingPath, HomePath } from "routes/Paths";
import useMarketplace from "./useMarketplace";
import store from "store";

export const useHomePath = (slug?: string): string => {
    const marketplace = store.getState().marketplace.marketplace;

    if (
        !slug &&
        (!marketplace || !marketplace.slug)
    ) {
        return "/";
    }

    return HomePath.replace(':slug', slug || marketplace.slug);
};

export const useBookingPath = (slug?: string): string => {
    const marketplace = useMarketplace();

    if (
        !slug &&
        (!marketplace || !marketplace.slug)
    ) {
        return "/";
    }

    return BookingPath.replace(':slug', slug || marketplace.slug);
};
