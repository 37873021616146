import React from 'react';

import { Box } from '@mui/material';

import { DateTime } from 'luxon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';

import { Day } from 'model';
import clsx from 'clsx';

interface DatePickerSpinnerProps {
  value: Day;
  range: Day[];
  loading: boolean;
  isMobile: boolean;
  disabled: Day['ISODate'][];
  onCurrentWeek: boolean;
  onChange(day: Day, id: string): void;
  controlAction(action: 'prev' | 'next'): void;
}

const DatePickerSpinner: React.FunctionComponent<DatePickerSpinnerProps> = ({
  range,
  value,
  loading,
  isMobile,
  disabled,
  onCurrentWeek,
  controlAction,
  onChange,
}) => {
  const currentDate = DateTime.now();

  const isDateSelected = (newValue: Day) => {
    return !value ? false : value.ISODate === newValue.ISODate;
  };

  const isDateDisabled = (value: Day) => {
    return (
      (disabled && disabled.includes(value.ISODate)) || value.object.endOf('day') < currentDate
    );
  };

  const daySelected = (val: Day, id: string) => {
    if (isDateDisabled(val)) return false;

    onChange(val, id);
  };

  return (
    <Box className='DatePicker-spin'>
      {!isMobile && (
        <Box
          className={`DatePicker-button DatePicker-button-prev ${
            onCurrentWeek ? 'DatePicker-button-disabled' : ''
          }`}
          onClick={() => controlAction('prev')}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Box>
      )}

      {[
        range.map((val, i) => (
          <Box
            key={i}
            onClick={() => daySelected(val, `#${val.dayLabel}-${val.dayNumber}`)}
            className={clsx('DatePicker-day', {
              'DatePicker-day-selected': isDateSelected(val),
              'DatePicker-day-disabled': isDateDisabled(val),
              'DatePicker-day-loading': loading,
            })}
          >
            <input
              id={`${val.dayLabel}-${val.dayNumber}`}
              type='radio'
              name='datepicker'
              value={val.ISODate}
            />
            <Box className='DatePicker-day-label'>{val.dayLabel}</Box>
            <Box className='DatePicker-day-number'>{val.dayNumber}</Box>
          </Box>
        )),
      ]}

      {!isMobile && (
        <Box
          className='DatePicker-button DatePicker-button-next'
          onClick={() => controlAction('next')}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Box>
      )}
    </Box>
  );
};

export default DatePickerSpinner;
