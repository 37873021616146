import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Heading from 'components/Commons/Heading/Heading';
import BookingLayout from 'components/UI/Layout/BookingLayout/BookingLayout';
import { FullWidthImage } from 'components';
import { hero3, poweredBy } from 'assets/images';
import store from 'store';
import { formatPhoneNumber } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faPhoneFlip } from '@fortawesome/pro-light-svg-icons';
import { MarketplaceType } from 'model';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logoContainer: {
            paddingBottom: '25px'
        },
        logo: {
            width: '141px'
        },
        subtitle: {
            fontSize: '18px',
            lineHeight: '23.4px',
            marginBottom: '1em'
        },
        detailsContainer: {
            display: 'flex'
        },
        detailIconContainer: {
            width: '5%'
        },
        detailTextContainer: {
            width: '95%'
        },
        detailIcon: {
            fontSize: '18px',
            fontWeight: 500
        },
        detailText: {
            fontSize: '18px',
            lineHeight: '32px',
            fontWeight: 500
        },
        footerText: {
            fontSize: '18px'
        },
        footerContainer: {
            height: '11vh !important',
            display: 'flex',
            [theme.breakpoints.only('lg')]: {
                display: 'none !important'
            },
            [theme.breakpoints.only('xl')]: {
                display: 'none !important'
            }
        },
        footerLogoContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        footerLogo: {
            alignSelf: 'flex-end',
            width: '127px',
            height: '40px'
        }
    })
);
export const BookingDisabled: React.FunctionComponent = () => {
    const classes = useStyles();

    const marketplace = store.getState().marketplace.marketplace;

    return (
        <BookingLayout
            hiddenHeader={true}
            leftColumn={
                <>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                'textAlign': 'left',
                                'maxWidth': { xs: '100%' },
                                'mx': 'auto',
                                'px': { xs: 1, md: 0 },
                                'minHeight': { xs: '70vh', md: 'auto' },
                                'display': 'flex',
                                'flexDirection': 'column',
                                'justifyContent': 'center',
                                'h1': { mt: 0, mb: 0 },
                                'p b:last-child': { display: { xs: 'block', md: 'inline' } }
                            }}
                        >
                            <Box className={classes.logoContainer}>
                                <img className={classes.logo} src={marketplace.logo}></img>
                            </Box>

                            <Heading
                                divider={false}
                                title="Online booking currently unavailable"
                                boxProps={{
                                    sx: {
                                        mb: 3
                                    }
                                }}
                            />

                            <Typography className={classes.subtitle}>
                                We're sorry, but our online booking service is currently
                                unavailable.
                                <br />
                            </Typography>

                            {marketplace.id && (
                                <>
                                    <Typography className={classes.subtitle}>
                                        If you would like to schedule an appointment or have any
                                        questions, please do not hesitate to reach out to us:
                                    </Typography>

                                    <Typography className={classes.detailText}>
                                        {marketplace.name}
                                    </Typography>
                                    <Box className={classes.detailsContainer}>
                                        <Box className={classes.detailIconContainer}>
                                            <FontAwesomeIcon
                                                className={classes.detailIcon}
                                                icon={faPhoneFlip}
                                            ></FontAwesomeIcon>
                                        </Box>
                                        <Box className={classes.detailTextContainer}>
                                            <Typography className={classes.detailText}>
                                                {' '}
                                                {formatPhoneNumber(marketplace.phone!)}
                                            </Typography>{' '}
                                        </Box>
                                    </Box>

                                    <Box className={classes.detailsContainer}>
                                        <Box className={classes.detailIconContainer}>
                                            <FontAwesomeIcon
                                                className={classes.detailIcon}
                                                icon={faMapPin}
                                            ></FontAwesomeIcon>
                                        </Box>
                                        <Box className={classes.detailTextContainer}>
                                            <Typography className={classes.detailText}>
                                                {' '}
                                                {(marketplace.businessType ===
                                                    MarketplaceType.ATHOME ||
                                                    marketplace.businessType ===
                                                        MarketplaceType.LOCAL_BUSINESS) &&
                                                    marketplace.address![0].addressLineOne + ' '}
                                                {(marketplace.businessType ===
                                                    MarketplaceType.ATHOME ||
                                                    marketplace.businessType ===
                                                        MarketplaceType.LOCAL_BUSINESS) &&
                                                    marketplace.address![0].addressLineTwo + ', '}
                                                {marketplace.address![0].city}{' '}
                                                {marketplace.address![0].state}
                                                {(marketplace.businessType ===
                                                    MarketplaceType.ATHOME ||
                                                    marketplace.businessType ===
                                                        MarketplaceType.LOCAL_BUSINESS) &&
                                                    ', ' + marketplace.address![0].zipcode}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </>
                            )}

                            <br />

                            <Typography className={classes.footerText}>
                                We look forward to seeing you soon!
                            </Typography>
                        </Box>
                    </Grid>
                    <Box className={classes.footerContainer}>
                        <Box className={classes.footerLogoContainer}>
                            <img className={classes.footerLogo} src={poweredBy}></img>
                        </Box>
                    </Box>
                </>
            }
            rightColumn={<FullWidthImage hiddenHeader={true} src={hero3} />}
        />
    );
};

export default BookingDisabled;
