import { Customer } from 'model';
import { Auth } from 'model/Auth';

export interface LoginState {
    customerLookUp?: Customer;
    auth: Auth;
    failed: boolean;
    loading: boolean;
    status: LoginStatus;
}

export enum LoginStatus {
    Initial,

    Start,
    Success,
    Failed,

    LookUpInitial,
    LookUpSuccess,

    RequestingSMSCode,
    RequestSMSCodeSuccess,
    RequestSMSCodeFailed,

    Error
}
