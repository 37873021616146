import { Reducer } from 'redux';
import {
    CustomerAction,
    CUSTOMER_RESET,
    CUSTOMER_ERROR
} from 'actions/customer/CustomerActionsTypes';
import { CustomerState, CustomerStatus } from './CustomerState';

const initialState: CustomerState = {
    status: CustomerStatus.Initial,
    loading: false
};

export const customerReducer: Reducer<CustomerState, CustomerAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case CUSTOMER_RESET:
            return initialState;
        case CUSTOMER_ERROR:
            return {
                ...state,
                loading: false,
                status: CustomerStatus.Error
            };
        default:
            return state;
    }
};

export default customerReducer;
