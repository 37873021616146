import { Staff } from 'model';
import { Action } from 'redux';

//Types
export const STAFFS_RESET_ACTION_TYPE = 'staffs/reset';
export const STAFFS_FETCH_ACTION_TYPE = 'staffs/fetch_start';
export const STAFFS_FETCH_SUCCESS_ACTION_TYPE = 'staffs/fetch_success';
export const STAFFS_SET_ACTION_TYPE = 'staffs/set';
export const STAFFS_SAVE_ACTION_TYPE = 'staffs/save_start';
export const STAFFS_SAVE_SUCCESS_ACTION_TYPE = 'staffs/save_success';
export const STAFFS_ERROR_ACTION_TYPE = 'staffs/error';

//Staffs
interface StaffsResetAction extends Action<typeof STAFFS_RESET_ACTION_TYPE> {}

interface StaffsFetchAction extends Action<typeof STAFFS_FETCH_ACTION_TYPE> {}
interface StaffsFetchSuccessAction extends Action<typeof STAFFS_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    staffs: Array<Staff>;
  };
}

interface StaffsSetAction extends Action<typeof STAFFS_SET_ACTION_TYPE> {
  payload: {
    staffs: Array<Staff>;
  };
}

interface StaffsSaveAction extends Action<typeof STAFFS_SAVE_ACTION_TYPE> {}
interface StaffsSaveSuccessAction extends Action<typeof STAFFS_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    staffs: Array<Staff>;
  };
}

interface StaffsErrorAction extends Action<typeof STAFFS_ERROR_ACTION_TYPE> {}

export type StaffsAction =
  | StaffsResetAction
  | StaffsFetchAction
  | StaffsFetchSuccessAction
  | StaffsSetAction
  | StaffsSaveAction
  | StaffsSaveSuccessAction
  | StaffsErrorAction;
