import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid } from '@mui/material';
import { Heading } from 'components/Commons';
import { IntakeFormLayout } from 'components/IntakeForm/Layout/Layout';
import PetSelect from 'components/Pet/PetSelect/PetSelect';
import { PrimaryButton } from 'components/UI/Buttons';
import { ProgressBar } from 'model';
import { IntakeForm } from 'model/IntakeForm';
import React from 'react';

interface IntakeFormPetListProps {
    progress: ProgressBar;
    intakeForm: IntakeForm;
    //
    onBack: () => void;
    onAddPet: () => void;
    onChange: (booking: IntakeForm) => void;
    onContinue: () => void;
}

export const IntakeFormPetList: React.FC<IntakeFormPetListProps> = props => {
    const [isDisabled, setIsDisabled] = React.useState(false);

    return (
        <IntakeFormLayout
            showBack
            onBack={props.onBack}
            progress={props.progress}
            footer={
                <PrimaryButton label="Submit" disabled={isDisabled} onClick={props.onContinue} />
            }
        >
            <Grid item xs={12} sx={{ paddingBottom: 4 }}>
                <Heading title={<small>Pets added</small>} />

                {props.intakeForm.pets.map((pet, i) => (
                    <PetSelect
                        key={i}
                        pet={pet}
                        hideCheck
                        petIndex={i}
                        services={[]}
                        onEdit={() => setIsDisabled(true)}
                        onSubmitPet={() => setIsDisabled(false)}
                        onUncheck={() => {}}
                        onChangeFn={() => {}}
                    />
                ))}

                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                'marginTop': 2,
                                'fontSize': 15,
                                'fontWeight': 500,
                                'cursor': 'pointer',
                                'display': 'inline-block',
                                'span': { textDecoration: 'underline' },
                                'svg': { marginRight: 0.7 },
                                '&:hover span': { textDecoration: 'none' }
                            }}
                            id="add-new-pet"
                            onClick={props.onAddPet}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            {'  '}
                            <span>Add new pet</span>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </IntakeFormLayout>
    );
};
