import { SignUpState, SignUpStatus } from "./SignUpState";
import {
  SIGN_UP_RESET_ACTION_TYPE,
  SIGN_UP_START_ACTION_TYPE,
  SIGN_UP_SUCCESS_ACTION_TYPE,
  SIGN_UP_FAILED_ACTION_TYPE,
  SIGN_UP_ERROR_ACTION_TYPE,
  SignUpAction,
} from "actions/signUp/SignUpActionsTypes";
import { Reducer } from "redux";

const initialState: SignUpState = {
  status: SignUpStatus.Initial,
  loading: false,
};

export const signUpReducer: Reducer<SignUpState, SignUpAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SIGN_UP_RESET_ACTION_TYPE:
      return { ...initialState };
    case SIGN_UP_START_ACTION_TYPE:
      return {
        ...state,
        status: SignUpStatus.SigningUp,
        loading: true,
      };
    case SIGN_UP_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: SignUpStatus.Success,
        loading: false,
      };
    case SIGN_UP_FAILED_ACTION_TYPE:
      return {
        ...state,
        status: SignUpStatus.Failed,
        loading: false,
      };
    case SIGN_UP_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: SignUpStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default signUpReducer;
