import { CustomerHeader } from "model";
import { Action } from "redux";

//Types
export const UPDATE_BACK_BUTTON = "UPDATE_BACK_BUTTON";
export const UPDATE_USER_BADGE = "UPDATE_USER_BADGE";
export const RESET_HEADER = "RESET_HEADER";

//Header
interface HeaderUpdateBackButtonAction
  extends Action<typeof UPDATE_BACK_BUTTON> {
  payload: {
    url: string;
    show: boolean;
  };
}
interface HeaderUpdateUserBadgeAction extends Action<typeof UPDATE_USER_BADGE> {
  payload: CustomerHeader;
}
interface HeaderResetAction extends Action<typeof RESET_HEADER> { }

//HeaderAction
export type HeaderAction =
  | HeaderUpdateBackButtonAction
  | HeaderUpdateUserBadgeAction
  | HeaderResetAction;
