export interface CustomerState {
    loading: boolean;
    status: CustomerStatus;
};

export enum CustomerStatus {
    Initial,

    CreateStart,
    CreateSuccess,

    UpdateStart,
    UpdateSuccess,

    Error,
};
