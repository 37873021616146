import { RootState } from "store";
import { useSelector } from "react-redux";
import { MasterData } from "model/MasterData";

export const useMasterData = (): MasterData => {
  const masterData = useSelector<RootState, MasterData>(state => state.masterdata.data);

  return masterData;
};

export default useMasterData;