import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';

import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTrash } from '@fortawesome/pro-regular-svg-icons';

import './PetImage.scss';

interface onChangeFn {
    (file: File | undefined, src?: string | null | undefined): void;
}

interface PetImageProps {
    value?: string | null;
    label: string;
    name: string;
    error?: string;
    //
    onChangeFn: onChangeFn;
}

const PetImage: React.FunctionComponent<PetImageProps> = props => {
    const fieldRef = useRef<HTMLInputElement>(null);
    const isFirstRun = useRef(true);

    // State
    const [fileImage, setFileImage] = useState<File>();
    const [preview, setPreview] = useState<null | string>(props.value ? props.value : null);
    const [editMenu, setEditMenu] = useState<boolean>(false);
    const [errormax, setErrormax] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);

    const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (!files || (files && files.length === 0)) {
            setPreview(null);
            return false;
        }

        const sizeInMB = files[0].size / 1000000;
        const url = URL.createObjectURL(files[0]);

        if (sizeInMB > 2.5) {
            setErrormax(true);
            return;
        }

        setFileImage(files[0]);
        setErrormax(false);
        setPreview(url);
    };

    const resetField = () => {
        setEditMenu(false);
        setPreview(null);

        if (!fieldRef.current) return;
        fieldRef.current.value = '';
    };

    const editField = () => {
        setEditMenu(false);

        if (!fieldRef.current) return;

        fieldRef.current.click();
    };

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        props.onChangeFn(fileImage, preview);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preview]);

    return (
        <Box
            className="ImageField"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Box sx={{ position: 'relative' }}>
                <label htmlFor={`ImageField-${props.name}`}>
                    {!preview && (
                        <Box className="ImageField-icon">
                            <FontAwesomeIcon icon={faCamera} />
                        </Box>
                    )}
                </label>

                {preview && (
                    <Box
                        className="ImageField-edit-preview"
                        onClick={() => {
                            setEditMenu(!editMenu);
                        }}
                    >
                        <img src={preview} alt="" />

                        {hovered && <Box className="ImageField-edit">Edit</Box>}
                    </Box>
                )}

                {editMenu && (
                    <Box className="ImageField-edit-box">
                        <Box className="ImageField-edit-flex">
                            <p onClick={editField}>
                                <FontAwesomeIcon icon={faImage} /> Edit
                            </p>
                            <p onClick={resetField}>
                                <FontAwesomeIcon icon={faTrash} /> Remove
                            </p>
                        </Box>
                    </Box>
                )}
            </Box>

            <Box sx={{ ml: 2 }}>
                <p className="ImageField-label">{props.label}</p>
                <p className="ImageField-meta">Max size: 2.5MB</p>
                {errormax && <p className="ImageField-error">This file is too large.</p>}
                {props.error && !errormax && <p className="ImageField-error">{props.error}</p>}
            </Box>

            <input
                type="file"
                ref={fieldRef}
                name={props.name}
                id={`ImageField-${props.name}`}
                accept="image/*"
                onChange={onFieldChange}
            />
        </Box>
    );
};

export default PetImage;
