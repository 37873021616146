import React from 'react';
import { Box, Grid } from '@mui/material';
import {
    //BlackButton,
    BookingLayout,
    FullWidthImage
} from 'components';
import { hero2, iconCheck } from 'assets/images';
import { EditBooking } from 'model/Booking';
import { MarketplaceState } from 'reducers/marketplace/MarketplaceState';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from 'utils';
import dogHighFive from 'assets/images/dog-high-five.png';
import { createStyles, makeStyles } from '@material-ui/core';
import PoweredByCuddles from 'components/UI/Common/PoweredByCuddles/PoweredByCuddles';

interface BookingSuccessProps {
    booking: EditBooking;
    //
    onContinue: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        dogHighFive: {
            'right': 0,
            'bottom': 0,
            'zIndex': 10,
            'height': 315,
            'position': 'fixed',

            '@media (min-width: 900px)': {
                display: 'none'
            },

            '@media (max-height: 750px)': {
                display: 'none'
            }
        },
        bookingSuccessPoweredByCuddlesBox: {
            '@media (min-width: 900px)': {
                position: 'relative'
            },

            '@media (max-height: 750px)': {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                margin: '0 auto',
                textAlign: 'center'
            }
        }
    })
);

export const BookingSuccess: React.FunctionComponent<BookingSuccessProps> = () => {
    const classes = useStyles();

    const { marketplace } = useSelector<RootState, MarketplaceState>(state => state.marketplace);

    return (
        <BookingLayout
            sx={{
                '.BookingLayout-left-column': {
                    display: 'flex'
                }
            }}
            rightColumnMobile={false}
            leftColumn={
                <Grid item xs={12}>
                    <Box
                        sx={{
                            'textAlign': 'center',
                            ...(marketplace.bookingRequestsEnabled
                                ? {
                                      maxWidth: { xs: '100%', md: 550 }
                                  }
                                : {
                                      maxWidth: { xs: '100%', md: 435 },
                                      minHeight: { xs: '70vh', md: 'auto' }
                                  }),
                            'mx': 'auto',
                            'px': { xs: 1, md: 0 },
                            'display': 'flex',
                            'flexDirection': 'column',
                            'justifyContent': 'center',
                            'h1': { mt: 0, mb: 0 },
                            'p b:last-child': { display: { xs: 'block', md: 'inline' } }
                        }}
                    >
                        <Box sx={{ img: { width: 64, mb: 1 } }}>
                            <img src={iconCheck} alt="Check icon" />
                        </Box>

                        <h1 id="bookingSuccess_label">
                            {marketplace.bookingRequestsEnabled
                                ? 'Appointment requested!'
                                : 'Appointment booked!'}
                        </h1>

                        {marketplace.bookingRequestsEnabled && (
                            <p>
                                Your appointment request is currently pending. We&apos;ll notify you
                                once it&apos;s accepted. If you have any questions contact{' '}
                                <b>{marketplace.name}</b> at{' '}
                                <b>
                                    {marketplace.phone ? formatPhoneNumber(marketplace.phone) : '-'}
                                </b>
                            </p>
                        )}

                        {!marketplace.bookingRequestsEnabled && (
                            <p>
                                Check your inbox for an appointment confirmation. If you have any
                                questions contact <b>{marketplace.name}</b> at{' '}
                                <b>
                                    {marketplace.phone ? formatPhoneNumber(marketplace.phone) : '-'}
                                </b>
                            </p>
                        )}

                        {marketplace.bookingRequestsEnabled && (
                            <img
                                src={dogHighFive}
                                alt="Dog high five"
                                className={classes.dogHighFive}
                            />
                        )}

                        <Box className={classes.bookingSuccessPoweredByCuddlesBox}>
                            <PoweredByCuddles showOnDesktop={false} showOnMobile={true} />
                        </Box>
                    </Box>
                </Grid>
            }
            rightColumn={<FullWidthImage src={hero2} />}
        />
    );
};

export default BookingSuccess;
