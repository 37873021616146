import { Service } from "model";

export interface ServicesState {
  status: ServiceStatus;
  loading: boolean;
  services: Array<Service>;
}

export enum ServiceStatus {
  Initial,

  Fetching,
  FetchSuccess,

  Set,

  Saving,
  SaveSuccess,
  Cleared,
  Error,
}
