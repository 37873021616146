export const PET_TYPE = { DOG: 'dogs', CAT: 'cats', EXOTIC: 'exotics' };
export const AMPLITUDE = {
  CTA_EDIT: 'CTA-Edit',
  CTA_SAVE_PET_INFO: 'CTA-Save-Pet-Info',
  LOAD_ADD_SERVICE: 'Load-Add-Service',
  LOAD_HOME: 'Load-Home',
  LOAD_PAYMENT_INFO: 'Load-Payment-Info',
  CTA_PAY: 'CTA-Pay',
  LOAD_APPOINTMENT_BOOKED: 'Load-Appointment-Booked',
  CTA_CONTINUE_PET_INFO_SERVICE: 'CTA-Continue-Pet-Info-Service',
  LOAD_SELECT_DATE_AND_TIME: 'Load-Select-Date-And-Time',
  CTA_CONTINUE_SELECT_DATE_AND_TIME: 'CTA-Continue-Select-Date-And-Time',
  LOAD_PERSONAL_INFO: 'Load-Personal-Info',
  CTA_CONTINUE_PERSONAL_INFO: 'CTA-Continue-Personal-Info',
  CTA_BACK_SELECT_PET: 'CTA-Back-Select-Pet',
  CTA_BACK_SELECT_SERVICE: 'CTA-Back-Select-Service',
  CTA_BACK_SELECT_DATE_AND_TIME: 'CTA-Back-Select-Date-And-Time',
  CTA_BACK_PERSONAL_INFO: 'CTA-Back-Personal-Info',
  CTA_UPDATE_PET_INFO: 'CTA-Update-Pet-Info',
  CTA_NEXT_WEEK: 'CTA-Next-Week',
  CTA_PREVIOUS_WEEK: 'CTA-Previous-Week',
};
