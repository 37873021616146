import { ThunkAction } from 'redux-thunk';
import {
    CustomerAction,
    CUSTOMER_ERROR,
    CUSTOMER_LOADING,
    CUSTOMER_RESET
} from './CustomerActionsTypes';
import { CustomerState } from 'reducers/customer/CustomerState';
import ApiClient from 'api';
import { Customer, CustomerAddress, EmergencyContact, NewCustomer, Pet } from 'model';
import axios, { AxiosResponse } from 'axios';
import store from 'store';
import { CustomerAddressDto, CustomerDto, EmergencyContactDto } from './CustomerDtos';
import ApiPublicClient from 'api/ApiPublicClient';
import { convertCustomer } from './CustomerConverter';
import { PetDataDtos, PetSaveDataDtos } from 'actions/pet/PetDataDtos';
import { convertToSavePetDto } from 'actions/pet/PetDataConverter';
import { loginSuccess } from 'actions/login/LoginActions';
import { LoginAction } from 'actions/login/LoginActionsTypes';
import { v4 as uuid } from 'uuid';
import { showErrorAlert } from 'actions/alerts/AlertsActions';
import { AlertsAction } from 'actions/alerts/AlertsActionsTypes';
import { BookingStep } from 'components/Booking/Booking';
import { serialize } from 'object-to-formdata';

const customersUrl = 'customers';
const lookupUrl = 'customer_lookup';
const lookupTokenUrl = 'customer_lookup_by_token';

export const resetThunk = (): ThunkAction<void, CustomerState, null, CustomerAction> => {
    return async dispatch => {
        dispatch(reset());
    };
};

export const findOrCreateCustomerWithPetsThunk = (
    customer: NewCustomer,
    pets: Array<Pet>
): ThunkAction<void, CustomerState, null, CustomerAction | LoginAction | AlertsAction> => {
    return async dispatch => {
        try {
            dispatch(loading(true));

            const marketplaceId = store.getState().marketplace.marketplace.id;
            const randPassword = (Math.random() + 1).toString(36).substring(2);

            const lookup = await dispatch(lookupCustomerThunk(customer.email, marketplaceId));
            const customerExists = lookup?.id;

            const apiMethod = lookup?.id ? ApiClient.patch : ApiClient.post;
            const url = lookup?.id ? `${customersUrl}/${lookup.id}` : customersUrl;

            const petsAttributes = pets.reduce((arr: PetSaveDataDtos[], pet: Pet) => {
                const petExists = lookup
                    ? lookup.pets?.find(
                          (p: PetDataDtos) =>
                              p.name.toLocaleLowerCase().trim() ===
                              pet.name.toLocaleLowerCase().trim()
                      )
                    : false;

                // If pet doesn't exist, add to array
                if (!petExists) {
                    arr.push(convertToSavePetDto(pet, marketplaceId!));
                }

                // If pet exists and it has an image to upload, add to array
                if (petExists && pet.imageFileToUpload) {
                    arr.push(
                        convertToSavePetDto(
                            {
                                ...pet,
                                id: petExists.id
                            },
                            marketplaceId!
                        )
                    );
                }

                return arr;
            }, []);

            const customerData = {
                customer: {
                    first_name: customer.first_name,
                    last_name: customer.last_name,
                    phone: customer.phone.replace(/\D+/g, ''),
                    channel: customer.channel || 'online-booking',

                    ...(!customerExists && {
                        email: customer.email,
                        password: randPassword,
                        password_confirmation: randPassword,
                        marketplace_id: marketplaceId,
                        regenerate_password: false,
                        terms_of_service: true,
                        phone_optout: false,
                        email_optout: false,
                        active: true
                    }),

                    ...(petsAttributes.length > 0 && {
                        pets_attributes: petsAttributes
                    }),

                    ...(customer.addresses && {
                        addresses_attributes: customer.addresses?.reduce(
                            (arr: Array<CustomerAddressDto>, address: CustomerAddress) => {
                                arr.push({
                                    marketplace_id: marketplaceId!,
                                    address_line_one: address.addressLineOne,
                                    address_line_two: address.addressLineTwo,
                                    city: address.city,
                                    state: address.state,
                                    zipcode: address.zipCode,
                                    country: address.country,
                                    active: address.active
                                });

                                return arr;
                            },
                            []
                        )
                    }),

                    ...(customer.emergencyContacts && {
                        authorized_contacts_attributes: customer.emergencyContacts?.reduce(
                            (arr: Array<EmergencyContactDto>, contact: EmergencyContact) => {
                                const contactExists = arr.findIndex(
                                    existing =>
                                        existing.first_name.toLocaleLowerCase() ===
                                            contact.first_name.toLocaleLowerCase() &&
                                        existing.last_name.toLocaleLowerCase() ===
                                            contact.last_name.toLocaleLowerCase()
                                );

                                if (contactExists >= 0) {
                                    arr[contactExists].phone = contact.phone;
                                    arr[contactExists].relationship_id = contact.relationship.id;
                                    arr[contactExists].emergency_contact = true;
                                } else {
                                    arr.push({
                                        uuid: uuid(),
                                        first_name: contact.first_name,
                                        last_name: contact.last_name,
                                        phone: contact.phone,
                                        relationship_id: contact.relationship.id,
                                        customer_id: customerExists!,
                                        emergency_contact: true
                                    });
                                }

                                return arr;
                            },
                            lookup?.authorized_contacts ?? []
                        )
                    })
                }
            };

            const formDataOptions = {
                indices: false,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                noFilesWithArrayNotation: true,
                allowEmptyArrays: false
            };

            let formData = new FormData();

            formData = serialize(customerData, formDataOptions);

            const { data: customerResponse }: AxiosResponse<CustomerDto> = await apiMethod(
                url,
                formData
            );

            dispatch(
                loginSuccess({
                    signedIn: false,
                    token: customerResponse.token,
                    customer: convertCustomer(customerResponse)
                })
            );

            return convertCustomer(customerResponse);
        } catch (err) {
            let errorMessage = 'Oops, something went wrong.';

            if (axios.isAxiosError(err)) {
                const phoneError = err.response?.data.phone;

                if (
                    phoneError &&
                    (phoneError as Array<string>).includes('has already been taken')
                ) {
                    errorMessage =
                        'The phone number you entered is associated with an existing client.';
                } else {
                    errorMessage = 'An error ocurred, please try again.';
                }
            }

            dispatch(error(BookingStep.CustomerForm));
            dispatch(showErrorAlert(errorMessage, BookingStep.CustomerForm));
            dispatch(loading(false));

            return null;
        }
    };
};

export const lookupCustomerThunk = (
    email: string,
    marketplaceId?: number
): ThunkAction<Promise<CustomerDto | null>, unknown, null, CustomerAction> => {
    return async () => {
        try {
            const url = marketplaceId ? `${lookupUrl}?marketplace_id=${marketplaceId}` : lookupUrl;

            const { data }: AxiosResponse<CustomerDto> = await ApiPublicClient.post(url, {
                email,
                marketplace_id: marketplaceId
            });

            return data;
        } catch (error) {
            return null;
        }
    };
};

export const lookupCustomerByTokenThunk = (
    token: string,
    marketplaceId?: number
): ThunkAction<Promise<CustomerDto>, unknown, null, CustomerAction> => {
    return async () => {
        const url = `${lookupTokenUrl}?marketplace_id=${marketplaceId}`;

        const { data }: AxiosResponse<CustomerDto> = await ApiPublicClient.post(url, {
            token,
            marketplace_id: marketplaceId
        });

        return data;
    };
};

export const updateCustomerThunk = (
    customer: Customer,
    pets?: Array<Pet>
): ThunkAction<void, unknown, null, CustomerAction> => {
    return async () => {
        try {
            const marketplaceId = store.getState().marketplace.marketplace.id;

            const petsAttributes = pets?.reduce((arr: PetSaveDataDtos[], pet: Pet) => {
                const petExists = customer
                    ? customer.pets?.find(
                          p =>
                              p.name.toLocaleLowerCase().trim() ===
                              pet.name.toLocaleLowerCase().trim()
                      )
                    : false;

                if (!petExists) {
                    arr.push(convertToSavePetDto(pet, marketplaceId!));
                }

                return arr;
            }, []);

            const customerData = {
                customer: {
                    ...(petsAttributes &&
                        petsAttributes.length > 0 && {
                            pets_attributes: petsAttributes
                        })
                }
            };

            const formDataOptions = {
                indices: false,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                allowEmptyArrays: false
            };

            const formData = serialize(customerData, formDataOptions);

            const response = await ApiClient.patch<CustomerDto>(
                `${customersUrl}/${customer.id}`,
                formData
            );

            return convertCustomer(response.data);
        } catch (error) {
            console.log(error);
        }
    };
};

export const reset = (): CustomerAction => {
    return {
        type: CUSTOMER_RESET
    };
};

const loading = (loading: boolean): CustomerAction => {
    return {
        type: CUSTOMER_LOADING,
        loading
    };
};

const error = (step?: BookingStep): CustomerAction => {
    return {
        type: CUSTOMER_ERROR,
        step
    };
};
