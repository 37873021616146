import React from 'react';
import './WideAnchor.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';

interface WideAnchorProps {
    id?: string;
    to?: string | object;
    title: string;
    description?: string;
    onClick?: () => void;
}

const WideAnchor: React.FunctionComponent<WideAnchorProps> = props => {
    const linkContent = (
        <>
            <span className="WideAnchor-title">{props.title}</span>
            {props.description && (
                <span className="WideAnchor-description">{props.description}</span>
            )}
            <FontAwesomeIcon icon={faAngleRight} className="WideAnchor-arrow" />
        </>
    );

    const clickHandler = () => props.onClick && props.onClick();

    return (
        <>
            {props.to ? (
                <Link id={props.id} to={props.to} className="WideAnchor" onClick={clickHandler}>
                    {linkContent}
                </Link>
            ) : (
                <span id={props.id} className="WideAnchor" onClick={clickHandler}>
                    {linkContent}
                </span>
            )}
        </>
    );
};

export default WideAnchor;
