import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pet } from 'model';
import React from 'react';
import { getPetIcon } from 'utils';
import './PetIcon.styles.scss';
import { isNumber } from 'lodash';

interface PetIconProps {
  pet: Pet;
  size?: number | string;
  variant?: 'dark' | 'light';
}

const PetIcon: React.FC<PetIconProps> = ({ pet, size = 32, variant = 'dark' }) => {
  const styles = {
    '--pet-icon-size': isNumber(size) ? `${size}px` : size,
  } as React.CSSProperties;

  return (
    <div className='PetIcon' data-pet-type={pet.type.id} data-variant={variant} style={styles}>
      <FontAwesomeIcon icon={getPetIcon(pet.type.id)} />
    </div>
  );
};

export default PetIcon;
