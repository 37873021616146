import React, { useEffect, useState } from 'react';
import { Radio } from '@mui/material';
import { Box } from '@mui/material';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PetForm } from 'components/Pet/PetForm';
import { Pet, Service, FixedPriceOption, VariablePriceOption } from 'model';
import './PetSelect.scss';
import { capitalize } from 'lodash';
import { getBreedName, getPetIcon } from 'utils';
import { Option } from 'model';
import { EditBooking } from 'model/Booking';
import store from 'store';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import { filterServicesForPet } from 'utils/servicesUtils';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
interface PetSelectProps {
    pet: Pet;
    service?: Service;
    petIndex: number;
    selected?: boolean;
    hideCheck?: boolean;
    services: Array<Service>;
    booking?: EditBooking;
    //
    onEdit: () => void;
    onSubmitPet: (pet: Pet) => void;
    onUncheck(petId: string | undefined): void;
    onChangeFn(service: { pet: Pet; service?: Service }): void;
}

export const PetSelect: React.FunctionComponent<PetSelectProps> = props => {
    const [edit, setEdit] = useState<boolean>(false);
    const [optionServices, setOptionServices] = useState<Array<Option<string>>>();
    const [petEdited, setPetEdited] = useState<Pet>(props.pet);
    const [petIcon, setPetIcon] = useState<IconProp>();
    const [petServiceId, setPetServiceId] = useState<string>();
    const [petService, setPetService] = useState<Option<string>>();
    const [serviceState, setServiceState] = useState<Service>();

    useEffect(() => {
        setPetEdited(props.pet);

        if (!props.pet.image) {
            setPetIcon(getPetIcon(props.pet.type.id));
        }

        loadOptionServices(props.pet);
        checkServiceLoad();
        // eslint-disable-next-line
    }, [props.pet]);

    useEffect(() => {
        if (props.service === undefined) {
            setPetService(props.service);
        }
    }, [props.service]);

    useEffect(() => {
        if (petServiceId) {
            const s = props.services?.find(service => service.id.toString() === petServiceId);
            const price = searchForServicePetPrice(s!, petEdited);

            const newService: Service = {
                id: s!.id,
                name: s!.name,
                description: s!.description,
                fixedPrice: s!.fixedPrice,
                variablePrice: s!.variablePrice,
                price: price.toString(),
                petList: s!.petList,
                variableDuration: s!.variableDuration,
                durationHours: s!.durationHours,
                durationMinutes: s!.durationMinutes,
                staffIds: s!.staffIds
            };

            setServiceState(newService);
            props.onChangeFn({ pet: petEdited, service: newService });
        }
        // eslint-disable-next-line
    }, [petServiceId]);

    const checkServiceLoad = () => {
        const check = props.booking?.petsToBooking.find(pet => pet.pet.id === props.pet.id);

        if (check && check.service) {
            setPetServiceId(check.service?.id.toString());
            setPetService({ id: check.service!.id.toString(), name: check.service!.name });
        }
    };

    const loadOptionServices = (pet: Pet) => {
        const services = filterServicesForPet(props.services, pet);

        setOptionServices(
            services
                .map(service => ({
                    id: service.id.toString(),
                    name: service.name
                }))
                .sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                })
        );
    };

    const searchForServicePetPrice = (service: Service, pet: Pet) => {
        let priceResult = 0;

        if (pet.type) {
            if (service.fixedPrice.length > 0) {
                let prices: Array<FixedPriceOption> = [];
                prices = service.fixedPrice.filter(
                    (p: FixedPriceOption) => p.petType && p.petType.singular === pet.type.singular
                );
                prices?.length > 0 ? (priceResult = prices[0]!.price!) : (priceResult = 0);
            }

            if (service.fixedPrice.length === 0) {
                let arrayPrice: Array<VariablePriceOption> = [];

                if (service.variablePrice?.prices !== undefined) {
                    arrayPrice = service.variablePrice?.prices;

                    // filter price by hair length
                    /*
          if (pet.petHairLength !== undefined) {
            if (service.variablePrice!.variables.hairLengthVariable) {
              arrayPrice = arrayPrice?.filter((price: VariablePriceOption) => price.hairLength?.id === petHairLength)
            }
          }
          */

                    // filter price by hair
                    /*
          if (service.variablePrice!.variables.hairTypeVariable) {
            arrayPrice = arrayPrice?.filter((price: VariablePriceOption) => price.hairType?.name === petHair)
          }
          */

                    // filter price by pet size
                    if (service.variablePrice!.variables.petSizeVariable) {
                        arrayPrice = arrayPrice?.filter(
                            (price: VariablePriceOption) => price.petSize?.id === pet.size
                        );
                    }

                    // filter price by pet type
                    if (service.variablePrice!.variables.petTypeVariable) {
                        arrayPrice = arrayPrice?.filter(
                            (price: VariablePriceOption) =>
                                price.petType?.singular === pet.type.singular
                        );
                    }
                }

                arrayPrice?.length > 0 ? (priceResult = arrayPrice[0]!.price!) : (priceResult = 0);
            }
        }

        if (!pet.type.id) {
            priceResult = 0;
        }

        return priceResult;
    };

    const onPetUpdate = (pet: Pet) => {
        amplitude.track(AMPLITUDE.CTA_UPDATE_PET_INFO, {
            marketplace: store.getState().marketplace.marketplace.name
        });
        props.onSubmitPet(pet);
        setPetEdited(pet);
        setPetIcon(getPetIcon(pet.type.id));
        loadOptionServices(pet);
        setPetService({ id: '', name: '' });
        setPetServiceId('');
        setEdit(false);
    };

    const onEdit = () => {
        amplitude.track(AMPLITUDE.CTA_EDIT, {
            marketplace: store.getState().marketplace.marketplace.name
        });
        setEdit(true);
        props.onEdit();
    };

    const unCheck = () => {
        props.onUncheck(petEdited.uuid);
    };

    const showBreedName = () => {
        return getBreedName(petEdited.type.id, petEdited.breed);
    };

    //

    const editPetView = (
        <Box sx={{ mt: 3 }}>
            <PetForm
                pet={petEdited}
                update
                showRemove={true}
                //
                onUpdate={onPetUpdate}
            />
        </Box>
    );

    //{petBreedName}{" "} - {capitalize(petEdited.size)}
    const selectPetView = (
        <Box className={`PetSelect PetSelect-${petEdited.type.id}`}>
            {!props.hideCheck && (
                <Radio
                    onClick={() =>
                        props.selected
                            ? unCheck()
                            : props.onChangeFn({
                                  pet: petEdited,
                                  service: serviceState
                              })
                    }
                    checked={props.selected}
                    checkedIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                />
            )}

            <Box className="PetSelect-image">
                {petEdited.image && <img src={petEdited.image} alt="pet" />}
                {!petEdited.image && petIcon && <FontAwesomeIcon icon={petIcon} />}
            </Box>
            <Box className="PetSelect-info">
                <p className="PetSelect-info-name">
                    {petEdited.name}
                    <span className={`PetSelect-badge PetSelect-badge-${petEdited.type.id}`}>
                        {petEdited.type.singular}
                    </span>
                </p>
                <p className="PetSelect-info-description">
                    {showBreedName()} - {capitalize(petEdited.size)}
                </p>
            </Box>
            <Box className="PetSelect-edit">
                <p onClick={onEdit}>
                    <FontAwesomeIcon icon={faPen} />
                    <span>Edit</span>
                </p>
            </Box>
        </Box>
    );

    return (
        <Box className="PetSelect-wrapper" data-edit={edit} data-hide-check={props.hideCheck}>
            {!edit && selectPetView}
            {edit && editPetView}
        </Box>
    );
};

export default PetSelect;
