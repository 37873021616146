import { Action } from "redux";

//Types
export const FORGOT_PASSWORD_RESET_ACTION_TYPE = "FORGOT_PASSWORD_RESET";
export const FORGOT_PASSWORD_START_ACTION_TYPE = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_END_ACTION_TYPE = "FORGOT_PASSWORD_END";
export const FORGOT_PASSWORD_FAIL_ACTION_TYPE = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_SENT_ACTION_TYPE = "FORGOT_PASSWORD_SENT";

//Actions
interface ForgotPasswordResetAction
  extends Action<typeof FORGOT_PASSWORD_RESET_ACTION_TYPE> {}

interface ForgotPasswordStartAction
  extends Action<typeof FORGOT_PASSWORD_START_ACTION_TYPE> {
    payload: {
      email: string;
    }
  }


interface ForgotPasswordEndAction
  extends Action<typeof FORGOT_PASSWORD_END_ACTION_TYPE> {}

interface ForgotPasswordFailAction
  extends Action<typeof FORGOT_PASSWORD_FAIL_ACTION_TYPE> {
    payload: boolean,
  }

interface ForgotPasswordSentAction
  extends Action<typeof FORGOT_PASSWORD_SENT_ACTION_TYPE> {
    payload: boolean
  }

export type ForgotPasswordAction =
  ForgotPasswordResetAction
  | ForgotPasswordStartAction
  | ForgotPasswordEndAction
  | ForgotPasswordFailAction
  | ForgotPasswordSentAction;
