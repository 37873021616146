import { Service, FixedPriceOption, VariablePrice, VariableDuration } from 'model';
import {
    ServiceDto,
    VariablePriceDto,
    FixedPriceOptionDto,
    VariableDurationDto
} from './ServicesDtos';
import MasterData from 'model/MasterData';
import store from 'store';

export const convertToService = (serviceDto: ServiceDto): Service => {
    return {
        id: serviceDto.id,
        name: serviceDto.name,
        description: serviceDto.description,
        fixedPrice: convertFixedPrice(serviceDto),
        variablePrice: convertVariablePrice(serviceDto),
        variableDuration: convertVariableDuration(serviceDto),
        petList: serviceDto.pet_list,
        durationHours: serviceDto.duration_hours,
        durationMinutes: serviceDto.duration_minutes,
        staffIds: serviceDto.staff.map(item => item.id)
    };
};

export declare enum PetTypeIds {
    DOGS = 'dogs',
    CATS = 'cats',
    EXOTICS = 'exotics'
}

const convertFixedPrice = (serviceDto: ServiceDto): Array<FixedPriceOption> => {
    const masterData: MasterData = store.getState().masterdata.data;
    if (serviceDto.fixed_price === null) {
        return [];
    } else {
        const responseFixedPrice: Array<FixedPriceOptionDto> = JSON.parse(serviceDto.fixed_price);

        return responseFixedPrice.map(fp => ({
            petType: masterData.petTypes.find(pt => pt.id === fp.pet_type_id)!,
            price: fp.price
        }));
    }
};

const convertVariablePrice = (serviceDto: ServiceDto): VariablePrice | undefined => {
    const masterData = store.getState().masterdata.data;
    const hairLengths = store.getState().pets.hairLengths;

    if (serviceDto.variable_price === null) {
        return undefined;
    }

    if (serviceDto.variable_price !== null) {
        const responseVariablePrice: VariablePriceDto = JSON.parse(serviceDto.variable_price);

        return {
            variables: {
                petTypeVariable: responseVariablePrice.variables.pet_type_variable,
                petSizeVariable: responseVariablePrice.variables.pet_size_variable,
                hairTypeVariable: responseVariablePrice.variables.hair_type_variable,
                hairLengthVariable: responseVariablePrice.variables.hair_length_variable,
                exoticTypeVariable: responseVariablePrice.variables.exotic_type_variable
            },
            prices: responseVariablePrice.prices.map(vp => {
                const petType = masterData.petTypes.find(pt => pt.id === vp.pet_type_id);
                const petSize =
                    masterData.petSizes &&
                    masterData.petSizes
                        .map(s => s.element)
                        .find(s => s.id === vp.size_meta_variable);

                const hairLengthByPetType = hairLengths[petType?.id as keyof typeof hairLengths];

                const hairLengthOptions = hairLengthByPetType?.find(
                    hairLength => hairLength.id === vp.hair_length_meta_variable
                );

                return {
                    petType: petType,
                    price: vp.price,
                    petSize: petSize,
                    hairLength: hairLengthOptions
                };
            })
        };
    }
};

const convertVariableDuration = (serviceDto: ServiceDto): Array<VariableDuration> => {
    const masterData: MasterData = store.getState().masterdata.data;
    const petSizes = masterData.petSizes;

    return serviceDto.variable_duration
        ? JSON.parse(serviceDto.variable_duration).map((vd: VariableDurationDto) => {
              const petSize = petSizes.find(ps => ps.element.id === vd.size_meta_variable);
              return {
                  petSize: {
                      id: petSize?.element.id,
                      name: petSize?.element.name
                  },
                  duration: { hours: vd.hours, minutes: vd.minutes }
              };
          })
        : [];
};
