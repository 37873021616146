import { Agreement, AgreementSignature } from 'model';
import { Action } from 'redux';

//Types
export const AGREEMENTS_RESET = 'AGREEMENTS_RESET';
export const AGREEMENTS_FETCH_START = 'AGREEMENTS_FETCH_START';
export const AGREEMENTS_FETCH_SUCCESS = 'AGREEMENTS_FETCH_SUCCESS';

export const AGREEMENTS_GET_SIGNATURE_START = 'AGREEMENTS_GET_SIGNATURE_START';
export const AGREEMENTS_GET_SIGNATURE_SUCCESS = 'AGREEMENTS_GET_SIGNATURE_SUCCESS';

export const AGREEMENTS_SIGN_START = 'AGREEMENTS_SIGN_START';
export const AGREEMENTS_SIGN_SUCCESS = 'AGREEMENTS_SIGN_SUCCESS';

export const AGREEMENTS_ERROR = 'AGREEMENTS_ERROR';

interface AgreementsResetAction extends Action<typeof AGREEMENTS_RESET> {}
interface AgreementsErrorAction extends Action<typeof AGREEMENTS_ERROR> {}

// Fetch
interface AgreementsFetchStartAction extends Action<typeof AGREEMENTS_FETCH_START> {}
interface AgreementsFetchSuccessAction extends Action<typeof AGREEMENTS_FETCH_SUCCESS> {
    payload: {
        agreements: Array<Agreement>;
    };
}

// Get signature
interface AgreementsGetSignatureStartAction extends Action<typeof AGREEMENTS_GET_SIGNATURE_START> {}
interface AgreementsGetSignatureSuccessAction
    extends Action<typeof AGREEMENTS_GET_SIGNATURE_SUCCESS> {
    payload: {
        signature: AgreementSignature;
    };
}

// Sign
interface AgreementsSignStartAction extends Action<typeof AGREEMENTS_SIGN_START> {}
interface AgreementsSignSuccessAction extends Action<typeof AGREEMENTS_SIGN_SUCCESS> {
    payload: {};
}

export type AgreementsAction =
    | AgreementsResetAction
    | AgreementsFetchStartAction
    | AgreementsFetchSuccessAction
    | AgreementsGetSignatureStartAction
    | AgreementsGetSignatureSuccessAction
    | AgreementsSignStartAction
    | AgreementsSignSuccessAction
    | AgreementsErrorAction;
