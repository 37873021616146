import {
    PetAction,
    PET_SAVE_START,
    PET_SAVE_SUCCESS,
    PET_ERROR,
    PET_FETCH_FAILED,
    PET_FETCH_START,
    PET_FETCH_SUCCESS,
    PET_RESET,
    PET_SET_SIZES,
    PET_SET_AGES,
    PET_SET_HAIR_LENGTHS
} from './PetActionsTypes';
import { ThunkAction } from 'redux-thunk';

import { serialize } from 'object-to-formdata';

import store from 'store';
import ApiPublicClient, { createTokenConfig } from 'api/ApiPublicClient';
import { AlertsAction } from 'actions/alerts/AlertsActionsTypes';
import { showErrorAlert, showSuccessAlert } from 'actions/alerts/AlertsActions';

import { CallHistoryMethodAction } from 'connected-react-router';

import { Pet, PetAges, PetHairLengths, PetSizes } from 'model';
import { PetDataDtos } from './PetDataDtos';
import axios, { AxiosResponse } from 'axios';

import { MasterDataAction } from 'actions/masterData/MasterDataActionsTypes';

import { PetState } from 'reducers/pet/PetState';
import { convertPetData, convertPetsData, convertToSavePetDto } from './PetDataConverter';
import { RootAction } from 'actions/ActionTypes';
import { BookingStep } from 'components/Booking/Booking';

const petUrl = 'pets';

const HTTP_STATUS_401_UNAUTHORIZED = 401;

export const resetThunk = (): ThunkAction<void, PetState, null, PetAction> => {
    return async dispatch => {
        dispatch(reset());
    };
};

export const newPetThunk =
    (
        newPet: Pet
    ): ThunkAction<
        void,
        PetState,
        null,
        PetAction | AlertsAction | CallHistoryMethodAction | MasterDataAction
    > =>
    async dispatch => {
        dispatch(saveStart());

        const marketplaceId = store.getState().marketplace.marketplace.id;
        const update = newPet.id ? true : false;

        const apiMethod = update ? ApiPublicClient.patch : ApiPublicClient.post;

        const saveUrl = update
            ? `${petUrl}/${newPet.id}?marketplace_id=${marketplaceId}`
            : `${petUrl}?marketplace_id=${marketplaceId}`;

        const petRequest = convertToSavePetDto(newPet, marketplaceId!);

        let formData = new FormData();

        const options = {
            indices: false,
            nullsAsUndefineds: false,
            booleansAsIntegers: false,
            allowEmptyArrays: false
        };

        formData = serialize(petRequest, options, formData, 'pet');

        try {
            const response: AxiosResponse<PetDataDtos> = await apiMethod(
                saveUrl,
                formData,
                createTokenConfig()
            );
            dispatch(petSaveSuccess(convertPetData(response.data)));

            if (update) {
                dispatch(showSuccessAlert('Pet updated! 🎉', BookingStep.PetSelect));
            }
        } catch (apiError: unknown) {
            if (axios.isAxiosError(apiError) && apiError.response) {
                if (apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED) {
                    dispatch(failed());
                }

                if (apiError.response.status === 422) {
                    const errorResponse = apiError.response.data;
                    const errorMessages = Object.keys(errorResponse).map(
                        key => `${key}: ${errorResponse[key]}`
                    );
                    errorMessages.forEach(message => {
                        dispatch(showErrorAlert(message, BookingStep.AddPet));
                    });
                } else {
                    dispatch(showErrorAlert('Error adding new pet.', BookingStep.AddPet));
                }

                dispatch(error());
                console.error(apiError);
            }
        }
    };

export const getPetsThunk = (): ThunkAction<void, PetState, null, RootAction> => {
    return async dispatch => {
        dispatch(start());

        const marketplaceId = store.getState().marketplace.marketplace.id;
        const url = petUrl + (marketplaceId ? `?marketplace_id=${marketplaceId}` : '');

        try {
            const response = await ApiPublicClient.get(url, createTokenConfig());
            const pets = convertPetsData(response.data);
            dispatch(success(pets));
        } catch (apiError: any) {
            console.error('apiError get pet ', apiError);
            if (apiError.response && apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED) {
                dispatch(failed());
            } else {
                if (apiError.response.status === 422) {
                    const errorResponse = apiError.response.data;
                    const errorMessages = Object.keys(errorResponse).map(
                        key => `${key}: ${errorResponse[key]}`
                    );
                    errorMessages.forEach(message => {
                        dispatch(showErrorAlert(message, BookingStep.AddPet));
                    });
                } else {
                    dispatch(showErrorAlert('Error adding new pet.', BookingStep.AddPet));
                }
                dispatch(error());
                console.error(apiError);
            }
        }
    };
};

export const reset = (): PetAction => {
    return {
        type: PET_RESET
    };
};

const start = (): PetAction => {
    return {
        type: PET_FETCH_START
    };
};

const saveStart = (): PetAction => {
    return {
        type: PET_SAVE_START
    };
};

const success = (pets: Pet[]): PetAction => {
    return {
        type: PET_FETCH_SUCCESS,
        pets
    };
};

export const petSaveSuccess = (pet: Pet): PetAction => {
    return {
        type: PET_SAVE_SUCCESS,
        pet: pet
    };
};

const failed = (): PetAction => {
    return {
        type: PET_FETCH_FAILED
    };
};

const error = (): PetAction => {
    return {
        type: PET_ERROR
    };
};

export const setPetAges = (ages: PetAges): PetAction => {
    return {
        type: PET_SET_AGES,
        ages
    };
};

export const setPetSizes = (sizes: PetSizes): PetAction => {
    return {
        type: PET_SET_SIZES,
        sizes: sizes
    };
};

export const setPetHairLengths = (hairLengths: PetHairLengths): PetAction => {
    return {
        type: PET_SET_HAIR_LENGTHS,
        hairLengths: hairLengths
    };
};
