import React, { ChangeEvent } from 'react';
import './CustomTextarea.scss';
import { Box, TextField } from '@mui/material';

interface CustomTextareaProps {
    rows?: number;
    id?: string;
    placeholder?: string;
    disabled?: boolean;
    //
    onChange?: (value: string) => void;
}

const CustomTextarea: React.FunctionComponent<CustomTextareaProps> = props => {
    const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        const newValue = event.target.value;
        props.onChange && props.onChange(newValue);
    };

    return (
        <Box className="CustomTextarea">
            <TextField
                multiline
                {...props}
                id={props.id}
                disabled={props.disabled}
                onChange={changeHandler}
            />
        </Box>
    );
};

export default CustomTextarea;
