import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import './UnderlinedLink.scss'

interface UnderlinedLinkProps {
    title: string,
    boxProps?: object,
    to: object|string
}

const UnderlinedLink: React.FunctionComponent<UnderlinedLinkProps> = (props) => {
    const { boxProps, title, ...otherProps } = props

    return (
        <Box {...boxProps}>
            <Link  className="UnderlinedLink" {...otherProps}>
                { props.title }
            </Link>
        </Box>
    )
}

UnderlinedLink.defaultProps = {
    title: ''
}

export default UnderlinedLink
