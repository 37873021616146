import { Action } from "redux";
import MasterData from "../../model/MasterData";

//Types
export const MASTER_DATA_RESET_ACTION_TYPE = "MASTER_RESET_START";
export const MASTER_DATA_FETCH_START_ACTION_TYPE = "MASTER_DATA_FETCH_START";
export const MASTER_DATA_FETCH_SUCCESS_ACTION_TYPE = "MASTER_DATA_FETCH_SUCCESS";
export const MASTER_DATA_ERROR_ACTION_TYPE = "MASTER_DATA_ERROR_REGISTERED";

//Action
interface MasterDataResetAction extends Action<typeof MASTER_DATA_RESET_ACTION_TYPE> {}

interface MasterDataFetchStartAction extends Action<typeof MASTER_DATA_FETCH_START_ACTION_TYPE> {}

interface MasterDataFetchSuccessAction extends Action<typeof MASTER_DATA_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    masterData: MasterData;
  };
}

interface MasterDataErrorAction extends Action<typeof MASTER_DATA_ERROR_ACTION_TYPE> {}

export type MasterDataAction =
  | MasterDataResetAction
  | MasterDataFetchStartAction
  | MasterDataFetchSuccessAction
  | MasterDataErrorAction;
