import React from 'react';
import { Box, TextField } from '@mui/material';
import './CustomField.scss';

const CustomField = (props: any) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <TextField className="CustomField" variant="outlined" {...props} />
            {props.siblings}
        </Box>
    )
}

export default CustomField
