import useNonInitialEffect from '@versiondos/hooks';
import {
    createBookingDepositThunk,
    createBookingThunk,
    fetchBookingSiblingsThunk
} from 'actions/booking/BookingActions';
import { Header } from 'components';
import { BookingNotes } from 'components/Booking/BookingNotes';
import { BookingSelectDate } from 'components/Booking/BookingSelectDate';
import { BookingSelectPet } from 'components/Booking/BookingSelectPet';
import { BookingSuccess } from 'components/Booking/BookingSuccess';
import { Customer, Hour, MarketplaceType, ProgressBar } from 'model';
import { EditBooking, NewBooking, NewBookingAppointment } from 'model/Booking';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BookingState, BookingStatus } from 'reducers/booking/BookingState';
import store, { dispatch, RootState } from 'store';
import { formatDateTimezone } from 'utils';
import { v4 as uuid } from 'uuid';
import { Spinner } from 'components/UI/Spinner';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useAuth } from 'hooks';
import {
    findOrCreateCustomerWithPetsThunk,
    lookupCustomerThunk,
    updateCustomerThunk
} from 'actions/customer/CustomerActions';
import { BookingPayment } from './BookingPayment';
import { fetchMarketplaceBySlugThunk } from 'actions/marketplace/MarketplaceActions';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import { DateTime } from 'luxon';
import BookingDisabled from './BookingDisabled';
import { InvoicesStatus, createDepositInvoiceThunk } from '@spike/invoices-action';
import useApiClientWrapper from 'hooks/useApiClientWrapper';
import Invoice from '@spike/invoice-model';
import { removeAlertsByStep, showErrorAlert } from 'actions/alerts/AlertsActions';
import BookingSelectService from 'components/Booking/BookingSelectService';
import BookingAddPet from 'components/Booking/BookingAddPet';
import BookingSelectStaff from 'components/Booking/BookingSelectStaff';
import { useIsOLBEnabled } from 'hooks/useIsOLBEnabled';
import { ServicesState } from 'reducers/services/ServicesState';
import { MasterDataState } from 'reducers/masterData/MasterDataState';
import { StaffsState } from 'reducers/staffs/StaffsState';
import BookingHome from 'components/Booking/BookingHome';
import BookingSignin from 'components/Booking/BookingSignin/BookingSignin';
import BookingCustomer from 'components/Booking/BookingCustomer';
import { usePrevious } from 'hooks/usePrevious';
import { signAgreementThunk } from 'actions/agreements/AgreementsActions';

const useStyles = makeStyles(() =>
    createStyles({
        boxSpinner: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px'
        },
        spinner: {
            '& .MuiCircularProgress-colorPrimary': {
                color: '#eab464 !important'
            }
        }
    })
);

export enum BookingStep {
    Loading,
    Home,
    CustomerForm,
    Signin,
    AddPet,
    PetSelect,
    ServiceSelect,
    StaffSelect,
    DateSelect,
    Notes,
    Payment,
    Success
}

const bookingStepsLength = Object.keys(BookingStep).length / 2;

export const Booking: React.FunctionComponent = () => {
    const auth = useAuth();
    const classes = useStyles();

    const apiClientWrapper = useApiClientWrapper();

    const isOLBEnabled = useIsOLBEnabled();

    const marketplace = store.getState().marketplace.marketplace;

    const staffsState = useSelector<RootState, StaffsState>(state => state.staffs);
    const servicesState = useSelector<RootState, ServicesState>(state => state.services);
    const masterDataState = useSelector<RootState, MasterDataState>(state => state.masterdata);
    const invoicesStatus = useSelector<RootState, InvoicesStatus>(state => state.invoices.status);
    const stateBooking = useSelector<RootState, BookingState | undefined>(state => state.booking);
    const bookingSiblings = useSelector<RootState, Array<string>>(state => state.booking.siblings);
    const depositInvoice = useSelector<RootState, Invoice | undefined>(
        state => state.invoices.depositInvoice
    );

    const createEmptyBooking = (): EditBooking => {
        return {
            pets: [],
            hour: {} as Hour,
            date: undefined,
            staffId: undefined,
            petsToBooking: [],
            agreementsSignatures: [],
            customer: {
                blocked: false,
                first_name: '',
                last_name: '',
                email: '',
                phone: ''
            }
        };
    };

    const [editedBooking, setEditedBooking] = useState<EditBooking>(createEmptyBooking());
    const [progress, setProgress] = useState<ProgressBar>({
        show: true,
        width: 1 / (bookingStepsLength - 1)
    });

    const [showBack, setShowBack] = useState<boolean>(false);
    const [step, setStep] = useState(BookingStep.Home);

    const oldStep = usePrevious(step);

    /**
     * --------------
     *    Effects
     * --------------
     */

    useEffect(() => {
        amplitude.track(AMPLITUDE.LOAD_HOME, {
            marketplace: store.getState().marketplace.marketplace.name
        });
    }, []);

    useEffect(() => {
        if (oldStep) {
            dispatch(removeAlertsByStep(oldStep));
        }

        if (step > 0) {
            setProgress(old => ({
                ...old,
                width: step / (bookingStepsLength - 1)
            }));
        }
    }, [step]);

    useNonInitialEffect(() => {
        switch (invoicesStatus) {
            case InvoicesStatus.CreateDepositInvoiceSuccess:
                if (!depositInvoice?.id) {
                    return;
                }

                setEditedBooking({
                    ...editedBooking,
                    invoiceId: depositInvoice?.id
                });
                setStep(BookingStep.Payment);
                amplitude.track(AMPLITUDE.LOAD_PAYMENT_INFO, {
                    marketplace: store.getState().marketplace.marketplace.name
                });
                setShowBack(false);
                break;
            case InvoicesStatus.Error:
                dispatch(
                    showErrorAlert(
                        'Error creating invoice, please try again later.',
                        BookingStep.Payment
                    )
                );
                onPaymentErrorHandler();
                break;
        }
    }, [invoicesStatus]);

    useNonInitialEffect(() => {
        if (stateBooking?.status === BookingStatus.CreateDepositSuccess) {
            console.log('Deposit creado');
        }

        if (
            stateBooking?.status === BookingStatus.CreateSuccess ||
            stateBooking?.status === BookingStatus.CreateDepositSuccess
        ) {
            if (hasDeposit && !hasSiblings && !marketplace.bookingRequestsEnabled) {
                setEditedBooking({
                    ...editedBooking,
                    id: stateBooking.booking?.id
                });

                dispatch(
                    createDepositInvoiceThunk(apiClientWrapper, Number(stateBooking.booking?.id))
                );
                return;
            }

            onCompletePaymentHandler();
        }

        if (stateBooking?.status === BookingStatus.Error) {
            if (stateBooking?.status === BookingStatus.Error) {
                if (stateBooking.errorStep) {
                    switch (stateBooking.errorStep) {
                        case BookingStep.DateSelect:
                            onContinueSelectStaff();
                            break;
                        case BookingStep.Notes:
                            onContinueSelectDate();
                            break;
                        case BookingStep.PetSelect:
                            onContinueSuccess();
                            break;
                        case BookingStep.CustomerForm:
                            if (auth.customer?.id) {
                                setShowBack(false);
                            }

                            setStep(BookingStep.CustomerForm);
                            break;
                        default:
                            onContinueSelectPet();
                            break;
                    }
                } else {
                    onContinueSelectPet();
                }
            }
        }
    }, [stateBooking]);

    /**
     * --------------
     * Event handlers
     * --------------
     */

    const homeContinueHandler = (isReturningClient: boolean) => {
        setShowBack(true);

        if (isReturningClient) {
            setStep(BookingStep.Signin);
        } else {
            setStep(BookingStep.CustomerForm);

            amplitude.track(AMPLITUDE.LOAD_PERSONAL_INFO, {
                marketplace: store.getState().marketplace.marketplace.name
            });
        }
    };

    const singinContinueHandler = () => {
        setShowBack(false);
        setStep(BookingStep.PetSelect);
    };

    const customerContinueHandler = async () => {
        const lookup = await dispatch(lookupCustomerThunk(editedBooking.customer.email));

        setEditedBooking(data => ({
            ...data,
            customer: {
                ...data.customer,
                blocked: lookup?.blocked
            }
        }));

        if (editedBooking.pets.length > 0) {
            setStep(BookingStep.PetSelect);
        } else {
            setStep(BookingStep.AddPet);
        }

        amplitude.track(AMPLITUDE.CTA_CONTINUE_PERSONAL_INFO, {
            marketplace: store.getState().marketplace.marketplace.name
        });
    };

    const onCompletePaymentHandler = async () => {
        await signAgreements();
        amplitude.track(AMPLITUDE.CTA_PAY, {
            marketplace: store.getState().marketplace.marketplace.name
        });
        setStep(BookingStep.Success);
        amplitude.track(AMPLITUDE.LOAD_APPOINTMENT_BOOKED, {
            marketplace: store.getState().marketplace.marketplace.name
        });
        setShowBack(false);
    };

    const onContinueAddPet = () => {
        amplitude.track(AMPLITUDE.CTA_CONTINUE_PET_INFO_SERVICE, {
            marketplace: store.getState().marketplace.marketplace.name
        });
        setShowBack(!auth.customer?.id);
        setStep(BookingStep.PetSelect);
        amplitude.track(AMPLITUDE.LOAD_SELECT_DATE_AND_TIME, {
            marketplace: store.getState().marketplace.marketplace.name
        });
    };

    const onContinueSelectPet = () => {
        setShowBack(true);
        setStep(BookingStep.ServiceSelect);
    };

    const onCreateNewPet = () => {
        setShowBack(true);
        setStep(BookingStep.AddPet);
    };

    const onContinueSelectService = () => {
        amplitude.track(AMPLITUDE.CTA_CONTINUE_PET_INFO_SERVICE, {
            marketplace: store.getState().marketplace.marketplace.name
        });
        setStep(
            marketplace.allowClientsSelectStaff ? BookingStep.StaffSelect : BookingStep.DateSelect
        );
        amplitude.track(AMPLITUDE.LOAD_SELECT_DATE_AND_TIME, {
            marketplace: store.getState().marketplace.marketplace.name
        });
    };

    const onContinueSelectStaff = () => {
        setStep(BookingStep.DateSelect);
    };

    const onContinueSelectDate = async () => {
        let customer: Customer;
        const { address, ...customerData } = editedBooking.customer;

        setStep(BookingStep.Loading);

        if (auth.signedIn && auth.customer) {
            customer = auth.customer;

            // If customer has not saved pets, save them
            if (editedBooking.petsToBooking.some(item => !item.pet.id)) {
                const updatedCustomer: Customer = await dispatch(
                    updateCustomerThunk(
                        customer,
                        editedBooking.pets.filter(pet => !pet.id)
                    )
                );

                customer = updatedCustomer;

                setEditedBooking({
                    ...editedBooking,
                    petsToBooking: editedBooking.petsToBooking.map(item => ({
                        ...item,
                        pet:
                            updatedCustomer.pets?.find(
                                pet => pet.name.toLocaleLowerCase() === item.pet.name.toLowerCase()
                            ) || item.pet
                    }))
                });
            }
        } else {
            customer = await dispatch(
                findOrCreateCustomerWithPetsThunk(
                    {
                        ...customerData,
                        ...(isAddressRequired && {
                            addresses: [
                                {
                                    city: `${address?.city}`,
                                    state: `${address?.state}`,
                                    zipCode: `${address?.zipCode}`,
                                    addressLineOne: `${address?.address}`,
                                    country: 'US',
                                    active: true
                                }
                            ]
                        }),
                        ...(customerData.emergencyContact && {
                            emergencyContacts: [
                                {
                                    ...customerData.emergencyContact
                                }
                            ]
                        })
                    },
                    editedBooking.pets
                )
            );
        }

        amplitude.track(AMPLITUDE.CTA_CONTINUE_SELECT_DATE_AND_TIME, {
            marketplace: store.getState().marketplace.marketplace.name
        });
        setStep(BookingStep.Notes);
    };

    const onContinueSuccess = () => {
        setStep(BookingStep.PetSelect);
    };

    const onChangeBooking = (booking: EditBooking) => {
        setEditedBooking(booking);
    };

    const signAgreements = async () => {
        await editedBooking.agreementsSignatures.forEach(async signature => {
            await dispatch(signAgreementThunk(signature));
        });
    };

    const onCreateBookingHandler = async () => {
        setStep(BookingStep.Loading);

        await dispatch(fetchMarketplaceBySlugThunk(marketplace.slug));

        const customer = auth.customer;
        const customerId = auth.customer?.id;

        if (!customer || !customerId) return;

        const bookingDate =
            editedBooking.date?.object || DateTime.now().setZone(marketplace.timeZone);

        const hasSiblings = await dispatch(
            fetchBookingSiblingsThunk(bookingDate.toFormat('yyyy-MM-dd'), customer)
        );

        // Collect deposits only when there are no siblings and booking requests are disabled
        const collectDeposit = hasDeposit && !hasSiblings && !marketplace.bookingRequestsEnabled;

        const arrayAppointment: Array<NewBookingAppointment> = [];
        const addressId = marketplace.address ? marketplace.address![0].id : 0;
        let appointmentsAttributes: NewBookingAppointment;

        editedBooking.petsToBooking.map(appointment => {
            const dateString = `${editedBooking.date?.ISODate} ${appointment.hour?.hour}`;

            const pet =
                customer?.pets?.find(
                    p => p.name.toLowerCase().trim() === appointment.pet.name.toLowerCase().trim()
                ) || appointment.pet;

            const newBookingStatus = marketplace.bookingRequestsEnabled ? 0 : 1;

            appointmentsAttributes = {
                addressId: addressId,
                customerId: customerId,
                channel: 'online-booking',
                bookedAt: formatDateTimezone(dateString, `${marketplace?.timeZone}`),
                petId: pet?.id as number,
                status: collectDeposit ? 11 : newBookingStatus,
                serviceId: appointment.service!.id as number,
                staffId: (editedBooking.staffId || appointment.hour?.staff[0]) as number,
                uuid: uuid(),
                createdBy: appointment.hour?.staff[0] as number,
                notes: editedBooking.notes
            };

            arrayAppointment.push(appointmentsAttributes);
            return '';
        });

        const booking: NewBooking = {
            uuid: uuid(),
            customerId: customerId,
            marketplaceId: store.getState().marketplace.marketplace.id!,
            staffId: (editedBooking.staffId ||
                editedBooking.petsToBooking[0].hour?.staff[0]) as number,
            appointmentsAttributes: arrayAppointment
        };

        if (collectDeposit) {
            dispatch(createBookingDepositThunk(booking));
        } else {
            dispatch(createBookingThunk(booking));
        }
    };

    const backHandler = () => {
        switch (step) {
            case BookingStep.Signin:
            case BookingStep.CustomerForm:
                setShowBack(false);
                setStep(BookingStep.Home);
                break;

            case BookingStep.AddPet:
                if (auth.customer?.id) {
                    setStep(BookingStep.PetSelect);
                    return;
                }

                setStep(BookingStep.CustomerForm);
                break;

            case BookingStep.PetSelect:
                amplitude.track(AMPLITUDE.CTA_BACK_SELECT_PET, {
                    marketplace: store.getState().marketplace.marketplace.name
                });

                setShowBack(true);

                if (editedBooking.pets.length) {
                    setStep(BookingStep.CustomerForm);
                } else {
                    setStep(BookingStep.AddPet);
                }

                amplitude.track(AMPLITUDE.LOAD_ADD_SERVICE, {
                    marketplace: store.getState().marketplace.marketplace.name
                });
                break;

            case BookingStep.ServiceSelect:
                amplitude.track(AMPLITUDE.CTA_BACK_SELECT_SERVICE, {
                    marketplace: store.getState().marketplace.marketplace.name
                });

                if (auth.token) {
                    setShowBack(false);
                }

                setStep(BookingStep.PetSelect);
                amplitude.track(AMPLITUDE.LOAD_ADD_SERVICE, {
                    marketplace: store.getState().marketplace.marketplace.name
                });
                break;

            case BookingStep.StaffSelect:
                setStep(BookingStep.ServiceSelect);
                break;

            case BookingStep.DateSelect:
                amplitude.track(AMPLITUDE.CTA_BACK_SELECT_DATE_AND_TIME, {
                    marketplace: store.getState().marketplace.marketplace.name
                });
                setStep(
                    marketplace.allowClientsSelectStaff
                        ? BookingStep.StaffSelect
                        : BookingStep.PetSelect
                );
                amplitude.track(AMPLITUDE.LOAD_ADD_SERVICE, {
                    marketplace: store.getState().marketplace.marketplace.name
                });
                break;

            case BookingStep.Notes:
                amplitude.track(AMPLITUDE.CTA_BACK_PERSONAL_INFO, {
                    marketplace: store.getState().marketplace.marketplace.name
                });
                setStep(BookingStep.DateSelect);
                amplitude.track(AMPLITUDE.LOAD_SELECT_DATE_AND_TIME, {
                    marketplace: store.getState().marketplace.marketplace.name
                });
                break;
        }
    };

    const onPaymentErrorHandler = () => {
        setStep(BookingStep.Notes);
        setShowBack(true);
    };

    /**
     * --------------
     * Memoized values
     * --------------
     */

    const isAddressRequired = useMemo(() => {
        return [MarketplaceType.INHOME, MarketplaceType.MOBILE].includes(marketplace.businessType);
    }, [marketplace]);

    const hasSiblings = useMemo(() => {
        return bookingSiblings.length > 0;
    }, [bookingSiblings]);

    const hasDeposit = useMemo(() => {
        const marketplaceHasDepositActive = marketplace.depositCategory !== 'none';

        return marketplaceHasDepositActive;
    }, [marketplace]);

    const isLoading = useMemo(() => {
        const isLoadingStep = step === BookingStep.Loading;
        const isMasterDataLoading = masterDataState.loading;
        const isServicesLoading = servicesState.loading;
        const isStaffsLoading = staffsState.loading;

        return isLoadingStep || isMasterDataLoading || isServicesLoading || isStaffsLoading;
    }, [step, masterDataState, servicesState, staffsState]);

    /**
     * --------------
     *   Components
     * --------------
     */

    const spinner = (
        <Box className={classes.boxSpinner}>
            <Spinner className={classes.spinner} />
        </Box>
    );

    const homeView = <BookingHome onContinue={homeContinueHandler} />;

    const signinView = <BookingSignin onContinue={singinContinueHandler} />;

    const customerView = (
        <BookingCustomer
            booking={editedBooking}
            onChange={onChangeBooking}
            onContinue={customerContinueHandler}
        />
    );

    const bookingAddPetView = (
        <BookingAddPet
            booking={editedBooking}
            marketplace={marketplace}
            //
            onChange={onChangeBooking}
            onContinue={onContinueAddPet}
        />
    );

    const bookingSelectPetView = (
        <BookingSelectPet
            booking={editedBooking}
            marketplace={marketplace}
            //
            onChange={onChangeBooking}
            onCreate={onCreateNewPet}
            onContinue={onContinueSelectPet}
        />
    );

    const bookingSelectServiceView = (
        <BookingSelectService
            booking={editedBooking}
            marketplace={marketplace}
            //
            onChange={onChangeBooking}
            onContinue={onContinueSelectService}
        />
    );

    const bookingSelectStaffView = (
        <BookingSelectStaff
            booking={editedBooking}
            marketplace={marketplace}
            //
            onChange={onChangeBooking}
            onContinue={onContinueSelectStaff}
        />
    );

    const bookingSelectDateView = (
        <BookingSelectDate
            booking={editedBooking}
            marketplace={marketplace}
            //
            onChange={onChangeBooking}
            onContinue={onContinueSelectDate}
        />
    );

    const bookingNotesView = (
        <BookingNotes
            booking={editedBooking}
            marketplace={marketplace}
            //
            onChange={onChangeBooking}
            onBooking={onCreateBookingHandler}
        />
    );

    const bookingPaymentView = (
        <BookingPayment
            booking={editedBooking}
            marketplace={marketplace}
            onError={onPaymentErrorHandler}
            onCompletePayment={onCompletePaymentHandler}
        />
    );

    const bookingSuccessView = (
        <BookingSuccess
            booking={editedBooking}
            //
            onContinue={onContinueSuccess}
        />
    );

    if (isLoading) {
        return spinner;
    }

    if (!isOLBEnabled) {
        return (
            <>
                <BookingDisabled />
            </>
        );
    }

    return (
        <>
            <Header
                backButton={showBack}
                progress={progress}
                //
                onBack={backHandler}
            />

            {step === BookingStep.Home && homeView}
            {step === BookingStep.Signin && signinView}
            {step === BookingStep.CustomerForm && customerView}
            {step === BookingStep.AddPet && bookingAddPetView}
            {step === BookingStep.PetSelect && bookingSelectPetView}
            {step === BookingStep.ServiceSelect && bookingSelectServiceView}
            {step === BookingStep.StaffSelect && bookingSelectStaffView}
            {step === BookingStep.DateSelect && bookingSelectDateView}
            {step === BookingStep.Notes && bookingNotesView}
            {step === BookingStep.Payment && bookingPaymentView}
            {step === BookingStep.Success && bookingSuccessView}
        </>
    );
};

export default Booking;
