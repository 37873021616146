import InputMask from 'react-input-mask';
import React, { useMemo, useRef } from 'react';
import { isString } from 'lodash';
import CustomField from '../CustomField/CustomField';

interface CustomMaskFieldProps {
    type: string;
    mask: string;
    value: string;
    name: string;
    label?: string;
    error?: boolean;
    required?: boolean;
    id?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CustomMaskField = (props: CustomMaskFieldProps) => {
    const input = useRef<HTMLInputElement>(null);

    const computedValue = useMemo(() => {
        return isString(props.value) ? props.value : '';
    }, [props.value]);

    return (
        <InputMask mask={props.mask} id={props.id} value={computedValue} onChange={props.onChange}>
            {() => <CustomField {...props} ref={input} />}
        </InputMask>
    );
};
