import { Box } from "@mui/material";
import { isString } from "lodash";
import React, { ReactElement, useState } from "react";
import "./Heading.scss";

interface HeadingProps {
  title: string | ReactElement;
  subtitle?: string | React.ReactNode;
  divider?: boolean;
  boxProps?: object;
  tooltip?: object;
  tooltipIcon?: object;
}

const Heading: React.FunctionComponent<HeadingProps> = (props) => {
  const { boxProps } = props;

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <Box className="Heading" {...boxProps}>
      <h1>
        {props.title}
        {!props.subtitle && props.tooltip && (
          <Box
            className="Heading-tooltip"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {props.tooltipIcon}

            {showTooltip && (
              <Box className="Heading-tooltip-box">{props.tooltip}</Box>
            )}
          </Box>
        )}
      </h1>
      {props.subtitle && (
        <Box className="Heading-subtitle">
          {isString(props.subtitle) ?
            <span dangerouslySetInnerHTML={{ __html: props.subtitle }}></span> :
            props.subtitle
          }

          {props.tooltip && (
            <Box
              className="Heading-tooltip"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              {props.tooltipIcon}

              {showTooltip && (
                <Box className="Heading-tooltip-box">{props.tooltip}</Box>
              )}
            </Box>
          )}
        </Box>
      )}
      {props.divider && <hr />}
    </Box>
  );
};

Heading.defaultProps = {
  divider: true,
};

export default Heading;
