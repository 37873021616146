import { Reducer } from "redux";
import { ServicesState, ServiceStatus } from "./ServicesState";
import {
  ServicesAction,
  SERVICES_ERROR_ACTION_TYPE,
  SERVICES_FETCH_ACTION_TYPE,
  SERVICES_FETCH_SUCCESS_ACTION_TYPE,
  SERVICES_RESET_ACTION_TYPE,
} from "actions/services/ServicesActionsTypes";

const initialState: ServicesState = {
  services: [],
  status: ServiceStatus.Initial,
  loading: true,
};

export const servicesReducer: Reducer<ServicesState, ServicesAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SERVICES_RESET_ACTION_TYPE:
      return {
        ...initialState,
      };
    case SERVICES_FETCH_ACTION_TYPE:
      return {
        ...state,
        status: ServiceStatus.Fetching,
      };
    case SERVICES_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: ServiceStatus.FetchSuccess,
        loading: false,
        services: action.payload.services,
      };
    case SERVICES_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: ServiceStatus.Error,
        loading: true,
      };
    default:
      return state;
  }
};

export default servicesReducer;
