import {
  HeaderAction,
  UPDATE_BACK_BUTTON,
  UPDATE_USER_BADGE,
  RESET_HEADER,
} from "actions/header/HeaderActionsTypes";
import { getFromStorage } from "auth";
import { HeaderState } from "./HeaderState";

const auth = getFromStorage();

const initialState: HeaderState = {
  progressBar: {
    show: false,
    width: 0,
  },
  user: { avatar: auth.customer?.avatar!, name: auth.customer?.name! },
};

function headerReducer(
  state: HeaderState = initialState,
  action: HeaderAction
) {
  switch (action.type) {
    case UPDATE_BACK_BUTTON:
      return Object.assign({}, state, {
        backButton: action.payload,
      });
    case UPDATE_USER_BADGE:
      return Object.assign({}, state, {
        user: action.payload,
      });
    case RESET_HEADER:
      return initialState;
    default:
      return state;
  }
}

export default headerReducer;
