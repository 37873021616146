import { Auth } from 'model/Auth';
import { Action } from 'redux';
import { Customer } from 'model/Customer';

/**
 * Types
 */
export const LOGIN_START_ACTION_TYPE = 'login/start';
export const LOGIN_SUCCESS_ACTION_TYPE = 'login/success';
export const LOGIN_FAIL_ACTION_TYPE = 'login/fail';

export const LOOKUP_START_ACTION_TYPE = 'login/start_lookup';
export const LOOKUP_SUCCESS_ACTION_TYPE = 'login/lookup';

export const REQUEST_SMS_CODE_START_ACTION_TYPE = 'login/request_sms_code';
export const REQUEST_SMS_CODE_SUCCESS_ACTION_TYPE = 'login/request_sms_code_success';
export const REQUEST_SMS_CODE_FAILED_ACTION_TYPE = 'login/request_sms_code_failed';

/**
 * Login
 */
interface LoginStartAction extends Action<typeof LOGIN_START_ACTION_TYPE> {}

interface LoginSuccessAction extends Action<typeof LOGIN_SUCCESS_ACTION_TYPE> {
    payload: {
        auth: Auth;
    };
}

interface LoginFailAction extends Action<typeof LOGIN_FAIL_ACTION_TYPE> {}

/**
 * Lookup
 */
interface LookUpStartAction extends Action<typeof LOOKUP_START_ACTION_TYPE> {}

interface LookUpSuccessAction extends Action<typeof LOOKUP_SUCCESS_ACTION_TYPE> {
    payload: {
        customer: Customer;
    };
}

/**
 * Request SMS code
 */
interface RequestSMSCodeStartAction extends Action<typeof REQUEST_SMS_CODE_START_ACTION_TYPE> {}

interface RequestSMSCodeSuccessAction extends Action<typeof REQUEST_SMS_CODE_SUCCESS_ACTION_TYPE> {}

interface RequestSMSCodeFailedAction extends Action<typeof REQUEST_SMS_CODE_FAILED_ACTION_TYPE> {}

export type LoginAction =
    | LookUpStartAction
    | LookUpSuccessAction
    //
    | LoginStartAction
    | LoginSuccessAction
    | LoginFailAction
    //
    | RequestSMSCodeStartAction
    | RequestSMSCodeSuccessAction
    | RequestSMSCodeFailedAction;
