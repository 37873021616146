import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "./CustomSelect.scss";
import { Option } from "model";

interface CustomSelectProps {
  options: Array<Option<string | number>>;
  // All other props
  [x: string]: any;
}

const CustomSelect: React.FunctionComponent<CustomSelectProps> = (
  props: any
) => {
  const { options, ...otherProps } = props;

  return (
    <Box>
      <FormControl className="CustomSelect" fullWidth>
        <InputLabel
          id={`CustomSelect-label-${props.name}`}
          {...(props.required && { required: true })}
        >
          {props.label}
        </InputLabel>

        <Select labelId={`CustomSelect-label-${props.name}`} {...otherProps}>
          {[...options].map((x, i) => (
            <MenuItem id={`customSelect-option-${x.name}`} value={x.id} key={x.id}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
