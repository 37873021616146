import React, { useEffect, useState } from "react";

import { DateTime, Duration } from "luxon";

import { CustomSelect } from "components";

interface Month {
  id: any;
  name: any;
}

interface DatePickerMonthSelectProps {
  value: string;
  onChange(value: string): void;
}

const DatePickerMonthSelect: React.FunctionComponent<
  DatePickerMonthSelectProps
> = (props) => {
  let startDate = DateTime.now().startOf("month");
  const endDate = startDate
    .plus(Duration.fromObject({ months: 12 }))
    .endOf("month");

  const [monthRange, setMonthRange] = useState<Month[]>([]);

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    props.onChange(value);
  };

  useEffect(() => {
    const monthArr: Month[] = [];

    while (startDate < endDate) {
      monthArr.push({
        id: startDate.toFormat("LL/y"),
        name: startDate.toFormat("LLLL y"),
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
      startDate = startDate.plus(Duration.fromObject({ months: 1 }));
    }

    setMonthRange(monthArr);
  }, []);

  return (
    <CustomSelect
      options={monthRange}
      value={props.value}
      onChange={onSelectChange}
    />
  );
};

export default DatePickerMonthSelect;
