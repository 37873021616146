import {
    MasterDataAction,
    MASTER_DATA_RESET_ACTION_TYPE,
    MASTER_DATA_FETCH_START_ACTION_TYPE,
    MASTER_DATA_FETCH_SUCCESS_ACTION_TYPE,
    MASTER_DATA_ERROR_ACTION_TYPE
} from './MasterDataActionsTypes';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { MasterDataState } from 'reducers/masterData/MasterDataState';
import ApiClient, { createTokenConfig } from 'api';
import { MasterDataDto } from './MasterDataDtos';
import { convertMasterData } from './MasterDataConverter';
import { AlertsAction } from 'actions/alerts/AlertsActionsTypes';

const masterDataUrl = '/master_data';

export const fetchMasterDataThunk = (
    marketplaceId: number
): ThunkAction<void, MasterDataState, null, MasterDataAction | AlertsAction> => {
    return async dispatch => {
        dispatch(fetchStart());
        const url = masterDataUrl + (marketplaceId ? `?marketplace_id=${marketplaceId}` : '');

        try {
            const response: AxiosResponse<MasterDataDto> = await ApiClient.get(
                url,
                createTokenConfig()
            );
            dispatch(fetchSucces(response.data));
        } catch (apiError) {
            dispatch(error());
            console.error(apiError);
        }
    };
};

export const reset = (): MasterDataAction => {
    return {
        type: MASTER_DATA_RESET_ACTION_TYPE
    };
};

const fetchStart = (): MasterDataAction => {
    return {
        type: MASTER_DATA_FETCH_START_ACTION_TYPE
    };
};

export const fetchSucces = (masterDataDto: MasterDataDto): MasterDataAction => {
    return {
        type: MASTER_DATA_FETCH_SUCCESS_ACTION_TYPE,
        payload: {
            masterData: convertMasterData(masterDataDto)
        }
    };
};

const error = (): MasterDataAction => {
    return {
        type: MASTER_DATA_ERROR_ACTION_TYPE
    };
};
