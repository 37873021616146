import { Box } from '@mui/material'
import React from 'react'
import './Divider.scss'

interface DividerProps {
    text: string,
    showText?: boolean
}

const Divider: React.FunctionComponent<DividerProps> = (props) => {
    return (
        <Box className="Divider">
            {props.showText && <p>{props.text}</p>}
        </Box>
    )
}

Divider.defaultProps = {
    showText: true,
    text: ''
}

export default Divider
