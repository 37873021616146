import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import store from "store";

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_API_PUBLIC_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const ApiPublicClient: AxiosInstance = axios.create(config);

export const createTokenConfig = (): AxiosRequestConfig => {
  const token = store.getState().login.auth.token;
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {};
  }
};

export const createMultipartTokenConfig = (): AxiosRequestConfig => {
  const tokenConfig = createTokenConfig();
  return {
    ...tokenConfig,
    headers: {
      ...tokenConfig.headers,
      "Content-Type": "multipart/mixed",
    },
  };
};

export default ApiPublicClient;
