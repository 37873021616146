import { Option, OrderedOption } from 'model';
import { Moment } from 'moment-timezone';
import { v4 as uuid } from 'uuid';

export interface Pet {
    id?: number;
    name: string;
    type: PetType;
    age: string;
    gender: string;
    breed: number;
    size: string;
    weight?: number;
    birthdate: string;
    hairColor: PetHairColor;
    hairLength?: PetHairLength;
    image?: string | null | undefined;
    imageFileToUpload?: File;
    imageRemoved?: boolean;
    uuid: string;
    active: boolean;
    vaccines: Array<PetVaccine>;
}

export interface PetType {
    id: string;
    singular: string;
    plural: string;
}

export type PetSize = OrderedOption<string>;

export type PetAge = Option<string>;

export type PetHairLength = Option<string>;

export type PetHairColor = Option<string>;

export type PetSizes = {
    cats: Array<PetSize>;
    dogs: Array<PetSize>;
    exotics: Array<PetSize>;
};

export type PetAges = {
    cats: Array<PetAge>;
    dogs: Array<PetAge>;
    exotics: Array<PetAge>;
};

export type PetHairLengths = {
    cats: Array<Option<string>>;
    dogs: Array<Option<string>>;
    exotics: Array<Option<string>>;
};

export type PetVaccine = {
    id?: number;
    uuid: string;
    expiration?: Moment;
    status: string;
    type?: Option<string>;
    recordUrl?: string;
    recordFileToUpload?: File;
};

export const createEmptyVaccine = (): PetVaccine => ({
    id: undefined,
    uuid: uuid(),
    expiration: undefined,
    status: 'active',
    recordUrl: undefined,
    recordFileToUpload: undefined,
    type: undefined
});
