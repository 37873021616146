import React, { useEffect, useMemo, useState } from 'react';

import './EmergencyContactForm.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Grid } from '@mui/material';
import { CustomField, SearchAutocomplete } from 'components/UI/FormsPart';
import { CustomPhoneField } from 'components/UI/FormsPart/CustomPhoneField/CustomPhoneField';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { EmergencyContact, EmergencyContactRelationShip } from 'model';
import { validatePhone } from 'utils';
import { SearchAutocompleteOption } from 'components/UI/FormsPart/SearchAutocomplete/SearchAutocomplete';
import { useMasterData } from 'hooks';

export type EmergencyContactValue = {
    first_name?: string;
    last_name?: string;
    phone?: string;
    relationship?: EmergencyContactRelationShip;
};

export type EmergencyContactFormProps = {
    showForm: boolean;
    values?: EmergencyContactValue;
    onToggle: () => void;
    onChange: (contact?: EmergencyContact) => void;
    className?: string;
};

const Form: React.FC<{
    values?: EmergencyContactValue;
    onChange: (contact?: EmergencyContact) => void;
    onDelete: () => void;
}> = props => {
    const { relationships } = useMasterData();

    const [contact, setContact] = useState<EmergencyContact>({
        first_name: props.values?.first_name ?? '',
        last_name: props.values?.last_name ?? '',
        phone: props.values?.phone ?? '',
        relationship: props.values?.relationship ?? ({} as EmergencyContactRelationShip)
    });

    const [touched, setTouched] = useState({
        first_name: false,
        last_name: false,
        phone: false,
        relationship: false
    });

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setContact({ ...contact, [name]: value });
        setTouched({ ...touched, [name]: true });
    };

    const relationshipChangeHandler = (value: SearchAutocompleteOption | null) => {
        setContact({
            ...contact,
            relationship: value as EmergencyContactRelationShip
        });
        setTouched({ ...touched, relationship: true });
    };

    const deleteHandler = () => {
        setTouched({
            first_name: false,
            last_name: false,
            phone: false,
            relationship: false
        });

        props.onDelete();
    };

    const errors = useMemo(() => {
        return Object.entries(contact).reduce(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (obj, item: [string, any]) => {
                const name = item[0];
                const value = item[1];

                if (name === 'first_name') obj.first_name = value.length === 0;

                if (name === 'last_name') obj.last_name = value.length === 0;

                if (name === 'phone') obj.phone = value.length === 0 || validatePhone(value);

                if (name === 'relationship') obj.relationship = !value?.id;

                return obj;
            },
            {
                first_name: false,
                last_name: false,
                phone: false,
                relationship: false
            }
        );
    }, [contact]);

    useEffect(() => {
        if (Object.values(errors).filter(Boolean).length === 0) {
            props.onChange(contact);
        } else {
            props.onChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact, errors]);

    return (
        <Grid container columnSpacing={3}>
            <Grid item xs={12} md={6}>
                <CustomField
                    id="emergency_contact_name"
                    required
                    type="text"
                    name="first_name"
                    label="First Name"
                    value={contact.first_name}
                    error={touched.first_name && errors.first_name}
                    onChange={inputChangeHandler}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomField
                    id="emergency_contact_last_name"
                    required
                    type="text"
                    name="last_name"
                    label="Last Name"
                    value={contact.last_name}
                    error={touched.last_name && errors.last_name}
                    onChange={inputChangeHandler}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomPhoneField
                    id="emergency_contact_phone"
                    required
                    type="text"
                    name="phone"
                    label="Phone"
                    value={contact.phone}
                    error={touched.phone && errors.phone}
                    onChange={inputChangeHandler}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SearchAutocomplete
                    required
                    hideIcon={true}
                    value={contact.relationship}
                    id="emergency_contact_relationship"
                    error={touched.relationship && errors.relationship}
                    label="Relationship"
                    options={relationships}
                    onChange={relationshipChangeHandler}
                />
            </Grid>
            <Grid item xs={12}>
                <button
                    type="button"
                    id="emergency_contact_delete"
                    className="EmergencyContactForm-delete"
                    onClick={deleteHandler}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                    <span>Delete</span>
                </button>
            </Grid>
        </Grid>
    );
};

const EmergencyContactForm: React.FC<EmergencyContactFormProps> = props => {
    const [showAddForm, setShowAddForm] = React.useState(props.showForm);

    const addContactHandler = () => {
        props.onToggle();
        setShowAddForm(true);
    };

    const deleteHandler = () => {
        setShowAddForm(false);

        props.onToggle();
        props.onChange(undefined);
    };

    return (
        <div className={`EmergencyContactForm ${props.className}`}>
            <h3 className="EmergencyContactForm-title">Emergency contact</h3>

            <p className="EmergencyContactForm-description">
                Your emergency contact is authorized to pick up, drop off, and make decisions on
                behalf of your pet.
            </p>

            {showAddForm ? (
                <>
                    <Form
                        values={props.values}
                        onDelete={deleteHandler}
                        onChange={props.onChange}
                    />
                </>
            ) : (
                <button
                    id="emergency_contact__add"
                    className="EmergencyContactForm-addNewButton"
                    onClick={addContactHandler}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    Add emergency contact
                </button>
            )}
        </div>
    );
};

export default EmergencyContactForm;
