import React from 'react'
import './FullWidthImage.scss'

interface FullWidthImageProps {
    src: string,
    alt?: string,
    hiddenHeader?: boolean,
}

const FullWidthImage: React.FunctionComponent<FullWidthImageProps> = (props) => {
    return (
        <img {...props} alt={props.alt} className={props.hiddenHeader ? "FullWidthImage FullWidthImage-no-header" : "FullWidthImage"} />
    )
}

export default FullWidthImage