import { MasterDataDto, TimeLimitDto } from './MasterDataDtos';
import { MasterData, TimeLimit } from 'model/MasterData';

export const convertMasterData = (masterDataDto: MasterDataDto): MasterData => {
    return {
        accessLevel: masterDataDto.access_level,
        businessAreas: masterDataDto.business_areas,
        paymentTypes: masterDataDto.payment_types || [],
        petTypes: masterDataDto.pet_types,
        petAges: masterDataDto.pet_age,
        ageOptions: masterDataDto.pet_age,
        petSizes:
            masterDataDto.pet_sizes?.map((size, i) => ({
                element: {
                    id: size.id,
                    name: size.name,
                    description: size.description
                },
                order: i
            })) || [],
        petHairTypes: masterDataDto.pet_hair_types,
        petHairLengths: masterDataDto.pet_hair_lengths,
        petHairColors: {
            cats: masterDataDto.cat_hair_colors,
            dogs: masterDataDto.dog_hair_colors,
            exotics: masterDataDto.exotic_hair_colors
        },
        petExoticTypes: masterDataDto.pet_exotic_types,
        petGenders: masterDataDto.pet_gender,
        grooming: {
            visibility: masterDataDto.grooming?.visibility,
            holdingAreas: masterDataDto.grooming?.holding_areas,
            sizeSeparation: masterDataDto.grooming?.size_separation,
            security: masterDataDto.grooming?.security,
            locationTypes: masterDataDto.grooming?.location_types
        },
        daycare: {
            visibility: masterDataDto.daycare?.visibility,
            sizeSeparation: masterDataDto.daycare?.size_separation,
            recreationalPerks: masterDataDto.daycare?.recreational_perks,
            security: masterDataDto.daycare?.security,
            extras: masterDataDto.daycare?.extras
        },
        boarding: {
            visibility: masterDataDto.boarding?.visibility,
            sizeSeparation: masterDataDto.boarding?.size_separation,
            facilityTours: masterDataDto.boarding?.facility_tours,
            overnightCare: masterDataDto.boarding?.overnight_care,
            recreationalPerks: masterDataDto.boarding?.recreational_perks,
            security: masterDataDto.boarding?.security,
            extras: masterDataDto.boarding?.extras
        },
        staff: {
            training: masterDataDto.staff?.training
        },
        status: masterDataDto.status,
        serviceTags: masterDataDto.service_tags,
        roles: masterDataDto.roles,
        relationships: masterDataDto.relationships,
        bookingStatus: masterDataDto.booking_statuses,
        dentalReportOptions: {
            dentalProcedures:
                masterDataDto.dental_report_options?.dental_procedures.map(option => ({
                    id: option.id,
                    name: option.name
                })) || [],
            homecare:
                masterDataDto.dental_report_options?.homecare_options.map(option => ({
                    id: option.id,
                    name: option.name
                })) || [],
            periodontalFindings:
                masterDataDto.dental_report_options?.periodontal_findings.map(option => ({
                    id: option.id,
                    name: option.name
                })) || [],
            recommendations:
                masterDataDto.dental_report_options?.recommendations.map(option => ({
                    id: option.id,
                    name: option.name
                })) || [],
            toothAbnormalities:
                masterDataDto.dental_report_options?.tooth_abnormalities.map(option => ({
                    id: option.id,
                    name: option.name
                })) || []
        },
        breeds: {
            dogs: masterDataDto.pet_breeds ? masterDataDto.pet_breeds[0].dogs : [],
            cats: masterDataDto.pet_breeds ? masterDataDto.pet_breeds[0].cats : [],
            exotics: masterDataDto.pet_breeds ? masterDataDto.pet_breeds[0].exotics : []
        },
        groomingReportOptions: {
            skin: masterDataDto.grooming_report_options?.skin || [],
            teeth: masterDataDto.grooming_report_options?.teeth || [],
            nails: masterDataDto.grooming_report_options?.nails || [],
            coat: masterDataDto.grooming_report_options?.coat || [],
            ears: masterDataDto.grooming_report_options?.ears || [],
            eyes: masterDataDto.grooming_report_options?.eyes || [],
            behavior: masterDataDto.grooming_report_options?.behavior || [],
            findings: masterDataDto.grooming_report_options?.findings || [],
            recommendations: masterDataDto.grooming_report_options?.recommendations || [],
            findingPlaces: masterDataDto.grooming_report_options?.finding_places || []
        },
        medicalHistory: {
            dentals: masterDataDto.dental_history_types?.map(dto => ({
                id: dto.id,
                name: dto.name
            })),
            medicalConditionCategories:
                masterDataDto.medical_condition_categories?.map(cat => ({
                    id: cat.id,
                    uuid: cat.uuid,
                    name: cat.name,
                    description: cat.description,
                    icon: cat.font_awesome_icon,
                    options:
                        cat.medical_conditions?.map(mc => ({
                            id: mc.id,
                            uuid: mc.uuid,
                            name: mc.name,
                            description: mc.description,
                            active: mc.active
                        })) || [],
                    active: cat.active
                })) || [],
            behaviorConditionCategories:
                masterDataDto.behavior_conditions_categories?.map(cat => ({
                    id: cat.id,
                    uuid: cat.uuid,
                    name: cat.name,
                    description: cat.description,
                    icon: cat.font_awesome_icon,
                    options:
                        cat.behavior_conditions?.map(bc => ({
                            id: bc.id,
                            uuid: bc.uuid,
                            name: bc.name,
                            description: bc.description,
                            active: bc.active
                        })) || [],
                    active: cat.active
                })) || []
        },
        petRequirements: masterDataDto.pet_requirements,
        imagesMaxSizes: {
            groomingReportMaxKb: masterDataDto.images_max_sizes?.grooming_report_kb || 2048,
            dentalReportKb: masterDataDto.images_max_sizes?.dental_report_kb || 2048,
            squareLogoKb: masterDataDto.images_max_sizes?.vertical_logo_kb || 2048,
            squareLogo: {
                maxSizeKb: masterDataDto.images_max_sizes?.vertical_logo_kb || 2048,
                maxWidthPx: masterDataDto.images_max_sizes?.vertical_logo_width || 600,
                maxHeightPx: masterDataDto.images_max_sizes?.vertical_logo_height || 600,
                imageTypes: masterDataDto.images_max_sizes?.vertical_logo_img_types || [
                    'png',
                    'jpg'
                ]
            },
            horizontalLogoKb: masterDataDto.images_max_sizes?.horizontal_logo_kb || 2048,
            horizontalLogo: {
                maxSizeKb: masterDataDto.images_max_sizes?.horizontal_logo_kb || 2048,
                maxWidthPx: masterDataDto.images_max_sizes?.horizontal_logo_width || 500,
                maxHeightPx: masterDataDto.images_max_sizes?.horizontal_logo_height || 200,
                imageTypes: masterDataDto.images_max_sizes?.horizontal_logo_img_types || [
                    'png',
                    'jpg'
                ]
            },
            avatarKb: masterDataDto.images_max_sizes?.avatar_kb || 2048
        },
        vaccines: masterDataDto.vaccines,
        emailContent: masterDataDto.email_default_recommendations
            ? [...masterDataDto.email_default_recommendations]
            : [],
        productUnits: [...(masterDataDto.units_of_measure || [])],
        taxOptions: [...(masterDataDto.tax_options || [])],
        payments: {
            phoneTypes: [...(masterDataDto.phone_types || [])],
            documentTypes: {
                businessProfile: [...(masterDataDto.business_profile_document_types || [])],
                shareholder: [...(masterDataDto.shareholder_document_types || [])],
                bankAccount: [...(masterDataDto.bank_account_document_types || [])]
            },
            genders: [...(masterDataDto.genders || [])],
            verificationStatuses: [...(masterDataDto.verification_statuses || [])],
            shareholderTypes: [...(masterDataDto.shareholder_types || [])],
            bankAccountTypes: [...(masterDataDto.bank_account_types || [])],
            currencies: [...(masterDataDto.currencies || [])],
            files: {
                pdf: {
                    maxSizeBytes:
                        masterDataDto.upload_file_size?.find(
                            size => size.id.toLowerCase() === 'pdf'
                        )?.max || 4 * 1024 * 1024,
                    minSizeBytes:
                        masterDataDto.upload_file_size?.find(
                            size => size.id.toLowerCase() === 'pdf'
                        )?.min || 1024
                },
                image: {
                    maxSizeBytes:
                        masterDataDto.upload_file_size?.find(
                            size => size.id.toLowerCase() === 'image'
                        )?.max || 4 * 1024 * 1024,
                    minSizeBytes:
                        masterDataDto.upload_file_size?.find(
                            size => size.id.toLowerCase() === 'image'
                        )?.min || 100 * 1024
                }
            }
        },
        usStates: [...(masterDataDto.us_states || [])],
        countryCodes: [...(masterDataDto.country_codes || [])],
        olbFromLimits: [...(masterDataDto.olb_minimum_lead_times || [])].map(limit =>
            convertToLimit(limit)
        ),
        olbToLimits: [...(masterDataDto.olb_maximum_lead_times || [])].map(limit =>
            convertToLimit(limit)
        )
    };
};

const convertToLimit = (dto: TimeLimitDto): TimeLimit => ({
    id: dto.id,
    name: dto.name,
    offsetSeconds: dto.time
});
