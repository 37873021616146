import React, { useEffect, useMemo } from 'react';
import { useMarketplace } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Booking as BookingComponent } from 'components/Booking/Booking';
import { Redirect, useParams } from 'react-router-dom';
import { useHomePath } from 'hooks/usePaths';
import { fetchMarketplaceBySlugThunk } from 'actions/marketplace/MarketplaceActions';
import { RootState } from 'store';
import { MarketplaceState, MarketplaceStatus } from 'reducers/marketplace/MarketplaceState';
import NotFound from './404';
import { BookingPath } from 'routes/Paths';

interface MarketplaceSlug {
    slug: string;
}

export default function Booking() {
    const BookingSlug = BookingPath + '/*';
    const dispatch = useDispatch();
    const marketplace = useMarketplace();
    const { slug } = useParams<MarketplaceSlug>();
    const homePath = useHomePath(slug);
    const stateMarketplace = useSelector<RootState, MarketplaceState>(state => state.marketplace);
    const loading =
        stateMarketplace.status == MarketplaceStatus.Fetching ||
        stateMarketplace.status == MarketplaceStatus.Initial ||
        stateMarketplace.status == MarketplaceStatus.Cleared;

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchMarketplaceBySlugThunk(slug));
        };

        if (!stateMarketplace?.marketplace.id || stateMarketplace?.marketplace.slug !== slug) {
            fetchData();
        }
        // eslint-disable-next-line
    }, []);

    const show = useMemo(() => {
        return stateMarketplace.marketplace.id !== undefined;
    }, [stateMarketplace.marketplace]);

    useEffect(() => {
        if (!marketplace.id) {
            dispatch(push(homePath));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {show ? (
                <BookingComponent />
            ) : (
                !loading && (
                    <>
                        <NotFound />
                        <Redirect from={BookingSlug} to="/" />{' '}
                    </>
                )
            )}
        </>
    );
}
