import { Availability, AvailabilityHour } from "model";
import { AvailabilityDtos, AvailabilityHourDto } from "./AvailabilityDtos";

export const convertAvailabilityData = (availabilityDataDto: AvailabilityDtos[]): Availability[] => {
  return availabilityDataDto.map((item) => ({
    availableHours: item.available_hours.map((hours) => convertHours(hours)),
    date: item.date,
    dayName: item.day_name,
  }));
};

export const convertHours = (hours: AvailabilityHourDto): AvailabilityHour => {
  return {
    datetime: hours.datetime,
    staffAvailable: hours.staff_available,
    time: hours.time,
  }
}
