import React, { useState } from 'react';
import './CollapsibleRadio.styles.scss';
import { Radio } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

interface CollapsibleRadioProps {
    id?: string;
    value: string;
    checked: boolean;
    title: string;
    content: string;
    description?: string;
    onChange: (value: string) => void;
}

const CollapsibleRadio: React.FC<CollapsibleRadioProps> = ({
    id,
    title,
    content,
    description,
    value,
    checked,
    onChange
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const hasContent = content.length > 0;

    const changeHandler = () => {
        onChange(value);
    };

    return (
        <div className="CollapsibleRadio" data-selected={checked} data-has-content={hasContent}>
            <div className="CollapsibleRadio-input">
                <Radio
                    id={id}
                    disableRipple
                    value={value}
                    checked={checked}
                    checkedIcon={
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="CollapsibleRadio-fontawesomeIcon"
                        />
                    }
                    onChange={changeHandler}
                />
            </div>

            <div className="CollapsibleRadio-info">
                <div className="CollapsibleRadio-heading">
                    <p className="CollapsibleRadio-title" onClick={changeHandler}>
                        {title}
                    </p>

                    {description && <p className="CollapsibleRadio-description">{description}</p>}
                </div>

                {isOpen && <p className="CollapsibleRadio-content">{content}</p>}

                {hasContent && (
                    <div className="CollapsibleRadio-moreInfo">
                        <button
                            type="button"
                            className="CollapsibleRadio-toggle"
                            aria-label={isOpen ? 'Hide info' : 'More info'}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {isOpen ? 'Hide info' : 'More info'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CollapsibleRadio;
