import {
    LoginAction,
    LOGIN_START_ACTION_TYPE,
    LOGIN_SUCCESS_ACTION_TYPE,
    LOGIN_FAIL_ACTION_TYPE,
    LOOKUP_START_ACTION_TYPE,
    LOOKUP_SUCCESS_ACTION_TYPE,
    REQUEST_SMS_CODE_START_ACTION_TYPE,
    REQUEST_SMS_CODE_SUCCESS_ACTION_TYPE,
    REQUEST_SMS_CODE_FAILED_ACTION_TYPE
} from './LoginActionsTypes';
import { ThunkAction } from 'redux-thunk';
import axios, { AxiosResponse } from 'axios';
import { LoginState } from 'reducers/login/LoginState';
import ApiPublicClient, { createTokenConfig } from 'api/ApiPublicClient';

import { CallHistoryMethodAction } from 'connected-react-router';
import { MasterDataAction } from 'actions/masterData/MasterDataActionsTypes';
import {
    CustomerLoginResponseDto,
    LoginWithSMSCodeResponseDto,
    RequestSMSCodeResponseDto
} from './LoginDtos';
import { convertLookUp } from './LoginConverter';
import { Auth } from 'model/Auth';
import store from 'store';

import { MarketplaceAction } from 'actions/marketplace/MarketplaceActionsTypes';
import { AlertsAction } from 'actions/alerts/AlertsActionsTypes';
import { HeaderAction } from 'actions/header/HeaderActionsTypes';
import { Customer } from 'model/Customer';
import { saveInStorage } from 'auth';
import { lookupCustomerByTokenThunk } from 'actions/customer/CustomerActions';
import { CustomerDto } from 'actions/customer/CustomerDtos';
import { convertCustomer } from 'actions/customer/CustomerConverter';
import { showErrorAlert } from 'actions/alerts/AlertsActions';
import { BookingStep } from 'components/Booking/Booking';

const lookUpUrl = 'customer_lookup';
const requestSMSCodeUrl = 'generate_sms_code';
const loginWithSMSCodeUrl = 'generate_token';

export const requestSMSCodeThunk = (
    phone: string
): ThunkAction<void, LoginState, null, LoginAction | AlertsAction> => {
    return async dispatch => {
        const marketplaceId = store.getState().marketplace.marketplace.id;

        dispatch(requestSMSCodeStart());

        try {
            await ApiPublicClient.post<RequestSMSCodeResponseDto>(requestSMSCodeUrl, {
                marketplace_id: marketplaceId,
                phone
            });

            dispatch(requestSMSCodeSuccess());
        } catch (error: unknown) {
            let errorMessage = 'Oops, something went wrong.';

            if (axios.isAxiosError(error) && error.response?.status === 404) {
                errorMessage = 'No client found with that phone number.';
            }

            dispatch(requestSMSCodeFailed());
            dispatch(showErrorAlert(errorMessage, BookingStep.Signin));
        }
    };
};

export const loginWithSMSCodeThunk = (
    phone: string,
    code: string
): ThunkAction<void, LoginState, null, LoginAction | AlertsAction> => {
    return async dispatch => {
        const marketplaceId = store.getState().marketplace.marketplace.id;

        dispatch(start());

        try {
            const response = await ApiPublicClient.post<LoginWithSMSCodeResponseDto>(
                loginWithSMSCodeUrl,
                {
                    marketplace_id: marketplaceId,
                    sms_code: code,
                    phone
                }
            );

            const customer: CustomerDto = await dispatch(
                lookupCustomerByTokenThunk(response.data.token, marketplaceId)
            );

            dispatch(
                loginSuccess({
                    signedIn: true,
                    token: response.data.token,
                    customer: convertCustomer(customer)
                })
            );
        } catch (error: unknown) {
            let errorMesssage = 'Oops, something went wrong.';

            if (axios.isAxiosError(error) && error.response?.data.message === 'SMS Code expired') {
                errorMesssage = 'Code expired, please request a new one.';
            }

            if (axios.isAxiosError(error) && error.response?.data.message === 'SMS Code invalid') {
                errorMesssage = 'Invalid code, please try again.';
            }

            dispatch(fail());
            dispatch(showErrorAlert(errorMesssage, BookingStep.Signin));
        }
    };
};

export const lookUpThunk = (
    email: string
): ThunkAction<
    void,
    LoginState,
    null,
    | LoginAction
    | CallHistoryMethodAction
    | MasterDataAction
    | MarketplaceAction
    | AlertsAction
    | HeaderAction
> => {
    return async dispatch => {
        const HTTP_STATUS_401_UNAUTHORIZED = 401;
        const marketplaceId = store.getState().marketplace.marketplace.id;

        dispatch(lookUpStart());
        const url =
            lookUpUrl + `?marketplace_id=${marketplaceId}&email=${email.trim().toLowerCase()}`;

        try {
            const response: AxiosResponse<CustomerLoginResponseDto> = await ApiPublicClient.get(
                url,
                createTokenConfig()
            );
            dispatch(lookUpSuccess(convertLookUp(response.data)));
        } catch (apiError: unknown) {
            // if (apiError.response && apiError.response.status === HTTP_STATUS_401_UNAUTHORIZED) {
            //     dispatch(fail());
            //     dispatch(showErrorAlert('Incorrect username or password'));
            // } else {
            //     dispatch(showErrorAlert('Login error'));
            //     console.error(apiError);
            // }
        }
    };
};

export const rememberUser = (auth: Auth, rememberMe: boolean) => {
    saveInStorage(auth, rememberMe);
};

export const loginSuccess = (auth: Auth): LoginAction => {
    return {
        type: LOGIN_SUCCESS_ACTION_TYPE,
        payload: {
            auth
        }
    };
};

/**
 * Lookup
 */
const lookUpStart = (): LoginAction => {
    return {
        type: LOOKUP_START_ACTION_TYPE
    };
};
const lookUpSuccess = (customer: Customer): LoginAction => {
    return {
        type: LOOKUP_SUCCESS_ACTION_TYPE,
        payload: {
            customer
        }
    };
};

/**
 * Request SMS code
 */
const requestSMSCodeStart = (): LoginAction => {
    return {
        type: REQUEST_SMS_CODE_START_ACTION_TYPE
    };
};
const requestSMSCodeSuccess = (): LoginAction => {
    return {
        type: REQUEST_SMS_CODE_SUCCESS_ACTION_TYPE
    };
};
const requestSMSCodeFailed = (): LoginAction => {
    return {
        type: REQUEST_SMS_CODE_FAILED_ACTION_TYPE
    };
};

/**
 * Login
 */
const start = (): LoginAction => {
    return {
        type: LOGIN_START_ACTION_TYPE
    };
};

const fail = (): LoginAction => {
    return {
        type: LOGIN_FAIL_ACTION_TYPE
    };
};
