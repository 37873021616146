import { IntakeFormLayout } from 'components/IntakeForm/Layout/Layout';
import { iconCheck } from 'assets/images';
import React from 'react';
import { Box } from '@mui/material';
import { ProgressBar } from 'model';

export type IntakeFormSuccessProps = {
    progress: ProgressBar;
};

export const IntakeFormSuccess: React.FC<IntakeFormSuccessProps> = props => {
    return (
        <IntakeFormLayout progress={props.progress}>
            <Box
                sx={{
                    'textAlign': 'center',
                    'mx': 'auto',
                    'px': { xs: 1, md: 0 },
                    'height': '90%',
                    'display': 'flex',
                    'flexDirection': 'column',
                    'justifyContent': 'center',
                    '& h1': { mt: 0, mb: 0 },
                    '& p': {
                        maxWidth: 400,
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }
                }}
            >
                <Box sx={{ img: { width: 64, mb: 1 } }}>
                    <img src={iconCheck} alt="Check icon" />
                </Box>

                <h1>Successfully Submitted</h1>
                <p>Your intake form has been successfully submitted. Thank you!</p>
            </Box>
        </IntakeFormLayout>
    );
};
