import { LoginState, LoginStatus } from './LoginState';
import {
    LOGIN_START_ACTION_TYPE,
    LOGIN_SUCCESS_ACTION_TYPE,
    LOGIN_FAIL_ACTION_TYPE,
    LOOKUP_START_ACTION_TYPE,
    LOOKUP_SUCCESS_ACTION_TYPE,
    LoginAction,
    REQUEST_SMS_CODE_START_ACTION_TYPE,
    REQUEST_SMS_CODE_SUCCESS_ACTION_TYPE,
    REQUEST_SMS_CODE_FAILED_ACTION_TYPE
} from 'actions/login/LoginActionsTypes';
import { Reducer } from 'redux';
import { getFromStorage } from 'auth';

const auth = getFromStorage();

const initialState: LoginState = {
    auth,
    customerLookUp: undefined,
    failed: false,
    loading: false,
    status: LoginStatus.Initial
};

export const loginReducer: Reducer<LoginState, LoginAction> = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_START_ACTION_TYPE:
            return {
                ...state,
                loading: true,
                failed: false,
                status: LoginStatus.Start
            };
        case LOGIN_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                auth: action.payload.auth,
                status: LoginStatus.Success,
                loading: false
            };
        case LOOKUP_START_ACTION_TYPE:
            return {
                ...state,
                loading: true,
                status: LoginStatus.LookUpInitial
            };
        case LOOKUP_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                customerLookUp: action.payload.customer,
                loading: false,
                status: LoginStatus.LookUpSuccess
            };
        case REQUEST_SMS_CODE_START_ACTION_TYPE:
            return {
                ...state,
                loading: true,
                status: LoginStatus.RequestingSMSCode
            };
        case REQUEST_SMS_CODE_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                loading: false,
                status: LoginStatus.RequestSMSCodeSuccess
            };
        case REQUEST_SMS_CODE_FAILED_ACTION_TYPE:
            return {
                ...state,
                loading: false,
                status: LoginStatus.RequestSMSCodeFailed
            };
        case LOGIN_FAIL_ACTION_TYPE:
            return {
                ...state,
                failed: true,
                loading: false,
                status: LoginStatus.Failed
            };
        default:
            return state;
    }
};

export default loginReducer;
