import clsx from 'clsx';
import React from 'react';

import './Button.styles.scss';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant: 'primary' | 'secondary';
    className?: string;
};

const Button: React.FC<ButtonProps> = ({ variant, className, ...props }) => {
    return (
        <button {...props} className={clsx('Button', className)} data-variant={variant}>
            {props.children}
        </button>
    );
};

export default Button;
