import {
  STAFFS_ERROR_ACTION_TYPE,
  STAFFS_FETCH_ACTION_TYPE,
  STAFFS_FETCH_SUCCESS_ACTION_TYPE,
  STAFFS_RESET_ACTION_TYPE,
  StaffsAction,
} from './StaffsActionsTypes';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';

import { StaffsState } from 'reducers/staffs/StaffsState';
import { StaffDto } from './StaffsDtos';
import ApiPublicClient, { createTokenConfig } from 'api/ApiPublicClient';
import { convertToStaff } from './StaffsConverter';

const staffsUrl = '/staff_lookup';

export const fetchStaffsThunk = (
  marketplaceId: number,
): ThunkAction<void, StaffsState, null, StaffsAction> => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const url = `${staffsUrl}?marketplace_id=${marketplaceId}`;

    try {
      const response: AxiosResponse<Array<StaffDto>> = await ApiPublicClient.get(
        url,
        createTokenConfig(),
      );
      dispatch(fetchSucces(response.data));
    } catch (apiError) {
      console.error(apiError);
      dispatch(error());
    }
  };
};

export const reset = (): StaffsAction => {
  return {
    type: STAFFS_RESET_ACTION_TYPE,
  };
};

const fetchStart = (): StaffsAction => {
  return {
    type: STAFFS_FETCH_ACTION_TYPE,
  };
};

export const fetchSucces = (staffsDto: Array<StaffDto>): StaffsAction => {
  return {
    type: STAFFS_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      staffs: staffsDto.map((staff) => convertToStaff(staff)),
    },
  };
};

const error = (): StaffsAction => {
  return {
    type: STAFFS_ERROR_ACTION_TYPE,
  };
};
