import { Service } from "model";
import { Action } from "redux";

//Types
export const SERVICES_RESET_ACTION_TYPE = "services/reset";
export const SERVICES_FETCH_ACTION_TYPE = "services/fetch_start";
export const SERVICES_FETCH_SUCCESS_ACTION_TYPE = "services/fetch_success";
export const SERVICES_SET_ACTION_TYPE = "services/set";
export const SERVICES_SAVE_ACTION_TYPE = "services/save_start";
export const SERVICES_SAVE_SUCCESS_ACTION_TYPE = "services/save_success";
export const SERVICES_ERROR_ACTION_TYPE = "services/error";

//Services
interface ServicesResetAction
  extends Action<typeof SERVICES_RESET_ACTION_TYPE> { }

interface ServicesFetchAction
  extends Action<typeof SERVICES_FETCH_ACTION_TYPE> { }
interface ServicesFetchSuccessAction
  extends Action<typeof SERVICES_FETCH_SUCCESS_ACTION_TYPE> {
  payload: {
    services: Array<Service>;
  };
}

interface ServicesSetAction extends Action<typeof SERVICES_SET_ACTION_TYPE> {
  payload: {
    services: Array<Service>;
  };
}

interface ServicesSaveAction extends Action<typeof SERVICES_SAVE_ACTION_TYPE> { }
interface ServicesSaveSuccessAction
  extends Action<typeof SERVICES_SAVE_SUCCESS_ACTION_TYPE> {
  payload: {
    services: Array<Service>;
  };
}

interface ServicesErrorAction
  extends Action<typeof SERVICES_ERROR_ACTION_TYPE> { }

//AccountSetupAction
export type ServicesAction =
  | ServicesResetAction
  | ServicesFetchAction
  | ServicesFetchSuccessAction
  | ServicesSetAction
  | ServicesSaveAction
  | ServicesSaveSuccessAction
  | ServicesErrorAction;
