import React from 'react';
import { Typography } from '@mui/material';
import { BookingLayout } from 'components/UI/Layout';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import store from 'store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faPhoneFlip } from '@fortawesome/pro-regular-svg-icons';
import { formatPhoneNumber } from 'utils';
import clsx from 'clsx';
import { MarketplaceType } from 'model';
import { hero4, poweredBy } from 'assets/images';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
            display: 'flex',
            position: 'relative'
        },
        column: {
            flex: 1,
            display: 'flex',
            paddingBottom: 40,
            flexDirection: 'column',
            justifyContent: 'center'
        },
        logo: {
            width: 140,

            [theme.breakpoints.down('sm')]: {
                marginTop: 72
            }
        },
        paragraph: {
            'fontSize': 16,

            '& + p': {
                marginTop: '1em'
            },
            '& svg': {
                marginRight: 8
            },

            [theme.breakpoints.up('md')]: {
                fontSize: 18
            }
        },
        marketplaceInfo: {
            gap: 6,
            display: 'flex',
            flexDirection: 'column',
            marginTop: '32px !important',
            marginBottom: '32px !important'
        },
        info: {
            fontWeight: 500,
            margin: '0px !important'
        },
        footerLogo: {
            left: 0,
            bottom: -20,
            height: 40,
            position: 'absolute'
        }
    })
);

export const IntakeFormDisabled: React.FC = () => {
    const classes = useStyles();

    const marketplace = store.getState().marketplace.marketplace;

    return (
        <BookingLayout
            hiddenHeader
            rightColumnImage={hero4}
            rightColumnMobile={false}
            leftColumn={
                <Box className={classes.container}>
                    <Box className={classes.column}>
                        <img
                            src={marketplace.logo}
                            alt={marketplace.name}
                            className={classes.logo}
                        />

                        <h1>Intake form submission currently unavailable</h1>

                        <Typography className={classes.paragraph}>
                            We&apos;re sorry, but our intake form submission is currently
                            unavailable.
                        </Typography>
                        <Typography className={classes.paragraph}>
                            If you would like to provide information or have any questions, please
                            feel free to contact us directly.
                        </Typography>

                        <Box className={classes.marketplaceInfo}>
                            <Typography className={clsx(classes.paragraph, classes.info)}>
                                {marketplace.name}
                            </Typography>

                            <Typography className={clsx(classes.paragraph, classes.info)}>
                                <FontAwesomeIcon icon={faPhoneFlip} />

                                {formatPhoneNumber(`${marketplace.phone}`)}
                            </Typography>

                            <Typography className={clsx(classes.paragraph, classes.info)}>
                                <FontAwesomeIcon icon={faMapPin} />{' '}
                                {(marketplace.businessType === MarketplaceType.ATHOME ||
                                    marketplace.businessType === MarketplaceType.LOCAL_BUSINESS) &&
                                    marketplace.address![0].addressLineOne + ' '}
                                {(marketplace.businessType === MarketplaceType.ATHOME ||
                                    marketplace.businessType === MarketplaceType.LOCAL_BUSINESS) &&
                                    marketplace.address![0].addressLineTwo + ', '}
                                {marketplace.address![0].city} {marketplace.address![0].state}
                                {(marketplace.businessType === MarketplaceType.ATHOME ||
                                    marketplace.businessType === MarketplaceType.LOCAL_BUSINESS) &&
                                    ', ' + marketplace.address![0].zipcode}
                            </Typography>
                        </Box>

                        <Typography className={classes.paragraph}>
                            We look forward to seeing you soon!
                        </Typography>
                    </Box>

                    <img className={classes.footerLogo} src={poweredBy} />
                </Box>
            }
        />
    );
};
