import { ApiClientWrapper, createApiClientWrapper } from '@spike/api-client';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { v4 as uuid } from 'uuid';
import store, { RootState } from 'store';

const abortController = new AbortController();

const config: AxiosRequestConfig = {
    baseURL: `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_API_BASE_URL}`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
};

const ApiClient: AxiosInstance = axios.create(config);

export const createTokenConfig = (): AxiosRequestConfig => {
    const token = store.getState().login.auth.token;
    if (token) {
        return {
            headers: {
                Authorization: `Bearer ${token}`
            },
            signal: abortController.signal
        };
    } else {
        return {};
    }
};

export const createMultipartTokenConfig = (): AxiosRequestConfig => {
    const tokenConfig = createTokenConfig();
    return {
        ...tokenConfig,
        headers: {
            ...tokenConfig.headers,
            'Content-Type': 'multipart/mixed'
        },
        signal: abortController.signal
    };
};

export const createWrapper = (state: RootState): ApiClientWrapper => {
    const masterData = state.masterdata;
    const marketplace = state.marketplace.marketplace;

    return createApiClientWrapper(
        ApiClient,
        createTokenConfig(),
        createMultipartTokenConfig(),
        Number(marketplace.id),
        String(marketplace.timeZone),
        masterData.data,
        uuid,
        marketplace
    );
};

export const isCancelled = (error: unknown) => {
    return axios.isCancel(error);
};

export default ApiClient;
