import { Auth } from "model/Auth";
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";

const authKey: string = `${process.env.REACT_APP_AUTH_KEY}`;
const secret: string = `${process.env.REACT_APP_AUTH_SECRET}`;

export const logoutAuth: Auth = {
  customer: undefined,
  signedIn: false,
  token: undefined,
};

const setLocalAuth = (auth: Auth) => {
  setAuth(auth, localStorage);
};

const setSessionAuth = (auth: Auth) => {
  setAuth(auth, sessionStorage);
};

const getLocalAuth = () => {
  return getAuth(localStorage);
};

const getSessionAuth = () => {
  return getAuth(sessionStorage);
};

const cleanLocal = () => {
  cleanAuth(localStorage);
};

const cleanSession = () => {
  cleanAuth(sessionStorage);
};

const cleanAuth = (storage: Storage) => {
  storage.removeItem(authKey);
};

const setAuth = (auth: Auth, storage: Storage) => {
  const encryptedAuth = AES.encrypt(JSON.stringify(auth), secret).toString();
  storage.setItem(authKey, encryptedAuth);
};

const getAuth = (storage: Storage): Auth | null => {
  try {
    const encryptedAuth = storage.getItem(authKey);

    if (encryptedAuth) {
      return JSON.parse(
        AES.decrypt(encryptedAuth, secret).toString(CryptoJS.enc.Utf8)
      );
    } else {
      return null;
    }
  } catch (error) {
    cleanSession();
    cleanLocal();
    return null;
  }
};

export const saveInStorage = (auth: Auth, local: boolean): void => {
  if (local) {
    setLocalAuth(auth);
  } else {
    cleanLocal();
  }

  setSessionAuth(auth);
};

export const getFromStorage = (): Auth => {
  let auth = getSessionAuth();

  if (auth === null) {
    auth = getLocalAuth();
    if (auth !== null) {
      setSessionAuth(auth);
    }
  }

  if (auth === null) {
    return { ...logoutAuth };
  } else {
    return auth;
  }
};

export const removeFromStorage = (): void => {
  cleanSession();
  cleanLocal();
};

export const changeAvatarInStorage = (
  avatar: string,
  customerId: number
): void => {
  const sessionAuth = getSessionAuth();
  const localAuth = getLocalAuth();

  if (sessionAuth && sessionAuth.customer?.id === customerId) {
    sessionAuth.customer!.avatar = avatar;
    setSessionAuth(sessionAuth);
  }

  if (localAuth && localAuth.customer?.id === customerId) {
    localAuth.customer!.avatar = avatar;
    setLocalAuth(localAuth);
  }
};
