import MasterData from "../../model/MasterData";

export interface MasterDataState {
  data: MasterData;
  status: MasterDataStatus;
  loading: boolean;
}

export enum MasterDataStatus {
  Initial,
  Fetching,
  FetchSuccess,
  Error
} 
